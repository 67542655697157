
import { Row, Col, Card, Space, Badge, Tooltip } from 'antd';
import './index.scss';
import CustomCardTitle from './CustomCardTitle';
import { FC } from 'react';
import { ApiLogsDrawerProps } from './type';
import { formatUrl, maskValue } from 'utils/Common';


const APIKeyDetailedRequestDrawer: FC<ApiLogsDrawerProps> = (props) => {
    const { drawerData } = props;
    const endPoint = formatUrl(drawerData?.apiUrl)

    return (
        <div className={'req-detail-body'}>
            <Row justify={"space-between"}>
                <Col>
                    <Space size={20}>
                        <Card title={<CustomCardTitle drawerData={drawerData} />} className={'card-width'} >
                            <Card.Grid hoverable={false} style={gridStyle}>
                                <p className={'content-heading'}>Integration</p>
                                <p className={'content-heading__sub-heading'}>{drawerData?.Integration}</p>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={gridStyle}>
                                <p className={'content-heading'}>API</p>
                                <p className={'content-heading__sub-heading'}>{drawerData?.apiName}</p>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={gridStyle}>
                                <p className={'content-heading'}>Timestamp</p>
                                <p className={'content-heading__sub-heading'}>{drawerData?.createdAt}</p>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={gridStyle}>
                                <p className={'content-heading'}>Status</p>
                                <p className={'content-heading__sub-heading'}>
                                    <Space>
                                        <Badge count={0} style={drawerData?.status == '200' ? { backgroundColor: '#52c41a', color: '#52c41a', fontSize: '5px' } : { backgroundColor: '#FAAD14', color: '#FAAD14', fontSize: '5px' }} showZero={true} />
                                        <span style={{ fontSize: '12px' }}>{drawerData?.status}</span>
                                    </Space>
                                </p>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={gridStyle}>
                                <p className={'content-heading'}>Duration</p>
                                <p className={'content-heading__sub-heading'}>{drawerData?.duration} ms</p>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={gridStyle}>
                                <p className={'content-heading'}>Full URL</p>
                                <Tooltip title={drawerData?.apiUrl} placement='bottom'> <p className={'content-heading__sub-heading fullUrl'}>{drawerData?.apiUrl}</p></Tooltip>
                            </Card.Grid>
                            <Card.Grid hoverable={false} style={{ width: '50%', boxShadow: 'none', textAlign: 'left', border: 'none' }}>
                                <p className={'content-heading'}>Request ID</p>
                                <p className={'content-heading__sub-heading'}>{drawerData?.id}</p>
                            </Card.Grid>
                        </Card>
                    </Space>
                </Col>
            </Row>
            <Row className={'mt-1'}>
                <Row gutter={24}>
                    <Col span={12} style={{ width: 500 }}>
                        <Card title="Request" >
                            <p>Method: {drawerData?.method}</p>
                            <p>Endpoints: {endPoint}</p>
                            <div style={{ marginTop: '5px' }}>
                                <p className={'req-header-title'}>Header</p>
                                {/*header details div*/}
                                <div>
                                    <p className={'req-header-title__sub-title'}>accept</p>
                                    <p style={{ fontSize: '12px' }}>{drawerData?.apiRequest?.header?.accept}</p>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <p className={'req-header-title__sub-title '}>authorization</p>
                                    <p className={'token'} style={{ fontSize: '12px' }}>Bearer {maskValue(drawerData?.apiRequest?.header?.accesstoken)} </p>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <p className={'req-header-title__sub-title'}>Request ID</p>
                                    <p style={{ fontSize: '12px' }}>{drawerData?.id}</p>
                                </div>
                            </div>

                        </Card>
                    </Col>
                    <Col span={12} style={{ width: 500 }}>
                        <Card title="Response" >
                            <p>Method: {drawerData?.method}</p>
                            <p>Endpoints: {endPoint}</p>
                            <div style={{ marginTop: '5px' }}>
                                <p className={'req-header-title'}>Header</p>
                                {/*header details div*/}
                                <div>
                                    <p className={'req-header-title__sub-title'}>content-type</p>
                                    <p style={{ fontSize: '12px' }}>{drawerData?.apiRequest?.header?.accept}</p>
                                </div>
                            </div>

                        </Card>
                    </Col>
                </Row>
            </Row>
        </div>
    )
}

export default APIKeyDetailedRequestDrawer



const gridStyle: React.CSSProperties = {
    width: '30%',
    textAlign: 'left',
    border: 'none',
    boxShadow: 'none'
};
