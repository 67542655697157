import React from "react";
import { Avatar, Divider } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import popOverStyles from "./index.module.scss";
import { ProfilePopoverProps } from "./types";
import { useDispatch } from "react-redux";
import { logoutAction } from "redux/action/logoutAction";
import Cookies from "js-cookie";
import { AppDispatch } from "redux/store";

const ProfilePopover: React.FC<ProfilePopoverProps> = (props) => {
  const { avatarName, role, fullName, hide, accessToken, email } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const onClickHandler = () => {
    navigate("/settings/users");
  };
  // Logout Handler
  const logoutData = {
    email,
    accessToken,
  };

  const handleLogout = async (): Promise<void> => {
    localStorage.clear();
    const data = { logoutData };
    dispatch(logoutAction(data))
      .unwrap()
      .then(() => {
        Cookies.remove("accessToken");
        localStorage.clear();
        navigate("/login");
      })
      .catch(() => {
        Cookies.remove("accessToken");
        localStorage.clear();
      });
  };

  return (
    <div>
      <Link to={`/settings/my-profile`} onClick={hide}>
        <div className={popOverStyles["popover-profile-div"]}>
          <Avatar
            size={50}
            style={{
              background: "#D2E3ED",
              color: "#1D75A7",
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            {avatarName}
          </Avatar>
          <div style={{ paddingLeft: "20px" }}>
            <p
              style={{ fontSize: "16px", color: "#444444", fontWeight: "400" }}
            >
              {fullName}
            </p>
            <span
              style={{
                background: "#E9F9FF",
                color: "#1D75A7",
                padding: "3px 0px",
                borderRadius: "5px",
              }}
            >
              {role}
            </span>
          </div>
          <div style={{ justifySelf: "center", alignSelf: "center" }}>
            <RightOutlined />
          </div>
        </div>
      </Link>
      <Divider style={{ margin: "0" }} />
      {/* <hr className={"mt-1"} /> */}
      <div style={{ marginTop: "10px" }}>
        <div
          className={popOverStyles["popover-menu-list"]}
          onClick={onClickHandler}
        >
          <div className={popOverStyles["popover-menu-list__icon"]}>
            <img
              alt="settings"
              src="https://staging.app.synctools.io/assets/settings.svg"
            />
          </div>
          <div style={{ paddingLeft: "7px" }}>Settings</div>
        </div>
        <div
          className={popOverStyles["popover-menu-list"]}
          onClick={handleLogout}
        >
          <div className={popOverStyles["popover-menu-list__icon"]}>
            <img
              alt="Logout"
              src="https://staging.app.synctools.io/assets/sign-out.svg"
            />
          </div>
          <div style={{ paddingLeft: "7px" }}>Log Out</div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePopover;
