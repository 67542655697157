import { createSlice } from "@reduxjs/toolkit";
import {
  deleteUserAction,
  editUserAction,
  getUsersAction,
  inviteUserAction,
  paginateUserAction,
  reinviteUserAction,
} from "redux/action/userAction";
import { toastText } from "utils/utils";

const initialState: any = {
  data: null,
  count: 0,
  isLoading: true,
  fistTimeFetchLoading: true,
  error: null,
};

const UserSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    updateLoadingHandler: (state) => {
      state.isLoading = false;
    },
    clearRedux: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    // Get All Users
    builder.addCase(getUsersAction.pending, (state) => {
      // state.fistTimeFetchLoading = true;ccc
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getUsersAction.fulfilled, (state, action) => {
      state.data = action?.payload?.data;
      state.count = action?.payload?.total;
      state.isLoading = false;
      state.fistTimeFetchLoading = false;
    });
    builder.addCase(getUsersAction.rejected, (state, action: any) => {
      state.error = action.payload;
      state.isLoading = false;
      if (action?.payload?.responseStatus !== 401) {
        toastText(action?.payload?.message, "error");
      }
      state.fistTimeFetchLoading = false;
    });

    //Paginate Users
    builder.addCase(paginateUserAction.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.isLoading = false;
      state.fistTimeFetchLoading = false;
    });

    // Invite New User
    builder.addCase(inviteUserAction.pending, (state) => {
      state.isLoading = false;
      // for loader at save button
      state.isAddUserLoading = true;
      state.error = null;
    });
    builder.addCase(inviteUserAction.fulfilled, (state, action) => {
      state.data = [...state.data, action.payload.data];
      state.count = state.count + 1;
      toastText(action?.payload?.message, "success");
      state.isLoading = false;
      // for loader at save button
      state.isAddUserLoading = false;
    });
    builder.addCase(inviteUserAction.rejected, (state, action: any) => {
      state.error = action.payload;
      if (action?.payload?.responseStatus !== 401) {
        toastText(action?.payload?.message, "error");
      }
      state.isLoading = false;
      // for loader at save button
      state.isAddUserLoading = false;
    });


    //Re-invite user
    builder.addCase(reinviteUserAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(reinviteUserAction.fulfilled, (state, action) => {
      toastText(action?.payload?.message, "success");
      state.isLoading = false;
    });
    builder.addCase(reinviteUserAction.rejected, (state, action: any) => {
      state.error = action.payload;
      if (action?.payload?.responseStatus !== 401) {
        toastText(action?.payload?.message, "error");
      }
      state.isLoading = false;
      // for loader at save button
      state.isAddUserLoading = false;
    });
    // Edit User
    builder.addCase(editUserAction.pending, (state, action:any) => {
      if (action?.meta?.arg?.isChangeStatus) {
        state.isLoading = true;
      } else {
        state.isAddUserLoading = true;
      }
      state.error = null;
    });
    builder.addCase(editUserAction.fulfilled, (state, action) => {
      const updatedData = action.payload.editData;
      state.data = state.data.map((user: any) => {
        if (user?.userId === updatedData.userId) {
          return action.payload.response?.data;
        } else {
          return user;
        }
      });
      state.isLoading = false;
      state.isAddUserLoading = false;

      toastText(action?.payload?.response?.message, "success");
    });
    builder.addCase(editUserAction.rejected, (state, action: any) => {
      state.error = action.payload;
      toastText(action?.payload?.message, "error");
      state.isLoading = false;
      state.isAddUserLoading = false;
    });

    // Delete User
    builder.addCase(deleteUserAction.pending, (state) => {
      state.isLoading = false;
      state.error = null;
      state.isAddUserLoading = true;
    });
    builder.addCase(deleteUserAction.fulfilled, (state, action) => {
      state.data = state?.data?.filter(
        (item: any) => item?.id !== action?.payload?.id
      );
      state.count = state.count - 1;
      state.isLoading = false;
      state.isAddUserLoading = false;
      toastText(action?.payload?.response?.message, "success");
    });
    builder.addCase(deleteUserAction.rejected, (state, action: any) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isAddUserLoading = false;
      if (action?.payload?.responseStatus !== 401) {
        toastText(action?.payload?.message, "error");
      }
    });
  },
});

export default UserSlice;
export const { updateLoadingHandler, clearRedux } = UserSlice.actions;
