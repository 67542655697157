import { Button, Tooltip } from 'antd'
import styles from './index.module.scss'
import { FC, useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { VerificationCodeProps } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { codeVerificationAction } from 'redux/action/codeVerificationAction';
import { useNavigate } from 'react-router-dom';
import { postApi } from 'redux/apis';
import { toastText } from 'utils/utils';

const OnBoardingVerificationCode: FC<VerificationCodeProps> = (props) => {
    const { email, handleNewEmail } = props
    const [isLoading, setIsLoading] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);
    const [code, setCode] = useState('');
    const [countdown, setCountdown] = useState(120);
    const [disabled, setDisabled] = useState(true);

    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate()
    const codeVerification = useSelector((state: any) => state?.codeVerification?.data?.data?.isVerified);
    const codeErrorCheck = useSelector((state: any) => state?.codeVerification?.error)
    const handleChangeOtp = (value: string) => {
        setCode(value);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        const data = {
            email: email,
            verificationCode: code
        }
        dispatch(codeVerificationAction(data))
    }

    const formatTime = (timeInSeconds: number) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const handleResendCode = async () => {
        if (!disabled) {
            setIsResendLoading(true);

            try {
                const res = await postApi("/auth/send-verification-code", { email });
                if (res.status === 200) {
                    toastText(res?.data?.message, "success");
                    setIsResendLoading(false);
                    setCountdown(10);
                }else{
                    setIsResendLoading(false);
                    setCountdown(10);
                } 
            } catch (error:any) {
                toastText(error?.message, "error");
              
            }
            setDisabled(true);
        }
    }

    const handlePaste = (e: any) => {
        const data = e.clipboardData.getData('text');
        setCode(data)
    }



    useEffect(() => {
        let intervalId: any;

        if (countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else {
            setDisabled(false);
        }

        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countdown]);

    useEffect(() => {
        const isVerified = localStorage.getItem('isVerified');
        if (isVerified !== undefined && isVerified !== null) {
            navigate('/register')
        }
    }, [codeVerification])

    useEffect(()=>{
        setIsLoading(false);
    }, [codeErrorCheck])

    return (
        <div className={styles['main']} >
            <div className={styles['header']} >
                <img src='assets/SyncToolLogo/syncToolLogo.png' alt='SyncTools' width={'200px'} />
                <p className={styles['header__text']} >Enter Verification Code</p>
                <p className={styles['header__text-sub']} >We’ve sent a code to <span style={{ color: 'black' }} >
                    {email ? email : ''}
                </span>
                    <Tooltip placement="right" title={`change email`}> <span onClick={handleNewEmail} style={{ paddingLeft: "20px", color: "#0b78c2", cursor: "pointer" }} ><LeftOutlined />Back</span> </Tooltip>
                </p>
            </div>
            <div className={styles['body']} >
                <form onSubmit={handleSubmit}>
                    <div className={styles['body__otp-field']}>
                        <OtpInput
                            value={code}
                            onChange={(e) => handleChangeOtp(e)}
                            numInputs={6}
                            renderSeparator={<span style={{ padding: '10px' }} ></span>}
                            renderInput={(props) => <input {...props} />}
                            inputStyle={{
                                width: '100%',
                                border: `1px solid  #D4D7DC`,
                                borderRadius: '7px',
                                height: '65.54px',
                                fontFamily: 'Lexend',
                                fontSize: '22px',
                                outline: 'none'
                            }}
                            onPaste={(e) => handlePaste(e)}
                        />
                    </div>
                    <div className={styles['body__otp-resend']} >
                        <p className={styles['body__otp-resend-text']} >
                            {disabled ? `Time Remaining: ${formatTime(countdown)}` : 'Didn\'t receive code?'}
                            {
                                !isResendLoading ? (
                                    <span className={styles[`link${disabled ? '-disabled' : ''}`]} onClick={() => handleResendCode()}>
                                        Resend code
                                    </span>
                                ) : (
                                    <>
                                        <span style={{ marginLeft: '20px' }} ><LoadingOutlined />&nbsp;Sending code...</span>
                                    </>
                                )
                            }
                        </p>
                    </div>
                    {
                        code.trim().length === 6 ? (
                            <div className={styles['body__otp-submit']} >
                                <Button loading={isLoading} type='primary' size='large' htmlType='submit' className={styles['body__otp-submit__button']} >Verify</Button>
                            </div>
                        ) : null
                    }
                </form>
            </div>
        </div>
    )
}


export default OnBoardingVerificationCode