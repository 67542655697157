import { Button, Checkbox, Col, Form, Input, Modal, Row, Tag } from "antd";
import { QuickbooksTypes, XeroTypes } from "utils/constant";
import { CloseSvg } from "utils/svgs";
import styles from "./index.module.scss";
import "./index.scss";

const { TextArea } = Input;

interface Props {
  isNewAccountModalOpen: boolean;
  handleModalClose: () => void;
  handleSubmit: (values: any) => void;
  selectedAccount: any;
  isLoading: boolean;
  removeSelectedAccount?: () => void;
  selectedApp?: string | null;
}

const AddAccountModal = (props: Props) => {
  const {
    isNewAccountModalOpen,
    handleModalClose,
    handleSubmit,
    selectedAccount,
    isLoading,
    removeSelectedAccount,
    selectedApp,
  } = props;

  const AccountingTypes =
    selectedApp === "QuickbooksOnline"
      ? QuickbooksTypes
      : selectedApp === "Xero"
      ? XeroTypes
      : [];

  return (
    <div className="qbo-account">
      <Modal
        open={isNewAccountModalOpen}
        footer={false}
        onCancel={handleModalClose}
        className="qbo-account-modal"
        closable={false}
        width={1300}
      >
        <Row className={styles["qbo-modal__header"]}>
          <div className="userDetailsTitle">
            <p>
              {selectedAccount ? "Edit" : "Add new"} {selectedApp} account
            </p>
          </div>
          <div
            className={`${styles[`qbo-modal__header-close`]} ${
              isLoading && "pointer-event-none"
            } pointer`}
            onClick={() => {
              handleModalClose();
              if (removeSelectedAccount) {
                removeSelectedAccount();
              }
            }}
          >
            <CloseSvg />
          </div>
        </Row>

        <hr />
        <Row>
          <Col md={12} className={styles["qbo-modal__form"]}>
            <Form
              name="basic"
              wrapperCol={{ span: 16 }}
              // style={{ maxWidth: 600 }}
              autoComplete="off"
              labelCol={{ span: 8 }}
              onFinish={handleSubmit}
            >
              <div className="qbo-account-form">
                <Form.Item
                  label="Account Name"
                  name="accountName"
                  rules={[
                    { required: true, message: "Please enter account name!" },
                  ]}
                  initialValue={selectedAccount?.accountName}
                >
                  <Input
                    size="large"
                    className="qbo-account-form__field-input"
                  />
                </Form.Item>

                <Form.Item
                  label="Account Type"
                  name="accountType"
                  className="qbo-account-form__field"
                  rules={[
                    { required: true, message: "Please enter account type!" },
                  ]}
                  initialValue={selectedAccount?.accountType}
                >
                  <Input
                    size="large"
                    className="qbo-account-form__field-input"
                  />
                </Form.Item>

                <Form.Item
                  label="Account Code"
                  name="accountCode"
                  initialValue={selectedAccount?.accountCode}
                >
                  <Input
                    size="large"
                    className="qbo-account-form__field-input"
                  />
                </Form.Item>

                <Form.Item
                  label="Account Subtype"
                  name="accountSubType"
                  initialValue={selectedAccount?.accountSubType}
                >
                  <Input
                    size="large"
                    className="qbo-account-form__field-input"
                  />
                </Form.Item>
                <Form.Item
                  label="Account Description"
                  name="description"
                  initialValue={selectedAccount?.description}
                >
                  <TextArea rows={4} maxLength={150} />
                </Form.Item>

                <Form.Item
                  label="Bank Account Number"
                  name="bankAccountNumber"
                  initialValue={selectedAccount?.bankAccountNumber}
                >
                  <Input
                    size="large"
                    className="qbo-account-form__field-input"
                  />
                </Form.Item>
                {selectedAccount && (
                  <>
                    <Form.Item
                      label="Currency"
                      name="currency"
                      initialValue={selectedAccount?.currency}
                    >
                      <Input
                        size="large"
                        className="qbo-account-form__field-input"
                      />
                    </Form.Item>
                    <Row>
                      {/* <Col sm={12}>
                <Form.Item
                  name="isBankAccount"
                  valuePropName="checked"
                  initialValue={selectedAccount?.isBankAccount}
                >
                  <Checkbox> Bank Account</Checkbox>
                </Form.Item>
              </Col> */}
                      <Col sm={12}>
                        <Form.Item
                          name="isActive"
                          valuePropName="checked"
                          initialValue={selectedAccount?.isActive}
                        >
                          <Checkbox
                            disabled={selectedApp === "QuickbooksOnline"}
                          >
                            Active
                          </Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              </div>
              <hr />
              <Row justify="start" className={styles["qbo-modal-buttons"]}>
                <Button
                  className={`edit ${isLoading && "pointer-event-none"}`}
                  htmlType="submit"
                >
                  {isLoading ? (
                    <img
                      src="/assets/gifs/loading.gif"
                      height={30}
                      width={30}
                    />
                  ) : selectedAccount ? (
                    "Edit"
                  ) : (
                    "Save"
                  )}
                </Button>
                <Button
                  className="cancel"
                  onClick={() => {
                    handleModalClose();
                    if (removeSelectedAccount) {
                      removeSelectedAccount();
                    }
                  }}
                >
                  Cancel
                </Button>
              </Row>
            </Form>
          </Col>
          <Col md={12} className={styles["qbo-modal__tags"]}>
            <p className={styles["qbo-modal__tags-header"]}>
              Type of the account
            </p>
            <div className={styles["qbo-modal__tags-types"]}>
              {AccountingTypes.map((type, index) => (
                <div key={index}>
                  <Tag
                    bordered={false}
                    color="processing"
                    className={styles["qbo-modal__tags-tag"]}
                  >
                    {type.AccountType}
                  </Tag>
                  <br />
                  {type.AccountSubtype.map((subtype, index) => (
                    <Tag
                      bordered={false}
                      key={index}
                      className={styles["qbo-modal__tags-tag"]}
                    >
                      {subtype}
                    </Tag>
                  ))}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AddAccountModal;
