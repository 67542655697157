import { Button, Col, Input, Modal, Row } from "antd";
import { FC, useState } from "react";
import styles from "./index.module.scss";
import { EmbedScriptModalProps } from "./types";

import { CopyOutlined, ExportOutlined } from "@ant-design/icons";

// ConfirmDelete popup
const EmbedScript: FC<EmbedScriptModalProps> = (props) => {
  const { handleCancel, handleOk, isModalOpen } = props;
  const { TextArea } = Input;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const currentUrl = window.location.href;
  // const [baseUrl] = currentUrl.split("/integrations/accounting");
  const companyId = localStorage.getItem("companyId");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inputValue, setInputValue] = useState<string>(
    `<iframe src='${window.location.origin}/preview?t=${btoa(
      companyId ? companyId : "companyId"
    )}' width='1225' height='1800' scrolling='auto' > </iframe>`
  );
  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
  };
  //   JSX
  return (
    <Modal
      title={
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={"/assets/images/embed.png"}
              alt="Your Image"
              style={{ marginRight: 8, width: 25, height: 25 }}
            />
            <h3 style={{ margin: 0 }}>Embed</h3>
          </div>
          <span
            style={{
              fontSize: "1.5rem",
              marginLeft: 4,
              color: "gray",
              fontWeight: 400,
            }}
          >
            Copy the below script and put it into your application
          </span>
        </>
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      width={1000}
      closeIcon={
        <>
          <a href={`#`} target="_blank" rel="noreferrer">
            <Button className="primary" style={{ left: -82 }}>
              Click here how to embed <ExportOutlined />
            </Button>
          </a>
        </>
      }
      footer={[]}
    >
      <div className={styles["embedbody"]}>
        <Row gutter={24} align={"middle"}>
          <Col span={21}>
            <TextArea value={inputValue} disabled={true} />
          </Col>
          <Col span={2}>
            <Button onClick={handleCopy} className="edit">
              <CopyOutlined />
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default EmbedScript;
