import { FC } from "react";
import styles from "./login.module.scss";
import { Row, Col } from "antd";
import { LoginLayoutInterface } from "./types";

// Login layout component
const loginLayout: FC<LoginLayoutInterface> = (props) => {
  const { children } = props;
  return (
    <div className={styles["login"]}>
      <Row
        className={styles["login__wrapper"]}
        justify="space-around"
        align={"middle"}
      >
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          className={styles["login__layout"]}
          span={11}
        >
          <img
            className={styles["login__layout--image"]}
            src={
              children?.props?.title === "Forgot Password"
                ? `assets/Auth/forgot.png`
                : `assets/Auth/sign-in.png`
            }
            // preview={false}
            crossOrigin={
              process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
            }
            style={
              children?.props?.title === "Forgot Password"
                ? { width: "100%", height: "100vh" }
                : { justifyContent: "center" }
            }
            alt="group"
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          className={styles["login__details"]}
          span={13}
        >
          {/* <div className={styles["login__details--logo"]}>
            <Image
              src={`assets/SyncToolLogo/syncToolLogo.png`}
              crossOrigin={
                process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
              }
              preview={false}
              alt="group"
            />
          </div> */}
          <div className={styles["login__details--body"]}>{children}</div>
        </Col>
      </Row>
    </div>
  );
};

export default loginLayout;
