import {
  //  Header,
  Sidebar,
} from "components/Global";
import WelcomeModal from "components/Global/WecomeModal";
import { FORMDATA } from "constants/Data";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { putApi } from "redux/apis";
import { setFirstLogIn } from "redux/slice/profileSlice";
import { AppDispatch } from "redux/store";
import { toastText } from "utils/utils";
import styles from "./index.module.scss";

const GlobalLayout = () => {
  // Initialize variables
  const navigate = useNavigate();
  const { pageMenuItems } = FORMDATA;
  const [selectedSideBar, setSelectedSideBar] = useState("dashboard");
  const { isLoading } = useSelector((state: any) => state.userProfile);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const userId = useSelector((state: any) => state?.userProfile?.data?.id);
  const [isFirstLogin, setIsFirstLogin] = useState(true);
  const firstLogIn = useSelector((data: any) => data?.userProfile?.data);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const companyName = useSelector(
    (data: any) => data?.userProfile?.data?.companies[0]?.company?.companyName
  );

  const onSubmit = async (values: any) => {
    setLoading(true);
    const newValues = {
      ...values,
      id: userId,
    };
    if (values && userId) {
      try {
        const res = await putApi("/auth/update-register", newValues);
        if (res.status === 200) {
          await dispatch(setFirstLogIn());
          setIsModalOpen(false);
          setLoading(false);
          toastText(`Welcome to ${companyName}'s admin panel`, "success");
        }
      } catch (error) {
        setIsModalOpen(false);
        setLoading(false);
      }
    }
  };

  const handleCancel = () => {
    if (localStorage.getItem("isFirstLogin") === "false") {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  };

   const selectedSidebarFromLocalStorage =
     localStorage.getItem("selectedSidebar");

  // Get the selected company and its permissions
  const selectedCompany = useSelector(
    (state: any) => state?.userProfile?.data?.companies
  );
  const selectedCompanyId = localStorage.getItem("companyId");
  const permissionForSelectedCompany = selectedCompany?.find(
    (data: any) => data.companyId === selectedCompanyId
  );

  // Create an array of permissions with 'view' access
  const resultArray =
    permissionForSelectedCompany?.role.permissions
      .filter((item: any) => item.view)
      .map((item: any) => item.permissionName) || [];

  // Filter menu items based on permissions
  const filteredMenuItems = pageMenuItems.filter((item) =>
    resultArray.includes(item.roleName)
  );

  const sideBarChange = (value: any) => {
    setSelectedSideBar(value.key);
     localStorage.setItem("selectedSidebar", value.key);
  };
  const keyWiseRouteMapping = {
    "api-keys": "developer/api-keys",
    webhooks: "developer/webhooks",
    configuration: "integrations/accounting",
    playground: "playground",
    ecommerce: "integrations/ecommerce",
    connectedapps: "integrations/connectedapps",
    logs: "developer/logs",
    dashboard: "/",
  };

  useEffect(() => {
    navigate(
      keyWiseRouteMapping[selectedSideBar as keyof typeof keyWiseRouteMapping]
    );
  }, [selectedSideBar]);
  const initialFunction = () => {
    const splitArray = location.pathname.split("/");
    setSelectedSideBar(
      splitArray[splitArray.length - 1] === "accounting" ||
        splitArray[splitArray.length - 1] === "ecommerce" ||
        splitArray[splitArray.length - 1] === "erp"
        ? "configuration"
        : splitArray[splitArray.length - 1] === ""
        ? "dashboard"
        : splitArray[splitArray.length - 1]
    );
  };
  useEffect(() => {
       initialFunction();
     if (selectedSidebarFromLocalStorage) {
       setSelectedSideBar(selectedSidebarFromLocalStorage);
     }
  }, []);
  useEffect(() => {
    setIsFirstLogin(firstLogIn?.isFirstLogin);
  }, [firstLogIn]);

  // JSX
  return !isLoading ? (
    <div className={styles["global-layout"]}>
      <div className={styles["global-layout__wrapper"]}>
        <div className={styles["global-layout__header"]}>
          {/* <Header /> */}
        </div>
        <div className={styles["global-layout__body"]}>
          <div className={styles["global-layout__body--sidebar"]}>
            <Sidebar
              items={filteredMenuItems}
              isGetSupportButton={false}
              selectedSidebar={selectedSideBar}
              handleSidebar={sideBarChange}
            />
          </div>

          <div>
            {isFirstLogin && (
              <WelcomeModal
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
                onSubmit={onSubmit}
                loading={loading}
                companyName={companyName}
              />
            )}
          </div>

          <div className={styles["global-layout__body--body"]}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default GlobalLayout;
