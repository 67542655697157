import { TableActionHeader } from "components/Global";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApiLogs } from "redux/action/apiLogsAction";
import { AppDispatch } from "redux/store";
import LogPageTabs from "./Tabs";
import { useLocation } from "react-router-dom";

const LogsComponent = () => {

    const [currentTabIndex, setTabIndex] = useState('1');
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch<AppDispatch>()
    const location = useLocation();

    const getApiLogsData = useSelector((data: any) => data?.apilogs?.data)

    const tempWebhookLogData = [
        {
            "id": 1,
            "methods": "PUT",
            "url": "https://fintechapp.satva.solutions/login",
            "status": "200 OK",
            "integration": "QuickBooks",
            "eventType": "Authentication Completed",
            "timeStamp": '15/03/2024 16:15:05'
        },
        {
            "id": 2,
            "methods": "PATCH",
            "url": "https://fintechapp.satva.solutions/register",
            "status": "404 NOT FOUND",
            "integration": "Xero",
            "eventType": "Data Synchronized",
            "timeStamp": '15/03/2024 16:15:05'
        },
    ]

    const tabChangeHandler = (key: string) => {
        setTabIndex(key)
        setLoading(true)
    };

    // to set the loading state
    const setLoadingHandler = (value: any) => {
        setLoading(value)
    }

    useEffect(() => {
        if (location?.pathname === "/developer/logs") {
            dispatch(getApiLogs('')).then(() => {
                setLoading(false)
            })
        }
    }, [currentTabIndex, location])

    return (
        <>
            <TableActionHeader title={"Logs"}></TableActionHeader>
            <LogPageTabs
                tabIndex={currentTabIndex}
                onTabChange={tabChangeHandler}
                logsDataSource={currentTabIndex == '1' ? getApiLogsData?.data : tempWebhookLogData}
                isLoading={loading}
                totalRecords={getApiLogsData?.total}
                setLoadingHandler={setLoadingHandler}
            />
        </>

    )
}

export default LogsComponent
