import { ConfigProvider, Select, Space } from "antd";
import { TableActionHeader } from "components/Global";
import SearchAndFilter from "components/settings/Configurations/SearchAndFilter";
import DynamicTable from "components/settings/Configurations/Table";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccountsAction } from "redux/action/accountsAction";
import { getActiveConnectionAction } from "redux/action/activeConnectionsActions";
import { getApiKey } from "redux/action/apikeyAction";
import { getCustomersAction } from "redux/action/customerAction";
import { getVendorsAction } from "redux/action/vendorAction";
import { AppDispatch } from "redux/store";
import { generateColumns } from "utils/Common";
import NewCompany from "./NoConnection";
import moment from "moment";

interface OptionInterface {
  label: string;
  value: string;
}

const Configurations = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [selectedConnection, setSelectedConnection] =
    useState<OptionInterface | null>(null);
  const [selectedApiKey, setSelectedApiKey] = useState<OptionInterface | null>(
    null
  );
  const [connections, setConnections] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedApp, setSelectedApp] = useState<string | null>(null);
  const [filterValue, setFilterValue] = useState<any | null>({
    filterFieldName: null,
    filterFieldValue: null,
    filterFieldValue2: null,
  });
  const [playgroundStartDate, setPlaygroundStartDate] = useState<Date | null>(
    null
  );
  const [playgroundEndDate, setPlaygroundEndDate] = useState<Date | null>(null);
  const [entity, setEntity] = useState<any>(["income", "customers"]);
  const [dataSource, setDataSource] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const activeServices = useSelector((state: any) => state.ActiveServices.data);
  const ApiKeyData = useSelector((data: any) => data?.apikeys?.data?.data);
  const [entityTableColumns, setEntityTableColumns] = useState<any>();

  const handleSelectEntityHandler = (value: string[]) => {
    value ? setEntity([value[0], value[1]]) : setEntity(undefined);
  };


  const handleApiKey = (value: OptionInterface) => {
    setSelectedApiKey(value);
  };

  const handlePlaygroundStartDate = (value: any) => {
    setPlaygroundStartDate(value);
    setPlaygroundEndDate(null);
    setFilterValue({
      ...filterValue,
      filterFieldValue: value ? moment(value.$d).startOf("day").toISOString() : "",
      filterFieldName: "modifiedDate",
    });
    if (!value) {
      setFilterValue({
        filterFieldValue2: null,
        filterFieldValue: null,
        filterFieldName: null,
      });
    }
  };

  const handlePlaygroundEndDate = (value: any) => {
    setPlaygroundEndDate(value);
    setFilterValue({
      ...filterValue,
      filterFieldValue2: value ? moment(value.$d).endOf('day').toISOString() : "",
      filterFieldName: "modifiedDate",
    });
  };

  const handleConnection = (value: OptionInterface | null) => {
    const connection = activeServices.find(
      (service: any) => service.id === value
    );
    setSelectedApp(connection?.channelName);
    setSelectedConnection(value);
  };

  const filterHandler = (name: string, value: string | Date) => {
    setFilterValue((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const dataSourceHandler = async () => {
    setLoading(true);

    if (entity[1] === "customers") {
      const params = {
        ...filterValue,
      };
      const query = {
        params: params,
        headers: {
          connectionId: selectedCompany?.id,
          tenantId: localStorage.getItem("companyId"),
          accessToken: selectedApiKey,
        },
      };
      const data = await dispatch(getCustomersAction(query));

      if (getCustomersAction.fulfilled.match(data)) {
        setDataSource(data.payload);
      }

      setEntityTableColumns(await generateColumns("customers"));
    }

    if (entity[1] === "accounts") {
      const params = {
        ...filterValue,
      };
      const query = {
        params: params,
        headers: {
          connectionId: selectedCompany?.id,
          tenantId: localStorage.getItem("companyId"),
          accessToken: selectedApiKey,
        },
      };
      const data = await dispatch(getAccountsAction(query));
      if (getAccountsAction.fulfilled.match(data)) {
        setDataSource(data.payload);
      }

      setEntityTableColumns(await generateColumns("accounts"));
    }
    if (entity[1] === "vendors") {
      const params = {
        ...filterValue,
      };
      const query = {
        params: params,
        headers: {
          connectionId: selectedCompany?.id,
          tenantId: localStorage.getItem("companyId"),
          accessToken: selectedApiKey,
        },
      };
      const data = await dispatch(getVendorsAction(query));
      if (getVendorsAction.fulfilled.match(data)) {
        setDataSource(data.payload);
      }
      setEntityTableColumns(await generateColumns("vendors"));
    }

    setLoading(false); // Set loading to false when data is fetched
  };

  const handleSelectCompany = (value: string) => {
    const selectedCompany = activeServices?.find(
      (connection: any) => connection?.id === value
    );

    localStorage.setItem(
      "connectedPlayground",
      JSON.stringify({
        channelName: selectedCompany?.channelName,
        companyName: selectedCompany?.companyName,
        id: selectedCompany?.id,
      })
    );

    setSelectedCompany(selectedCompany);
  };

  useEffect(() => {
    dispatch(getActiveConnectionAction());
    dispatch(getApiKey());
  }, []);

  useEffect(() => {
    const connectedPlaygroundData = JSON.parse(
      localStorage.getItem("connectedPlayground") || "{}"
    );
    if (connectedPlaygroundData?.id) {
      setSelectedCompany(connectedPlaygroundData);
    }
    if (activeServices?.length) {
      const _connections = activeServices.map((connection: any) => ({
        label: `${connection.companyName} (${connection.channelName})`,
        value: connection.id,
      }));
      setConnections(_connections);
    }
  }, [activeServices]);

  return (
    <>
      <TableActionHeader title="Playground"></TableActionHeader>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              selectorBg: "",
              colorBorder: "none",
              colorTextPlaceholder: "black",
              fontSize: 16,
            },
          },
        }}
      >
        <Space
          direction="horizontal"
          size="large"
          style={{ display: "flex", marginBottom: "10px" }}
        >
          <Select
            style={{ width: 300 }}
            placeholder={"Select Company"}
            options={connections}
            onChange={handleSelectCompany}
            value={selectedCompany?.companyName}
          ></Select>
        </Space>
      </ConfigProvider>

      {selectedCompany?.id ? (
        <>
          <div>
            <SearchAndFilter
              selectedConnection={selectedConnection}
              handleConnection={handleConnection}
              handlePlaygroundStartDate={handlePlaygroundStartDate}
              playgroundStartDate={playgroundStartDate}
              handlePlaygroundEndDate={handlePlaygroundEndDate}
              playgroundEndDate={playgroundEndDate}
              selectedEntity={entity}
              handleSelectEntityHandler={handleSelectEntityHandler}
              dataSourceHandler={dataSourceHandler}
              connections={connections}
              selectedApp={selectedApp}
              selectedApiKey={selectedApiKey}
              handleApiKey={handleApiKey}
              apiKeyValues={ApiKeyData?.map((item: any) => ({
                label: item.apikeyName,
                value: item.accessToken,
              }))}
            />
            <DynamicTable
              selectedEntity={entity}
              selectedConnection={selectedConnection}
              handleConnection={handleConnection}
              selectedApp={selectedApp}
              filterHandler={filterHandler}
              selectedApiKey={selectedApiKey}
              datasource={dataSource}
              entityTableColumns={entityTableColumns}
              loading={loading}
            />
          </div>
        </>
      ) : (
        <NewCompany />
      )}
    </>
  );
};

export default Configurations;
