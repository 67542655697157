import {
  Loader,
  SideDrawerWrapper,
  TableActionHeader,
} from "components/Global";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import { PermissionsNames, userColumns } from "constants/Data";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getRoleAction } from "redux/action/roleActions";
import { deleteUserAction, getUsersAction } from "redux/action/userAction";
import { userCheck } from "redux/slice/userCheckSlice";
import { AppDispatch } from "redux/store";
import { AddSvg } from "utils/svgs";
import { checkPermission, formatDate, formatPhoneNumber } from "utils/utils";
import AddUserBody from "./AddUserBody";
import DynamicTable from "./Table";
import styles from "./index.module.scss";

// UsersTable component
const UsersTable = () => {
  // State variables
  const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
  const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [PageSize, setPageSize] = useState(
    Number(localStorage.getItem("userTableSize")) || 20
  );
  const [filterValue, setFilterValue] = useState("all");
  const [type, setType] = useState("asc");
  const [drawerInfo, setDrawerInfo] = useState({
    drawerTitle: "Users",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editSelectedUser, setEditSelectedUser] = useState<any>();
  const [formateData, setFormateData] = useState<any>([]);
  const [sortDirection, SetsortDirection] = useState("ascend");


  // Dispatch
  const dispatch = useDispatch<AppDispatch>();

  // Location and Navigation
  const location = useLocation();
  const navigate = useNavigate();

  // Selectors
  const {
    data: userData,
    isLoading,
    fistTimeFetchLoading,
    isAddUserLoading,
  } = useSelector((state: any) => state?.users);

  const UserDataState = useSelector((state: any) => state?.users);
  const totalRecords = useSelector((state: any) => state?.users?.count);
  const selectedCompany = useSelector(
    (state: any) => state?.userProfile?.data?.companies
  );
  const selectedCompanyPermission = useSelector(
    (state: any) => state?.userProfile?.data?.companies[0]?.role?.permissions
  );

  // Permission handling
  const PermssionForSelectedCompanay = selectedCompany?.map((data: any) => {
    if (data.companyId === localStorage.getItem("companyId")) {
      return data.role.permissions;
    }
  });
  let DashboardPermission;
  if (PermssionForSelectedCompanay) {
    DashboardPermission = PermssionForSelectedCompanay[0]?.find((data: any) => {
      return data.permissionName === PermissionsNames.ManageUser;
    });
  }

  // Check view permissions
  const hasViewPermission = checkPermission(selectedCompanyPermission, {
    permissionName: PermissionsNames.ManageUser,
    permission: ["view"],
  });

  // Check view permissions based on location
  useEffect(() => {
    if (
      location.pathname.startsWith("/settings/users") &&
      selectedCompanyPermission?.length
    ) {
      if (!hasViewPermission) {
        navigate("/");
      }
    }
  }, [location, selectedCompanyPermission?.length]);

  // Format user data
  useEffect(() => {
    if (UserDataState?.data?.length === 0 && currentPage > 1) {
      const query: any = {
        page: currentPage - 1,
        limit: PageSize,
        search: searchValue,
        filter: filterValue,
        sort: type,
        type: sortDirection === "ascend" ? "asc" : "desc",
      };

      if (filterValue === "all") {
        delete query?.filter;
      }

      dispatch(getUsersAction(query)).then((res: any) => {
        if (res?.payload?.error?.status === 401) {
          const response = {
            isLoggedIn: true,
            isErrorMessage: res?.payload?.error?.message,
          };
          dispatch(userCheck(response));
        }
      });
      setCurrentPage(currentPage - 1);
    }

    const data = userData?.map((user: any) => {
      const phone = user?.user?.phone
        ? formatPhoneNumber(user?.user?.phone?.toString())
        : user?.user?.phone;
      return {
        id: user?.id,
        userId: user?.userId,
        name: user?.user?.fullName,
        email: user?.user?.email,
        phone: phone,
        simplePhone: user?.user?.phone,
        role: user?.role?.roleName,
        roleId: user?.roleId,
        status: user?.status,
        isAdmin: user?.role?.isAdminRole,
        isCompanyAdmin: user?.role?.isCompanyAdmin,
        roleStatus: user.role.status,
        isVerified: user?.user?.isVerified,
        LastSignIn: user?.user?.lastSignInAt
          ? formatDate(user?.user?.lastSignInAt)
          : "-",
      };
    });
    setFormateData(data);
  }, [UserDataState]);

  // Fetch roles
  useEffect(() => {
    dispatch(
      getRoleAction({
        url: `page=${1}&limit=1000000`,
      })
    );
  }, []);

  // Fetch user data
  const fetchUsers = () => {
    const query: any = {
      page: currentPage,
      limit: PageSize,
      search: searchValue,
      filter: filterValue,
      sort: "fullName",
      type: type,
    };

    if (filterValue === "all") {
      delete query.filter;
    }

    dispatch(getUsersAction(query)).then((res: any) => {
      if (res?.payload?.error?.status === 401) {
        const response = {
          isLoggedIn: true,
          isErrorMessage: res?.payload?.error?.message,
        };
        dispatch(userCheck(response));
      }
    });
  };

  useEffect(() => {
    fetchUsers();
  }, [PageSize, currentPage, filterValue, searchValue, type]);

  // Remove the side drawer
  const removeDrawerFromDom = () => {
    setSideDrawerOpen(false);
  };

  // Open side drawer with animation
  const openDrawerHandler = () => {
    setDrawerInfo({ drawerTitle: "Add Users" });
    setDrawerAnimation(true);
    setSideDrawerOpen(true);
  };

  // Close the side drawer with animation
  const closeDrawerByAnimation = () => {
    !isAddUserLoading && setEditSelectedUser(undefined);
    !isAddUserLoading && setDrawerAnimation(false);
  };

  // Handle search operation
  const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);
    setCurrentPage(1);
  };

  // Handle sorting operation
  const performSortHandler = (type: string) => {
    console.log("🚀 ~ performSortHandler ~ type:", type)
    if (type !== sortDirection) {
      SetsortDirection(type);
      setType(type === "ascend" ? "asc" : "desc");
      setCurrentPage(1);
    }
  };

  // Handle pagination for the table
  const paginationChangeHandler = (pageNo: number, pageSize: number) => {
    setCurrentPage(pageNo);
    setPageSize(pageSize);
  };

  // Modify the page size
  const modifyPageSize = (current: number, size: number) => {
    if (!size) {
      setPageSize(10);
    } else {
      setPageSize(size);
      localStorage.setItem("userTableSize", size.toString());
    }
    setCurrentPage(1);
  };

  // Open the modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // Change data and title between components
  const setDrawerInfoHandler = (drawerTitle: any) => {
    setDrawerInfo({ drawerTitle });
  };

  // Confirm operation
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // Cancel operation
  const handleCancel = () => {
   !isAddUserLoading && setIsModalOpen(false);
  };

  // Delete user
  const deleteHandler = () => {
    if (editSelectedUser) {
      dispatch(deleteUserAction(editSelectedUser)).then(() => {
        !isAddUserLoading && setIsModalOpen(false);
        !isAddUserLoading && setEditSelectedUser(undefined);
      });
    }
  };

  // JSX
  return (
    <>
      <div className={styles["user-table"]}>
        {!fistTimeFetchLoading ? (
          <div>
            <TableActionHeader title={"Users"}>
              <div className={styles["user-table__action"]}>
                {localStorage.getItem("companyId") !== "undefined" &&
                  DashboardPermission?.add && (
                    <button
                      className={`btn-blue ${styles["user-table__action--button"]}`}
                      onClick={openDrawerHandler}
                    >
                      <AddSvg />
                      <p>Add User</p>
                    </button>
                  )}
              </div>
            </TableActionHeader>
            <div>
              <DynamicTable
                userDataSource={formateData}
                userColumns={userColumns}
                paginationChangeHandler={paginationChangeHandler}
                currentPage={currentPage}
                totalRecords={totalRecords}
                performSearchHandler={performSearchHandler}
                searchValue={searchValue}
                showModal={showModal}
                openDrawerHandler={openDrawerHandler}
                setDrawerInfoHandler={setDrawerInfoHandler}
                setEditSelectedUser={setEditSelectedUser}
                performSortHandler={(type: any) => performSortHandler(type)}
                performFilterHandler={(value) => setFilterValue(value)}
                filterValue={filterValue}
                permissions={DashboardPermission}
                PageSize={PageSize}
                modifyPageSize={modifyPageSize}
                sortDirection={sortDirection}
                isLoading={isLoading}
                isAddUserLoading={isAddUserLoading}
              />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
      <ConfirmDelete
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        deleteHandler={deleteHandler}
        // isLoading={isLoading}
        isAddUserLoading={isAddUserLoading}
      />
      {isSideDrawerOpen && (
        <SideDrawerWrapper
          isOpen={drawerAnimation}
          removeDrawerFromDom={removeDrawerFromDom}
          closeDrawerByAnimation={closeDrawerByAnimation}
          headerTitle={drawerInfo.drawerTitle}
          position="right"
          width="half"
        >
          <AddUserBody
            closeDrawerByAnimation={closeDrawerByAnimation}
            editSelectedUser={editSelectedUser}
            setEditSelectedUser={setEditSelectedUser}
          />
        </SideDrawerWrapper>
      )}
    </>
  );
};

export default UsersTable;
