/* eslint-disable react-hooks/rules-of-hooks */
import DynamicCategoryTable from "components/Global/DynamicCategoryTable";
import { PermissionsNames } from "constants/Data";
import { UserProfileInterface } from "interfaces/user.interface";
import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPermission } from "utils/utils";

const EcommerceTable: FC = () => {
  // Get the current location
  const location = useLocation();
  // Initialize navigation function
  const navigate = useNavigate();

  const integrationList = useSelector((state: any) => state?.Integration?.data);
  const isLoading = useSelector((state: any) => state?.Integration?.isLoading);

  const ecommerceDataList = integrationList?.filter((items: any) => items.integrationType === 'ECOMMERCE')

  // Get permissions from Redux state
  const selectedCompanyPermission = useSelector(
    (state: UserProfileInterface) =>
      state?.userProfile?.data?.companies[0]?.role?.permissions
  );

  // Check if the user has view permission for Integrations
  const hasViewPermission = checkPermission(selectedCompanyPermission, {
    permissionName: PermissionsNames.AccessAllIntegrations,
    permission: ["view"],
  });

  useEffect(() => {
    // Check if the user is on the Integrations page and doesn't have view permission
    if (
      location.pathname.startsWith("/settings/integrations") &&
      selectedCompanyPermission?.length &&
      !hasViewPermission
    ) {
      navigate("/");

    } 
  }, [location, selectedCompanyPermission?.length]);

  return (
    <DynamicCategoryTable
      dataSource={ecommerceDataList}
      type={"ECOMMERCE".toLowerCase()}
      isLoading={isLoading}
    />
  );
};

export default EcommerceTable;
