import { FORMDATA } from "constants/Data";

export {
  // integrationsAccountCards,
  integrationsEcommerceCards,
  integrationsERPCards,
  integrationsMarketplaceCards,
  integrationsPaymentCards,
  integrationsCRMCards,
  channelTypeCards,
};

export const CssSpriteNames = {
  QUICKBOOKS: "QuickbooksOnline",
  QUICKBOOKSDESKTOP: "QuickbooksDesktop",
  XERO: "Xero",
  ZOHOBOOK: "ZohoBook",
  CLEARBOOK: "ClearBook",
  FRESHBOOKS: "FreshBooks",
  WAVEACCOUNT: "WaveAccount",
  SAGEINTACCT: "SageIntacct",
  SAP: "SAP",
  BUSINESSCENTRAL: "BusinessCentral",
  ODOO: "Odoo",
  ACUMATICA: "Acumatica",
  BRIGHTPEARL: "BrightPearl",
  MSDYNAMIC365: "MsDynamic",
  SHOPIFY: "Shopify",
  WOOCOMMERCE: "WooCommerce",
  MAGENTO: "Magento",
  BIGCOMMERCE: "BigCommerce",
  SHOPWARE: "ShopWare",
};

export const IntegrationDescriptions = {
  QUICKBOOKS:
    "is a cloud-based accounting software that simplifies financial management for businesses.",
  QUICKBOOKSDESKTOP:
    "is a robust accounting software for small and medium-sized businesses that provides comprehensive financial management tools and can be installed on a local computer.",
  XERO: "is an easy-to-use online accounting software designed for small businesses and their advisors.",
  ZOHOBOOK:
    "is an online accounting software designed for small businesses to manage their finances efficiently.",
  CLEARBOOK:
    "is an online accounting software designed for small businesses, offering features for invoicing, expense tracking, and financial reporting.",
  FRESHBOOKS:
    "is Cloud-based accounting software tailored for freelancers and small businesses, known for its user-friendly interface, time tracking, and invoicing capabilities.",
  WAVEACCOUNT:
    "is a cloud-based accounting and financial management software platform designed primarily for small businesses, entrepreneurs, and freelancers.",
  SAGEINTACCT:
    "is a cloud-based financial management solution that helps businesses grow and scale.",
  SAP: "ERP software offers comprehensive business solutions for enterprises.",
  BUSINESSCENTRAL:
    "is an all-in-one business management solution that helps streamline financials, sales, service, and operations.",
  ODOO: "is an open-source ERP system that offers a suite of business applications for various functions.",
  ACUMATICA:
    "is a cloud-based ERP solution designed to support businesses with flexible and scalable operations.",
  BRIGHTPEARL:
    "by Sage is a retail operations platform that helps businesses manage their orders, inventory, and customer data.",
  MSDYNAMIC365:
    "is a suite of intelligent business applications that help organizations manage their operations and customer relationships.",
  SHOPIFY: "integration allows you to sell products online.",
  WOOCOMMERCE: "is a flexible and customizable eCommerce platform.",
  MAGENTO: "integration empowers your online store with powerful features.",
  BIGCOMMERCE:
    "is a leading e-commerce platform that empowers businesses to build and manage online stores with a wide range of customizable features and integrations.",
  SHOPWARE: "offers scalable eCommerce solutions for your business.",
};
export const DescriptionKeys = {
  QUICKBOOKS: "QuickBooks Online",
  QUICKBOOKSDESKTOP: "QuickBooks Desktop",
  XERO: "Xero",
  ZOHOBOOK: "Zoho Books",
  CLEARBOOK: "ClearBooks",
  FRESHBOOKS: "FreshBooks",
  WAVEACCOUNT: "Wave Accounting",
  SAGEINTACCT: "Sage Intacct",
  SAP: "SAP",
  BUSINESSCENTRAL: "Business Central",
  ODOO: "Odoo",
  ACUMATICA: "Acumatica",
  BRIGHTPEARL: "Brightpearl",
  MSDYNAMIC365: "Microsoft Dynamics 365",
  SHOPIFY: "Shopify",
  WOOCOMMERCE: "WooCommerce",
  MAGENTO: "Magento",
  BIGCOMMERCE: "BigCommerce",
  SHOPWARE: "Shopware",
};

export const GetClientIdReference = {
  QUICKBOOKS:
    "https://docs.google.com/document/d/15A6K_DEYEtYx_swp0dqYiafyNgpeK1eI0bx45TYDOHU/edit#heading=h.z6opy5thch6e",
  QUICKBOOKSDESKTOP: "QuickBooks Desktop",
  XERO: "https://docs.google.com/document/d/1HB_FNGxl7jfMFv9xSj7q3wIjrIQIqt53w2x5Wn_fQ28/edit#heading=h.z6opy5thch6e",
  ZOHOBOOK:
    "https://www.zoho.com/accounts/protocol/oauth/self-client/authorization-code-flow.html",
  CLEARBOOK: "ClearBooks",
  FRESHBOOKS: "FreshBooks",
  WAVEACCOUNT: "Wave Accounting",
  SAGEINTACCT: "Sage Intacct",
  SAP: "SAP",
  BUSINESSCENTRAL: "Business Central",
  ODOO: "Odoo",
  ACUMATICA: "Acumatica",
  BRIGHTPEARL: "Brightpearl",
  MSDYNAMIC365: "Microsoft Dynamics 365",
  SHOPIFY: "Shopify",
  WOOCOMMERCE: "WooCommerce",
  MAGENTO: "Magento",
  BIGCOMMERCE: "BigCommerce",
  SHOPWARE: "Shopware",
};

export const QuickBookOnlineScopes = {
  Accounting: {
    value: "com.intuit.quickbooks.accounting",
    description: "manage accounting tasks",
  },
  Payment: {
    value: "com.intuit.quickbooks.payment",
    description: "process payments",
  },
  Payroll: {
    value: "com.intuit.quickbooks.payroll",
    description: "handle payroll activities",
  },
  TimeTracking: {
    value: "com.intuit.quickbooks.payroll.timetracking",
    description: "track employee work hours",
  },
  Benefits: {
    value: "com.intuit.quickbooks.payroll.benefits",
    description: "manage employee benefits",
  },
  Profile: { value: "profile", description: "access user profile information" },
  Email: { value: "email", description: "access user email information" },
  Phone: { value: "phone", description: "access user phone information" },
  Address: { value: "address", description: "access user address information" },
  OpenId: { value: "openid", description: "access user openid information" },
  Intuit_name: {
    value: "intuit_name",
    description: "access user intuit name information",
  },
};

export const XeroScopes = {
  OpenID: {
    value: "openid",
    description: "Your application intends to use the user’s identity",
  },
  Profile: {
    value: "profile",
    description:
      "Access user's first name, last name, full name, and Xero user ID",
  },
  Email: {
    value: "email",
    description: "Access user's email address",
  },
  AccountingTransactions: {
    value: "accounting.transactions",
    description: "View and manage your business transactions",
  },
  AccountingTransactionsRead: {
    value: "accounting.transactions.read",
    description: "View your business transactions",
  },
  AccountingReports: {
    value: "accounting.reports.read",
    description: "View your reports",
  },
  AccountingReportsTenNinetyNine: {
    value: "accounting.reports.tenninetynine.read",
    description: "View your 1099 reports",
  },
  AccountingJournals: {
    value: "accounting.journals.read",
    description: "View your general ledger",
  },
  AccountingSettings: {
    value: "accounting.settings",
    description: "View and manage your organisation settings",
  },
  AccountingSettingsRead: {
    value: "accounting.settings.read",
    description: "View your organisation settings",
  },
  AccountingContacts: {
    value: "accounting.contacts",
    description: "View and manage your contacts",
  },
  AccountingContactsRead: {
    value: "accounting.contacts.read",
    description: "View your contacts",
  },
  AccountingAttachments: {
    value: "accounting.attachments",
    description: "View and manage your attachments",
  },
  AccountingAttachmentsRead: {
    value: "accounting.attachments.read",
    description: "View your attachments",
  },
  AccountingBudgetsRead: {
    value: "accounting.budgets.read",
    description: "View your budgets",
  },
  PayrollEmployes: {
    value: "payroll.employees",
    description: "View and manage your employees",
  },
  PayrollEmployesRead: {
    value: "payroll.employees.read",
    description: "View your employees",
  },
  PayrollPayruns: {
    value: "payroll.payruns",
    description: "View and manage your pay runs",
  },
  PayrollPayrunsRead: {
    value: "payroll.payruns.read",
    description: "View your pay runs",
  },
  PayrollPayslip: {
    value: "payroll.payslip",
    description: "View and manage your payslips",
  },
  PayrollPayslipRead: {
    value: "payroll.payslip.read",
    description: "View your payslips",
  },
  PayrollTimesheets: {
    value: "payroll.timesheets",
    description: "View and manage your timesheets",
  },
  PayrollTimesheetsRead: {
    value: "payroll.timesheets.read",
    description: "View your timesheets",
  },
  PayrollSettings: {
    value: "payroll.settings",
    description: "View and manage your payroll settings",
  },
  PayrollSettingsRead: {
    value: "payroll.settings.read",
    description: "View your payroll settings",
  },

  Files: {
    value: "files",
    description: "View and manage your file library",
  },
  FilesRead: {
    value: "files.read",
    description: "View your file library",
  },
  Assets: {
    value: "assets",
    description: "View and manage your fixed assets",
  },
  AssetsRead: {
    value: "assets.read",
    description: "View your fixed assets",
  },
  Projects: {
    value: "projects",
    description: "View and manage your projects",
  },
  ProjectsRead: {
    value: "projects.read",
    description: "View your projects",
  },
  PaymentServices: {
    value: "paymentservices",
    description:
      "View and manage your payment services (requires additional certification)",
  },
  BankFeeds: {
    value: "bankfeeds",
    description:
      "View and manage your bank statements (requires additional certification)",
  },
  Finance: {
    value: "finance",
    description:
      "View your Xero usage activity, bank statement data, financial statements (requires additional certification)",
  },

  WorkflowMax: {
    value: "workflowmax",
    description: "View and manage your WorkflowMax data",
  },
  WorkflowMaxRead: {
    value: "workflowmax.read",
    description: "View your WorkflowMax data",
  },
  workflowmaxJob: {
    value: "workflowmax.job",
    description:
      "View and manage your WorkflowMax job data. Access to job endpoints",
  },
  workflowmaxJobRead: {
    value: "workflowmax.job.read",
    description: "View your WorkflowMax job data. As above but GET only",
  },
  workflowmaxClient: {
    value: "workflowmax.client",
    description:
      "View and manage your WorkflowMax client data. Access to client and client group endpoints",
  },
  workflowmaxClientRead: {
    value: "workflowmax.client.read",
    description: "View your WorkflowMax client data. As above but GET only",
  },
  workflowmaxStaff: {
    value: "workflowmax.staff",
    description:
      "View and manage your WorkflowMax staff data. Access to staff endpoints",
  },
  workflowmaxStaffRead: {
    value: "workflowmax.staff.read",
    description: "View your WorkflowMax staff data. As above but GET only",
  },
  workflowmaxTime: {
    value: "workflowmax.time",
    description:
      "View and manage your WorkflowMax time data. Access to time endpoints",
  },
  workflowmaxTimeRead: {
    value: "workflowmax.time.read",
    description: "View your WorkflowMax time data. As above but GET only",
  },
  PracticeManager: {
    value: "practicemanager",
    description: "View and manage your Practice Manager data",
  },
  PracticeManagerRead: {
    value: "practicemanager.read",
    description: "View your Practice Manager data",
  },
  PracticeManagerJob: {
    value: "practicemanager.job",
    description:
      "View and manage your Practice Manager job data. Access to job endpoints",
  },
  PracticeManagerJobRead: {
    value: "practicemanager.job.read",
    description: "View your Practice Manager job data. As above but GET only",
  },
  PracticeManagerClient: {
    value: "practicemanager.client",
    description:
      "View and manage your Practice Manager client data. Access to client endpoints and client group endpoints",
  },
  PracticeManagerClientRead: {
    value: "practicemanager.client.read",
    description:
      "View your Practice Manager client data. As above but GET only",
  },
  PracticeManagerStaff: {
    value: "practicemanager.staff",
    description:
      "View and manage your Practice Manager staff data. Access to staff endpoints",
  },
  PracticeManagerStaffRead: {
    value: "practicemanager.staff.read",
    description: "View your Practice Manager staff data. As above but GET only",
  },
  PracticeManagerTime: {
    value: "practicemanager.time",
    description:
      "View and manage your Practice Manager time data. Access to time endpoints",
  },
  PracticeManagerTimeRead: {
    value: "practicemanager.time.read",
    description: "View your Practice Manager time data. As above but GET only",
  },

  hqClientsRead: {
    value: "hq.clients.read",
    description: "View your client data. Clients, Industry Codes",
  },
  hqStaff: {
    value: "hq.staff",
    description: "View and manage your staff data. Staff",
  },
  hqStaffRead: {
    value: "hq.staff.read",
    description: "View your staff data. As above but GET only",
  },
  EInvoicing: {
    value: "einvoicing",
    description: "View and manage registration information for eInvoicing",
  },
  NonTenanted: {
    value: "app.connections",
    description: "View your connection data",
  },
  NonTenantedMarketPlace: {
    value: "marketplace.billing",
    description: "View and manage Xero App Store data",
  },
};

export const ZohoBooksScopes = {
  Contacts: {
    value:
      "ZohoBooks.contacts.Create,ZohoBooks.contacts.UPDATE,ZohoBooks.contacts.READ,ZohoBooks.contacts.DELETE",
    description: "To access contacts related APIs",
  },
  Settings: {
    value:
      "ZohoBooks.settings.Create,ZohoBooks.settings.UPDATE,ZohoBooks.settings.READ,ZohoBooks.settings.DELETE",
    description:
      "To access items, expense categories, users, taxes, currencies, opening balances related APIs",
  },
  Estimates: {
    value:
      "ZohoBooks.estimates.Create,ZohoBooks.estimates.UPDATE,ZohoBooks.estimates.READ,ZohoBooks.estimates.DELETE",
    description: "To access estimates related APIs",
  },
  Invoices: {
    value:
      "ZohoBooks.invoices.Create,ZohoBooks.invoices.UPDATE,ZohoBooks.invoices.READ,ZohoBooks.invoices.DELETE",
    description: "To access invoices related APIs",
  },
  CustomerPayments: {
    value:
      "ZohoBooks.customerpayments.Create,ZohoBooks.customerpayments.UPDATE,ZohoBooks.customerpayments.READ,ZohoBooks.customerpayments.DELETE",
    description: "To access customer payments related APIs",
  },
  CreditNotes: {
    value:
      "ZohoBooks.creditnotes.Create,ZohoBooks.creditnotes.UPDATE,ZohoBooks.creditnotes.READ,ZohoBooks.creditnotes.DELETE",
    description: "To access credit notes related APIs",
  },
  Projects: {
    value:
      "ZohoBooks.projects.Create,ZohoBooks.projects.UPDATE,ZohoBooks.projects.READ,ZohoBooks.projects.DELETE",
    description: "To access projects related APIs",
  },
  Expenses: {
    value:
      "ZohoBooks.expenses.Create,ZohoBooks.expenses.UPDATE,ZohoBooks.expenses.READ,ZohoBooks.expenses.DELETE",
    description: "To access expenses related APIs",
  },
  SalesOrder: {
    value:
      "ZohoBooks.salesorders.Create,ZohoBooks.salesorders.UPDATE,ZohoBooks.salesorders.READ,ZohoBooks.salesorders.DELETE",
    description: "To access salesorder related APIs",
  },
  PurchaseOrder: {
    value:
      "ZohoBooks.purchaseorders.Create,ZohoBooks.purchaseorders.UPDATE,ZohoBooks.purchaseorders.READ,ZohoBooks.purchaseorders.DELETE",
    description: "To access purchaseorder related APIs",
  },
  Bills: {
    value:
      "ZohoBooks.bills.Create,ZohoBooks.bills.UPDATE,ZohoBooks.bills.READ,ZohoBooks.bills.DELETE",
    description: "To access bills related APIs",
  },
  DebitNotes: {
    value:
      "ZohoBooks.debitnotes.Create,ZohoBooks.debitnotes.UPDATE,ZohoBooks.debitnotes.READ,ZohoBooks.debitnotes.DELETE",
    description: "To access debitnote related APIs",
  },
  VendorPayments: {
    value:
      "ZohoBooks.vendorpayments.Create,ZohoBooks.vendorpayments.UPDATE,ZohoBooks.vendorpayments.READ,ZohoBooks.vendorpayments.DELETE",
    description: "To access vendorpayment related APIs",
  },
  Banking: {
    value:
      "ZohoBooks.banking.Create,ZohoBooks.banking.UPDATE,ZohoBooks.banking.READ,ZohoBooks.banking.DELETE",
    description: "To access banking related APIs",
  },
  Accountants: {
    value:
      "ZohoBooks.accountants.Create,ZohoBooks.accountants.UPDATE,ZohoBooks.accountants.READ,ZohoBooks.accountants.DELETE",
    description: "To access accountant related APIs",
  },
};

export const WaveAccountingScopes = {
  "account:read": {
    value: "account:read",
    description: "Read access to Account resource",
  },
  "account:write": {
    value: "account:write",
    description: "Write access to Account resource",
  },
  "business:read": {
    value: "business:read",
    description: "Read access to Business resource",
  },
  "business:write": {
    value: "business:write",
    description: "Write access to Business resource",
  },
  "customer:read": {
    value: "customer:read",
    description: "Read access to Customer resource",
  },
  "customer:write": {
    value: "customer:write",
    description: "Write access to Customer resource",
  },
  "invoice:read": {
    value: "invoice:read",
    description: "Read access to Invoice resource",
  },
  "invoice:write": {
    value: "invoice:write",
    description: "Write access to Invoice resource",
  },
  "invoice:send": {
    value: "invoice:send",
    description: "Send a created Invoice",
  },
  "product:read": {
    value: "product:read",
    description: "Read access to Product resource",
  },
  "product:write": {
    value: "product:write",
    description: "Write access to Product resource",
  },
  "sales_tax:read": {
    value: "sales_tax:read",
    description: "Read access to SalesTax resource",
  },
  "sales_tax:write": {
    value: "sales_tax:write",
    description: "Write access to SalesTax resource",
  },
  "transaction:write": {
    value: "transaction:write",
    description: "Write access to MoneyTransaction resource",
  },
  "user:read": {
    value: "user:read",
    description: "Read access to User resource",
  },
  "vendor:read": {
    value: "vendor:read",
    description: "Read access to Vendor resource",
  },
  "vendor:write": {
    value: "vendor:write",
    description: "Write access to Vendor resource",
  },
};

export const UUID = () => (Math.random() + 1).toString(36).substring(2);

export const AddUniqueID = (Arr: any[], lable: string) =>
  Arr.map((d: any) => {
    return {
      ...d,
      [lable]: UUID(),
    };
  });

// const integrationsAccountCards = AddUniqueID(
//   [
//     {
//       title: "Connect With Quickbooks Online",
//       description:
//         "is a cloud-based accounting software that simplifies financial management for businesses.",
//       buttonText: "Connect",
//       logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Accounting/quickbooks-online.png`,
//       ghost: false,
//       select: false,
//       type: "Accounting",
//       connect: false,
//       titleKey: "QuickbooksOnline",
//       cssSpriteKey: "QuickbooksOnline",
//       DescriptionKey: "QuickBooks Online",
//     },
//     {
//       title: "Connect With Quickbooks Desktop",
//       description:
//         "is a robust accounting software for small and medium-sized businesses that provides comprehensive financial management tools and can be installed on a local computer.",
//       buttonText: "Connect",
//       logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Accounting/quickbooks-desktop.png`,
//       ghost: false,
//       select: false,
//       type: "Accounting",
//       connect: false,
//       titleKey: "QuickbooksDesktop",
//       cssSpriteKey: "QuickbooksDesktop",
//       DescriptionKey: "QuickBooks Desktop",
//     },
//     {
//       title: "Connect With Xero",
//       description:
//         "is an easy-to-use online accounting software designed for small businesses and their advisors.",
//       buttonText: "Connect",
//       logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Accounting/xero.png`,
//       ghost: false,
//       select: false,
//       type: "Accounting",
//       connect: false,
//       titleKey: "Xero",
//       cssSpriteKey: "Xero",
//       DescriptionKey: "Xero",
//     },

//     {
//       title: "Connect With Zoho Books",
//       description:
//         "is an online accounting software designed for small businesses to manage their finances efficiently.",
//       buttonText: "Connect",
//       logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Accounting/zohobooks.png`,
//       ghost: false,
//       select: false,
//       type: "Accounting",
//       connect: false,
//       titleKey: "Zoho Books",
//       cssSpriteKey: "ZohoBook",
//       DescriptionKey: "Zoho Books",
//     },
//     {
//       title: "Connect With Clear Books",
//       description:
//         "is an online accounting software designed for small businesses, offering features for invoicing, expense tracking, and financial reporting.",
//       buttonText: "Connect",
//       logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Accounting/clearbooks.png`,
//       ghost: false,
//       select: false,
//       type: "Accounting",
//       connect: false,
//       formData: FORMDATA.clearBookIntegrationFields,
//       titleKey: "Clear Books",
//       cssSpriteKey: "ClearBook",
//       DescriptionKey: "Clear Books",
//     },
//     {
//       title: "Connect With Fresh Books",
//       description:
//         "is Cloud-based accounting software tailored for freelancers and small businesses, known for its user-friendly interface, time tracking, and invoicing capabilities.",
//       buttonText: "Connect",
//       logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Accounting/freshbooks.png`,
//       ghost: false,
//       select: false,
//       type: "Accounting",
//       connect: false,
//       titleKey: "Fresh Books",
//       cssSpriteKey: "FreshBooks",
//       DescriptionKey: "FreshBooks",
//     },
//     {
//       title: "Connect With Wave Accounting",
//       description:
//         "is a cloud-based accounting and financial management software platform designed primarily for small businesses, entrepreneurs, and freelancers.",
//       buttonText: "Connect",
//       ghost: false,
//       select: false,
//       type: "Accounting",
//       connect: false,
//       titleKey: "WaveAccount",
//       cssSpriteKey: "WaveAccount",
//       DescriptionKey: "Wave",
//     },
//   ],
//   "id"
// );
const integrationsERPCards = AddUniqueID(
  [
    {
      title: "Connect With Netsuite",
      description:
        "is a cloud-based ERP system that helps streamline business processes.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/ERP/oracle-netsuite.png`,
      formData: FORMDATA.netsuiteIntegrationFields,
      ghost: false,
      select: false,
      type: "ERP",
      connect: false,
      titleKey: "Netsuite",
      cssSpriteKey: "NetSuite",
      DescriptionKey: "NetSuite",
    },
    {
      title: "Connect With Sage Intacct",
      description:
        "is a cloud-based financial management solution that helps businesses grow and scale.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/ERP/sage-intacct.png`,
      formData: FORMDATA.sageIntegrationFields,
      ghost: false,
      select: false,
      type: "Accounting",
      connect: false,
      titleKey: "Sage Intacct",
      cssSpriteKey: "SageIntacct",
      DescriptionKey: "Sage Intacct",
    },
    {
      title: "Connect With SAP",
      description:
        "ERP software offers comprehensive business solutions for enterprises.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/ERP/sap.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "ERP",
      connect: false,
      titleKey: "SAP",
      cssSpriteKey: "SAP",
      DescriptionKey: "SAP",
    },
    {
      title: "Connect With Microsoft Business Central",
      description:
        "is an all-in-one business management solution that helps streamline financials, sales, service, and operations.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/ERP/microsoft-business-central.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "ERP",
      connect: false,
      titleKey: "Business Central",
      cssSpriteKey: "BusinessCentral",
      DescriptionKey: "Microsoft Business Central",
    },
    {
      title: "Connect With Odoo",
      description:
        "is an open-source ERP system that offers a suite of business applications for various functions.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/ERP/odoo.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "ERP",
      connect: false,
      titleKey: "Odoo",
      cssSpriteKey: "Odoo",
      DescriptionKey: "Odoo",
    },
    {
      title: "Connect With Acumatica",
      description:
        "is a cloud-based ERP solution designed to support businesses with flexible and scalable operations.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/ERP/acumatica.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "ERP",
      connect: false,
      titleKey: "Acumatica",
      cssSpriteKey: "Acumatica",
      DescriptionKey: "Acumatica",
    },
    {
      title: "Connect With Brightpearl by Sage",
      description:
        "by Sage is a retail operations platform that helps businesses manage their orders, inventory, and customer data.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/ERP/brightpearl.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "Retail",
      connect: false,
      titleKey: "Brightpearl by Sage",
      cssSpriteKey: "BrightPearl",
      DescriptionKey: "Brightpearl",
    },
    {
      title: "Connect With Dynamics 365",
      description:
        "is a suite of intelligent business applications that help organizations manage their operations and customer relationships.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/ERP/dynamics-365.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "ERP",
      connect: false,
      titleKey: "MsDynamic",
      cssSpriteKey: "MsDynamic",
      DescriptionKey: "Dynamics 365",
    },
  ],
  "id"
);
const integrationsEcommerceCards = AddUniqueID(
  [
    {
      title: "Connect With Shopify",
      description: "integration allows you to sell products online.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Ecommerce/shopify.png`,
      formData: FORMDATA.shopifyIntegrationFields,
      ghost: false,
      select: false,
      type: "Ecommerce",
      connect: false,
      titleKey: "Shopify",
      cssSpriteKey: "Shopify",
      DescriptionKey: "Shopify",
    },
    {
      title: "Connect With WooCommerce",
      description: "is a flexible and customizable eCommerce platform.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Ecommerce/woo-commerce.png`,
      ghost: false,
      select: false,
      formData: FORMDATA.wooIntegrationFields,
      type: "Ecommerce",
      connect: false,
      titleKey: "Woo Commerce",
      cssSpriteKey: "WooCommerce",
      DescriptionKey: "WooCommerce",
    },
    {
      title: "Connect With Magento Online",
      description:
        "integration empowers your online store with powerful features.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Ecommerce/magento.png`,
      formData: FORMDATA.magentoIntegrationFields,
      ghost: false,
      select: false,
      type: "Ecommerce",
      connect: false,
      titleKey: "Magento",
      cssSpriteKey: "Magento",
      DescriptionKey: "Magento",
    },
    {
      title: "Connect With ShopWare",
      description: "offers scalable eCommerce solutions for your business.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Ecommerce/shopware.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "Ecommerce",
      connect: false,
      titleKey: "ShopWare",
      cssSpriteKey: "ShopWare",
      DescriptionKey: "ShopWare",
    },
    {
      title: "Connect With Big Commerce",
      description:
        "is a leading e-commerce platform that empowers businesses to build and manage online stores with a wide range of customizable features and integrations.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Ecommerce/big-commerce.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "Ecommerce",
      connect: false,
      titleKey: "Big commerce",
      cssSpriteKey: "BigCommerce",
      DescriptionKey: "BigCommerce",
    },
  ],
  "id"
);
const integrationsMarketplaceCards = AddUniqueID(
  [
    {
      title: "Connect With Amazon",
      description:
        "is a leading online platform for selling products to a wide audience.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Marketplace/amazon-marketplace.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "Marketplace",
      connect: false,
      titleKey: "AmazonMarketPlace",
      cssSpriteKey: "AmazonMarketPlace",
      DescriptionKey: "Amazon MarketPlace",
    },
    {
      title: "Connect With eBay",
      description:
        "is a popular online marketplace where you can buy and sell a wide range of products.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Marketplace/ebay.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "Marketplace",
      connect: false,
      titleKey: "Ebay",
      cssSpriteKey: "Ebay",
      DescriptionKey: "eBay",
    },
    {
      title: "Connect With Walmart",
      description:
        "Marketplace allows you to reach a large customer base with your products.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Marketplace/walmart.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "Marketplace",
      connect: false,
      titleKey: "Walmart",
      cssSpriteKey: "Walmart",
      DescriptionKey: "Walmart",
    },
  ],
  "id"
);
const integrationsPaymentCards = AddUniqueID(
  [
    {
      title: "Connect With Stripe",
      description:
        "is a popular online payment processing platform for businesses of all sizes.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Payment/stripe.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "Payment",
      connect: false,
      titleKey: "Stripe",
      cssSpriteKey: "Stripe",
      DescriptionKey: "Stripe",
    },
    {
      title: "Connect With Authorize.net",
      description:
        "offers secure payment gateway solutions for online merchants.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Payment/authorize-net.png`,
      formData: FORMDATA.authorizeNetIntegrationFields,
      ghost: false,
      select: false,
      type: "Payment",
      connect: false,
      titleKey: "Authorize.net",
      cssSpriteKey: "AuthorizeNet",
      DescriptionKey: "Authorize.net",
    },
  ],
  "id"
);
const integrationsCRMCards = AddUniqueID(
  [
    {
      title: "Connect With Zoho CRM",
      description:
        "is a comprehensive customer relationship management solution that helps businesses of all sizes manage their sales, marketing, and support processes.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/CRM/zoho-crm.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "CRM",
      connect: false,
      titleKey: "Zoho CRM",
      cssSpriteKey: "ZohoCRM",
      DescriptionKey: "Zoho CRM",
    },
    {
      title: "Connect With Salesforce",
      description:
        "is a leading cloud-based CRM platform that enables companies to streamline their sales, service, and marketing operations for enhanced customer engagement.",
      buttonText: "Connect",
      logo: `${process.env.REACT_APP_IMAGES_BASE_URL}/assets/CRM/sales-force.png`,
      formData: "",
      ghost: false,
      select: false,
      type: "CRM",
      connect: false,
      titleKey: "Salesforce",
      cssSpriteKey: "Salesforce",
      DescriptionKey: "Salesforce",
    },
  ],
  "id"
);

const channelTypeCards = [
  // ...integrationsAccountCards,
  ...integrationsERPCards,
  ...integrationsEcommerceCards,
  ...integrationsMarketplaceCards,
  ...integrationsPaymentCards,
  ...integrationsCRMCards,
];

export const CountryList = [
  { name: "Afghanistan", code: "AF" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "Andorra", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Brazil", code: "BR" },
  { name: "Brunei", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cabo Verde", code: "CV" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo (Congo-Brazzaville)", code: "CG" },
  { name: "Costa Rica", code: "CR" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czechia (Czech Republic)", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Eswatini (fmr. Swaziland)", code: "SZ" },
  { name: "Ethiopia", code: "ET" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Greece", code: "GR" },
  { name: "Grenada", code: "GD" },
  { name: "Guatemala", code: "GT" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Holy See", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Laos", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia", code: "FM" },
  { name: "Moldova", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montenegro", code: "ME" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar (formerly Burma)", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "North Korea", code: "KP" },
  { name: "North Macedonia", code: "MK" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestine State", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Qatar", code: "QA" },
  { name: "Romania", code: "RO" },
  { name: "Russia", code: "RU" },
  { name: "Rwanda", code: "RW" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia", code: "RS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Korea", code: "KR" },
  { name: "South Sudan", code: "SS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syria", code: "SY" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States of America", code: "US" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Vietnam", code: "VN" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];

// export const CountryWiseTimeZones = {
//   "AF": ["(GMT+04:30) Kabul"],
//   "AL": ["(GMT+01:00) Tirana"],
//   "DZ": ["(GMT+01:00) Algiers"],
//   "AD": ["(GMT+01:00) Andorra"],
//   "AO": ["(GMT+01:00) Luanda"],
//   "AG": ["(GMT-04:00) Atlantic Time (Canada)"],
//   "AR": ["(GMT-03:00) Buenos Aires, Georgetown"],
//   "AM": ["(GMT+04:00) Yerevan"],
//   "AU": [
//     "(GMT+08:00) Perth",
//     "(GMT+10:00) Canberra, Melbourne, Sydney",
//     "(GMT+10:00) Brisbane",
//     "(GMT+09:30) Adelaide",
//     "(GMT+08:00) Darwin"
//   ],
//   "AT": ["(GMT+01:00) Vienna"],
//   "AZ": ["(GMT+04:00) Baku"],
//   "BS": ["(GMT-05:00) Eastern Time (US & Canada)"],
//   "BH": ["(GMT+03:00) Kuwait, Riyadh, Baghdad"],
//   "BD": ["(GMT+06:00) Dhaka"],
//   "BB": ["(GMT-04:00) Atlantic Time (Canada)"],
//   "BY": ["(GMT+03:00) Moscow, St. Petersburg, Volgograd"],
//   "BE": ["(GMT+01:00) Brussels"],
//   "BZ": ["(GMT-06:00) Central Time (US & Canada)"],
//   "BJ": ["(GMT+01:00) West Central Africa"],
//   "BT": ["(GMT+06:00) Thimphu"],
//   "BO": ["(GMT-04:00) Manaus", "(GMT-04:00) Santiago"],
//   "BA": ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
//   "BW": ["(GMT+02:00) Harare, Pretoria"],
//   "BR": ["(GMT-03:00) Brasilia"],
//   "BN": ["(GMT+08:00) Kuala Lumpur, Singapore"],
//   "BG": ["(GMT+02:00) Sofia"],
//   "BF": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "BI": ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
//   "CV": ["(GMT-01:00) Cape Verde Is."],
//   "KH": ["(GMT+07:00) Bangkok, Hanoi, Jakarta"],
//   "CM": ["(GMT+01:00) West Central Africa"],
//   "CA": [
//     "(GMT-07:00) Mountain Time (US & Canada)",
//     "(GMT-06:00) Central Time (US & Canada)",
//     "(GMT-05:00) Eastern Time (US & Canada)",
//     "(GMT-04:00) Atlantic Time (Canada)",
//     "(GMT-03:30) Newfoundland"
//   ],
//   "CF": ["(GMT+01:00) West Central Africa"],
//   "TD": ["(GMT+01:00) West Central Africa"],
//   "CL": ["(GMT-04:00) Santiago"],
//   "CN": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "CO": ["(GMT-05:00) Bogota, Lima, Quito, Rio Branco"],
//   "KM": ["(GMT+03:00) Nairobi"],
//   "CG": ["(GMT+01:00) West Central Africa"],
//   "CR": ["(GMT-06:00) Central Time (US & Canada)"],
//   "HR": ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
//   "CU": ["(GMT-05:00) Eastern Time (US & Canada)"],
//   "CY": ["(GMT+02:00) Beirut"],
//   "CZ": ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
//   "DK": ["(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"],
//   "DJ": ["(GMT+03:00) Nairobi"],
//   "DM": ["(GMT-04:00) Atlantic Time (Canada)"],
//   "DO": ["(GMT-04:00) Atlantic Time (Canada)"],
//   "EC": ["(GMT-05:00) Bogota, Lima, Quito, Rio Branco"],
//   "EG": ["(GMT+02:00) Cairo"],
//   "SV": ["(GMT-06:00) Central Time (US & Canada)"],
//   "GQ": ["(GMT+01:00) West Central Africa"],
//   "ER": ["(GMT+03:00) Nairobi"],
//   "EE": ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
//   "SZ": ["(GMT+02:00) Harare, Pretoria"],
//   "ET": ["(GMT+03:00) Nairobi"],
//   "FJ": ["(GMT+12:00) Auckland, Wellington"],
//   "FI": ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
//   "FR": ["(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"],
//   "GA": ["(GMT+01:00) West Central Africa"],
//   "GM": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "GE": ["(GMT+04:00) Tbilisi"],
//   "DE": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "GH": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "GR": ["(GMT+02:00) Athens, Bucharest, Istanbul"],
//   "GD": ["(GMT-04:00) Atlantic Time (Canada)"],
//   "GT": ["(GMT-06:00) Central Time (US & Canada)"],
//   "GN": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "GW": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "GY": ["(GMT-04:00) Guyana"],
//   "HT": ["(GMT-05:00) Eastern Time (US & Canada)"],
//   "VA": ["(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"],
//   "HN": ["(GMT-06:00) Central Time (US & Canada)"],
//   "HU": ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
//   "IS": ["(GMT+00:00) Dublin, Edinburgh, Lisbon, London"],
//   "IN": ["(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi"],
//   "ID": ["(GMT+07:00) Bangkok, Hanoi, Jakarta"],
//   "IR": ["(GMT+03:30) Tehran"],
//   "IQ": ["(GMT+03:00) Baghdad"],
//   "IE": ["(GMT+00:00) Dublin, Edinburgh, Lisbon, London"],
//   "IL": ["(GMT+02:00) Jerusalem"],
//   "IT": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "JM": ["(GMT-05:00) Eastern Time (US & Canada)"],
//   "JP": ["(GMT+09:00) Osaka, Sapporo, Tokyo"],
//   "JO": ["(GMT+02:00) Amman"],
//   "KZ": ["(GMT+06:00) Almaty, Novosibirsk"],
//   "KE": ["(GMT+03:00) Nairobi"],
//   "KI": ["(GMT+12:00) Fiji"],
//   "KW": ["(GMT+03:00) Kuwait, Riyadh, Baghdad"],
//   "KG": ["(GMT+06:00) Almaty, Novosibirsk"],
//   "LA": ["(GMT+07:00) Bangkok, Hanoi, Jakarta"],
//   "LV": ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
//   "LB": ["(GMT+02:00) Beirut"],
//   "LS": ["(GMT+02:00) Harare, Pretoria"],
//   "LR": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "LY": ["(GMT+02:00) Cairo"],
//   "LI": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "LT": ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
//   "LU": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "MG": ["(GMT+03:00) Nairobi"],
//   "MW": ["(GMT+02:00) Harare, Pretoria"],
//   "MY": ["(GMT+08:00) Kuala Lumpur, Singapore"],
//   "MV": ["(GMT+05:00) Islamabad, Karachi, Tashkent"],
//   "ML": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "MT": ["(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"],
//   "MH": ["(GMT+12:00) Fiji"],
//   "MR": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "MU": ["(GMT+04:00) Port Louis"],
//   "MX": [
//     "(GMT-08:00) Tijuana, Baja California",
//     "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
//     "(GMT-06:00) Guadalajara, Mexico City, Monterrey"
//   ],
//   "FM": ["(GMT+10:00) Brisbane"],
//   "MD": ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
//   "MC": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "MN": ["(GMT+08:00) Ulaanbaatar"],
//   "ME": ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
//   "MA": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "MZ": ["(GMT+02:00) Harare, Pretoria"],
//   "MM": ["(GMT+06:30) Yangon (Rangoon)"],
//   "NA": ["(GMT+01:00) West Central Africa"],
//   "NR": ["(GMT+12:00) Fiji"],
//   "NP": ["(GMT+05:45) Kathmandu"],
//   "NL": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "NZ": ["(GMT+12:00) Auckland, Wellington"],
//   "NI": ["(GMT-06:00) Central Time (US & Canada)"],
//   "NE": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "NG": ["(GMT+01:00) West Central Africa"],
//   "KP": ["(GMT+09:00) Pyongyang"],
//   "MK": ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
//   "NO": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "OM": ["(GMT+04:00) Abu Dhabi, Muscat"],
//   "PK": ["(GMT+05:00) Islamabad, Karachi, Tashkent"],
//   "PW": ["(GMT+09:00) Osaka, Sapporo, Tokyo"],
//   "PS": ["(GMT+02:00) Beirut"],
//   "PA": ["(GMT-05:00) Eastern Time (US & Canada)"],
//   "PG": ["(GMT+10:00) Brisbane"],
//   "PY": ["(GMT-03:00) Asuncion"],
//   "PE": ["(GMT-05:00) Bogota, Lima, Quito, Rio Branco"],
//   "PH": ["(GMT+08:00) Kuala Lumpur, Singapore"],
//   "PL": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "PT": ["(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London"],
//   "QA": ["(GMT+03:00) Kuwait, Riyadh, Baghdad"],
//   "RO": ["(GMT+02:00) Athens, Bucharest, Istanbul"],
//   "RU": ["(GMT+03:00) Moscow, St. Petersburg, Volgograd"],
//   "RW": ["(GMT+02:00) Harare, Pretoria"],
//   "KN": ["(GMT-04:00) Atlantic Time (Canada)"],
//   "LC": ["(GMT-04:00) Atlantic Time (Canada)"],
//   "VC": ["(GMT-04:00) Atlantic Time (Canada)"],
//   "WS": ["(GMT+13:00) Samoa"],
//   "SM": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "ST": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "SA": ["(GMT+03:00) Kuwait, Riyadh, Baghdad"],
//   "SN": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "RS": ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
//   "SC": ["(GMT+04:00) Port Louis"],
//   "SL": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "SG": ["(GMT+08:00) Kuala Lumpur, Singapore"],
//   "SK": ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
//   "SI": ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
//   "SB": ["(GMT+11:00) Solomon Is., New Caledonia"],
//   "SO": ["(GMT+03:00) Nairobi"],
//   "ZA": ["(GMT+02:00) Harare, Pretoria"],
//   "KR": ["(GMT+09:00) Seoul"],
//   "SS": ["(GMT+03:00) Nairobi"],
//   "ES": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "LK": ["(GMT+05:30) Sri Jayawardenapura"],
//   "SD": ["(GMT+03:00) Nairobi"],
//   "SR": ["(GMT-03:00) Paramaribo"],
//   "SE": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "CH": ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
//   "SY": ["(GMT+02:00) Damascus"],
//   "TJ": ["(GMT+05:00) Islamabad, Karachi, Tashkent"],
//   "TZ": ["(GMT+03:00) Nairobi"],
//   "TH": ["(GMT+07:00) Bangkok, Hanoi, Jakarta"],
//   "TL": ["(GMT+09:00) Osaka, Sapporo, Tokyo"],
//   "TG": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
//   "TO": ["(GMT+13:00) Nuku'alofa"],
//   "TT": ["(GMT-04:00) Atlantic Time (Canada)"],
//   "TN": ["(GMT+01:00) West Central Africa"],
//   "TR": ["(GMT+03:00) Istanbul"],
//   "TM": ["(GMT+05:00) Islamabad, Karachi, Tashkent"],
//   "TV": ["(GMT+12:00) Fiji"],
//   "UG": ["(GMT+03:00) Nairobi"],
//   "UA": ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
//   "AE": ["(GMT+04:00) Abu Dhabi, Muscat"],
//   "GB": ["(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London"],
//   "US": [
//     "(GMT-12:00) International Date Line West",
//     "(GMT-11:00) Midway Island, Samoa",
//     "(GMT-10:00) Hawaii",
//     "(GMT-09:00) Alaska",
//     "(GMT-08:00) Pacific Time (US & Canada)",
//     "(GMT-07:00) Mountain Time (US & Canada)",
//     "(GMT-06:00) Central Time (US & Canada)",
//     "(GMT-05:00) Eastern Time (US & Canada)",
//     "(GMT-04:00) Atlantic Time (Canada)",
//     "(GMT-03:30) Newfoundland"
//   ],
//   "UY": ["(GMT-03:00) Montevideo"],
//   "UZ": ["(GMT+05:00) Tashkent"],
//   "VU": ["(GMT+11:00) Solomon Is., New Caledonia"],
//   "VE": ["(GMT-04:00) Caracas"],
//   "VN": ["(GMT+07:00) Bangkok, Hanoi, Jakarta"],
//   "YE": ["(GMT+03:00) Nairobi"],
//   "ZM": ["(GMT+02:00) Harare, Pretoria"],
//   "ZW": ["(GMT+02:00) Harare, Pretoria"]
// }
export const CountryWiseTimeZones = {
  Afghanistan: ["(GMT+04:30) Kabul"],
  Albania: ["(GMT+01:00) Tirana"],
  Algeria: ["(GMT+01:00) Algiers"],
  Andorra: ["(GMT+01:00) Andorra"],
  Angola: ["(GMT+01:00) Luanda"],
  "Antigua and Barbuda": ["(GMT-04:00) Atlantic Time (Canada)"],
  Argentina: ["(GMT-03:00) Buenos Aires, Georgetown"],
  Armenia: ["(GMT+04:00) Yerevan"],
  Australia: [
    "(GMT+08:00) Perth",
    "(GMT+10:00) Canberra, Melbourne, Sydney",
    "(GMT+10:00) Brisbane",
    "(GMT+09:30) Adelaide",
    "(GMT+08:00) Darwin",
  ],
  Austria: ["(GMT+01:00) Vienna"],
  Azerbaijan: ["(GMT+04:00) Baku"],
  Bahamas: ["(GMT-05:00) Eastern Time (US & Canada)"],
  Bahrain: ["(GMT+03:00) Kuwait, Riyadh, Baghdad"],
  Bangladesh: ["(GMT+06:00) Dhaka"],
  Barbados: ["(GMT-04:00) Atlantic Time (Canada)"],
  Belarus: ["(GMT+03:00) Moscow, St. Petersburg, Volgograd"],
  Belgium: ["(GMT+01:00) Brussels"],
  Belize: ["(GMT-06:00) Central Time (US & Canada)"],
  Benin: ["(GMT+01:00) West Central Africa"],
  Bhutan: ["(GMT+06:00) Thimphu"],
  Bolivia: ["(GMT-04:00) Manaus", "(GMT-04:00) Santiago"],
  "Bosnia and Herzegovina": ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
  Botswana: ["(GMT+02:00) Harare, Pretoria"],
  Brazil: ["(GMT-03:00) Brasilia"],
  Brunei: ["(GMT+08:00) Kuala Lumpur, Singapore"],
  Bulgaria: ["(GMT+02:00) Sofia"],
  "Burkina Faso": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Burundi: ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
  "Cabo Verde": ["(GMT-01:00) Cape Verde Is."],
  Cambodia: ["(GMT+07:00) Bangkok, Hanoi, Jakarta"],
  Cameroon: ["(GMT+01:00) West Central Africa"],
  Canada: [
    "(GMT-07:00) Mountain Time (US & Canada)",
    "(GMT-06:00) Central Time (US & Canada)",
    "(GMT-05:00) Eastern Time (US & Canada)",
    "(GMT-04:00) Atlantic Time (Canada)",
    "(GMT-03:30) Newfoundland",
  ],
  "Central African Republic": ["(GMT+01:00) West Central Africa"],
  Chad: ["(GMT+01:00) West Central Africa"],
  Chile: ["(GMT-04:00) Santiago"],
  China: ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Colombia: ["(GMT-05:00) Bogota, Lima, Quito, Rio Branco"],
  Comoros: ["(GMT+03:00) Nairobi"],
  "Congo (Brazzaville)": ["(GMT+01:00) West Central Africa"],
  "Congo (Kinshasa)": ["(GMT+01:00) West Central Africa"],
  "Costa Rica": ["(GMT-06:00) Central Time (US & Canada)"],
  Croatia: ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
  Cuba: ["(GMT-05:00) Eastern Time (US & Canada)"],
  Cyprus: ["(GMT+02:00) Beirut"],
  Czechia: ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
  Denmark: ["(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"],
  Djibouti: ["(GMT+03:00) Nairobi"],
  Dominica: ["(GMT-04:00) Atlantic Time (Canada)"],
  "Dominican Republic": ["(GMT-04:00) Atlantic Time (Canada)"],
  Ecuador: ["(GMT-05:00) Bogota, Lima, Quito, Rio Branco"],
  Egypt: ["(GMT+02:00) Cairo"],
  "El Salvador": ["(GMT-06:00) Central Time (US & Canada)"],
  "Equatorial Guinea": ["(GMT+01:00) West Central Africa"],
  Eritrea: ["(GMT+03:00) Nairobi"],
  Estonia: ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
  Eswatini: ["(GMT+02:00) Harare, Pretoria"],
  Ethiopia: ["(GMT+03:00) Nairobi"],
  Fiji: ["(GMT+12:00) Auckland, Wellington"],
  Finland: ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
  France: ["(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"],
  Gabon: ["(GMT+01:00) West Central Africa"],
  Gambia: ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Georgia: ["(GMT+04:00) Tbilisi"],
  Germany: ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
  Ghana: ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Greece: ["(GMT+02:00) Athens, Bucharest, Istanbul"],
  Grenada: ["(GMT-04:00) Atlantic Time (Canada)"],
  Guatemala: ["(GMT-06:00) Central Time (US & Canada)"],
  Guinea: ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  "Guinea-Bissau": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Guyana: ["(GMT-04:00) Guyana"],
  Haiti: ["(GMT-05:00) Eastern Time (US & Canada)"],
  "Holy See": ["(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"],
  Honduras: ["(GMT-06:00) Central Time (US & Canada)"],
  Hungary: ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
  Iceland: ["(GMT+00:00) Dublin, Edinburgh, Lisbon, London"],
  India: ["(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi"],
  Indonesia: ["(GMT+07:00) Bangkok, Hanoi, Jakarta"],
  Iran: ["(GMT+03:30) Tehran"],
  Iraq: ["(GMT+03:00) Baghdad"],
  Ireland: ["(GMT+00:00) Dublin, Edinburgh, Lisbon, London"],
  Israel: ["(GMT+02:00) Jerusalem"],
  Italy: ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
  Jamaica: ["(GMT-05:00) Eastern Time (US & Canada)"],
  Japan: ["(GMT+09:00) Osaka, Sapporo, Tokyo"],
  Jordan: ["(GMT+02:00) Amman"],
  Kazakhstan: ["(GMT+06:00) Almaty, Novosibirsk"],
  Kenya: ["(GMT+03:00) Nairobi"],
  Kiribati: ["(GMT+12:00) Fiji"],
  Kuwait: ["(GMT+03:00) Kuwait, Riyadh, Baghdad"],
  Kyrgyzstan: ["(GMT+06:00) Almaty, Novosibirsk"],
  Laos: ["(GMT+07:00) Bangkok, Hanoi, Jakarta"],
  Latvia: ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
  Lebanon: ["(GMT+02:00) Beirut"],
  Lesotho: ["(GMT+02:00) Harare, Pretoria"],
  Liberia: ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Libya: ["(GMT+02:00) Cairo"],
  Liechtenstein: [
    "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  ],
  Lithuania: ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
  Luxembourg: ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
  Madagascar: ["(GMT+03:00) Nairobi"],
  Malawi: ["(GMT+02:00) Harare, Pretoria"],
  Malaysia: ["(GMT+08:00) Kuala Lumpur, Singapore"],
  Maldives: ["(GMT+05:00) Islamabad, Karachi, Tashkent"],
  Mali: ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Malta: ["(GMT+01:00) Brussels, Copenhagen, Madrid, Paris"],
  "Marshall Islands": ["(GMT+12:00) Fiji"],
  Mauritania: ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Mauritius: ["(GMT+04:00) Port Louis"],
  Mexico: [
    "(GMT-08:00) Tijuana, Baja California",
    "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
  ],
  Micronesia: ["(GMT+10:00) Brisbane"],
  Moldova: ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
  Monaco: ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
  Mongolia: ["(GMT+08:00) Ulaanbaatar"],
  Montenegro: ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
  Morocco: ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Mozambique: ["(GMT+02:00) Harare, Pretoria"],
  Myanmar: ["(GMT+06:30) Yangon (Rangoon)"],
  Namibia: ["(GMT+01:00) West Central Africa"],
  Nauru: ["(GMT+12:00) Fiji"],
  Nepal: ["(GMT+05:45) Kathmandu"],
  Netherlands: ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
  "New Zealand": ["(GMT+12:00) Auckland, Wellington"],
  Nicaragua: ["(GMT-06:00) Central Time (US & Canada)"],
  Niger: ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Nigeria: ["(GMT+01:00) West Central Africa"],
  "North Korea": ["(GMT+09:00) Pyongyang"],
  "North Macedonia": ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
  Norway: ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
  Oman: ["(GMT+04:00) Abu Dhabi, Muscat"],
  Pakistan: ["(GMT+05:00) Islamabad, Karachi, Tashkent"],
  Palau: ["(GMT+09:00) Osaka, Sapporo, Tokyo"],
  Palestine: ["(GMT+02:00) Beirut"],
  Panama: ["(GMT-05:00) Eastern Time (US & Canada)"],
  "Papua New Guinea": ["(GMT+10:00) Brisbane"],
  Paraguay: ["(GMT-03:00) Asuncion"],
  Peru: ["(GMT-05:00) Bogota, Lima, Quito, Rio Branco"],
  Philippines: ["(GMT+08:00) Kuala Lumpur, Singapore"],
  Poland: ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
  Portugal: [
    "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London",
  ],
  Qatar: ["(GMT+03:00) Kuwait, Riyadh, Baghdad"],
  Romania: ["(GMT+02:00) Athens, Bucharest, Istanbul"],
  Russia: ["(GMT+03:00) Moscow, St. Petersburg, Volgograd"],
  Rwanda: ["(GMT+02:00) Harare, Pretoria"],
  "Saint Kitts and Nevis": ["(GMT-04:00) Atlantic Time (Canada)"],
  "Saint Lucia": ["(GMT-04:00) Atlantic Time (Canada)"],
  "Saint Vincent and the Grenadines": ["(GMT-04:00) Atlantic Time (Canada)"],
  Samoa: ["(GMT+13:00) Samoa"],
  "San Marino": [
    "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  ],
  "Sao Tome and Principe": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  "Saudi Arabia": ["(GMT+03:00) Kuwait, Riyadh, Baghdad"],
  Senegal: ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Serbia: ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
  Seychelles: ["(GMT+04:00) Port Louis"],
  "Sierra Leone": ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Singapore: ["(GMT+08:00) Kuala Lumpur, Singapore"],
  Slovakia: ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
  Slovenia: ["(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb"],
  "Solomon Islands": ["(GMT+11:00) Solomon Is., New Caledonia"],
  Somalia: ["(GMT+03:00) Nairobi"],
  "South Africa": ["(GMT+02:00) Harare, Pretoria"],
  "South Korea": ["(GMT+09:00) Seoul"],
  "South Sudan": ["(GMT+03:00) Nairobi"],
  Spain: ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
  "Sri Lanka": ["(GMT+05:30) Sri Jayawardenapura"],
  Sudan: ["(GMT+03:00) Nairobi"],
  Suriname: ["(GMT-03:00) Paramaribo"],
  Sweden: ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
  Switzerland: ["(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna"],
  Syria: ["(GMT+02:00) Damascus"],
  Taiwan: ["(GMT+08:00) Kuala Lumpur, Singapore"],
  Tajikistan: ["(GMT+05:00) Islamabad, Karachi, Tashkent"],
  Tanzania: ["(GMT+03:00) Nairobi"],
  Thailand: ["(GMT+07:00) Bangkok, Hanoi, Jakarta"],
  "Timor-Leste": ["(GMT+09:00) Osaka, Sapporo, Tokyo"],
  Togo: ["(GMT+00:00) Casablanca, Monrovia, Reykjavik"],
  Tonga: ["(GMT+13:00) Nuku'alofa"],
  "Trinidad and Tobago": ["(GMT-04:00) Atlantic Time (Canada)"],
  Tunisia: ["(GMT+01:00) West Central Africa"],
  Turkey: ["(GMT+03:00) Istanbul"],
  Turkmenistan: ["(GMT+05:00) Islamabad, Karachi, Tashkent"],
  Tuvalu: ["(GMT+12:00) Fiji"],
  Uganda: ["(GMT+03:00) Nairobi"],
  Ukraine: ["(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius"],
  "United Arab Emirates": ["(GMT+04:00) Abu Dhabi, Muscat"],
  "United Kingdom": ["(GMT+00:00) Dublin, Edinburgh, Lisbon, London"],
  "United States of America": [
    "(GMT-08:00) Pacific Time (US & Canada)",
    "(GMT-07:00) Mountain Time (US & Canada)",
    "(GMT-06:00) Central Time (US & Canada)",
    "(GMT-05:00) Eastern Time (US & Canada)",
    "(GMT-04:00) Atlantic Time (Canada)",
    "(GMT-03:30) Newfoundland",
  ],
  Uruguay: ["(GMT-03:00) Montevideo"],
  Uzbekistan: ["(GMT+05:00) Tashkent"],
  Vanuatu: ["(GMT+11:00) Magadan, Solomon Is., New Caledonia"],
  Venezuela: ["(GMT-04:00) Caracas"],
  Vietnam: ["(GMT+07:00) Bangkok, Hanoi, Jakarta"],
  Yemen: ["(GMT+03:00) Kuwait, Riyadh, Baghdad"],
  Zambia: ["(GMT+02:00) Harare, Pretoria"],
  Zimbabwe: ["(GMT+02:00) Harare, Pretoria"],
};

export const IndustryOptions = [
  { label: "Fashion & Apparel", value: "Fashion & Apparel" },
  { label: "Beauty & Cosmetics", value: "Beauty & Cosmetics" },
  { label: "Food & Beverage", value: "Food & Beverage" },
  { label: "Health & Wellness", value: "Health & Wellness" },
  { label: "Crafts & Jewellery", value: "Crafts & Jewellery" },
  { label: "Home & Garden", value: "Home & Garden" },
  { label: "Art & Photography", value: "Art & Photography" },
  { label: "Consumer Electronics", value: "Consumer Electronics" },
  { label: "Sporting Goods", value: "Sporting Goods" },
  { label: "Toys", value: "Toys" },
  { label: "Pets", value: "Pets" },
  { label: "Industry", value: "Others" },
];

export const DepartmentOptions = [
  { label: "Technology", value: "Technology" },
  { label: "Product", value: "Product" },
  { label: "Support", value: "Support" },
  { label: "Analyst", value: "Analyst" },
  { label: "Department", value: "Others" },
];
