import { Badge, Space, Table } from "antd";
import { SideDrawerWrapper } from "components/Global";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApiLogs } from "redux/action/apiLogsAction";
import { AppDispatch } from "redux/store";
import { formatUrl } from "utils/Common";
import { pageSizeOptionsPaging } from "utils/constant";
import { SortSvgBottom, SortSvgTop } from "utils/svgs";
import { formatDate } from "utils/utils";
import APIKeyDetailedRequestDrawer from "../APIKeyDetailedRequest";
import LogsSearchAndFilter from "../SearchAndFilter";
import "./index.scss";
import { DynamicLogsTableProps } from "./type";

const SortingIcon = (data: any) => {
    return data.data.sortOrder === "ascend" ? <SortSvgTop /> : <SortSvgBottom />;
};

const DynamicLogsTable: FC<DynamicLogsTableProps> = (props) => {
    const { Column } = Table;
    const { logsDataSource, isLoading, totalRecords, tabIndex, setLoadingHandler } = props;
    console.log("🚀 ~ tabIndex:", tabIndex)
    const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
    const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
    const [tableData, setTableData] = useState(logsDataSource);
    const [drawerInfo, setDrawerInfo] = useState({ drawerTitle: "", });
    const [drawerData, setDrawerData] = useState({})
    const [searchValue, setSearchValue] = useState("");
    const [filterValue, setFilterValue] = useState("");
    const [pageSize, setPageSize] = useState(20);
    const [currentPageNo, setCurrentPageNo] = useState(1);

    const [sortDirection, SetsortDirection] = useState("desc");

    const dispatch = useDispatch<AppDispatch>()

    const apiLogData = useSelector((data: any) => data?.apilogs?.data?.data?.length)

    const openSideBarDrawer = (data: any) => {
        setDrawerData(data)
        setDrawerInfo({ drawerTitle: "Request Details" });
        setDrawerAnimation(true);
        setSideDrawerOpen(true);
    };

    const removeDrawerFromDom = () => {
        setSideDrawerOpen(false);
    };

    const closeDrawerByAnimation = () => {
        setDrawerAnimation(false);
    };

    // Handle search operation
    const performSearchHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearchValue(value);
        setCurrentPageNo(1);
    };

    const performFilterHandler = (value: string) => {
        setFilterValue(value);
    }

    //  Modify pageSize
    const modifyPageSize = (current: number, size: number) => {
        if (!size) {
            setPageSize(10);
        } else {
            setPageSize(size);
        }
        setCurrentPageNo(1);
    };


    // Handle pagination for the table
    const paginationChangeHandler = (pageNo: number, pageSize: number) => {
        setCurrentPageNo(pageNo);
        setPageSize(pageSize);
    };

    const tableChangeHandler = (pageInfo: any, d: any, columnInfo: any) => {
        const orderVal = columnInfo.order === "descend" ? "desc" : "asc"
        SetsortDirection(orderVal);
        setCurrentPageNo(pageInfo.current);
    }


    const fetchApiLogs = () => {
        setLoadingHandler(true)
        const query: any = {
            page: currentPageNo,
            limit: pageSize,
            search: searchValue,
            filter: filterValue,
            type: sortDirection,
        };

        if (filterValue === " ") {
            delete query.filter;
        }
        dispatch(getApiLogs(query)).then(() => {
            setLoadingHandler(false)
        });
    };

    useEffect(() => {
        fetchApiLogs();
    }, [pageSize, currentPageNo, filterValue, searchValue, sortDirection]);


    useEffect(() => {
        if (Array.isArray(logsDataSource)) {
            const data = logsDataSource?.map((item: any) => ({
                ...item,
                createdAt: formatDate(item?.createdAt),
            }));
            setTableData(data);
        }
    }, [logsDataSource]);

    // Format user data
    useEffect(() => {
        if (apiLogData === 0 && currentPageNo > 1) {
            const query: any = {
                page: currentPageNo - 1,
                limit: pageSize,
                search: searchValue,
                filter: filterValue,
                type: sortDirection,

            };

            if (filterValue === " ") {
                delete query.filter;
            }

            if (filterValue === " ") {
                delete query.filter;
            }
            dispatch(getApiLogs(query)).then(() => {
                setLoadingHandler(false)
            });
            setCurrentPageNo(currentPageNo - 1);
        }

        if (Array.isArray(logsDataSource)) {
            const data = logsDataSource?.map((item: any) => ({
                ...item,
                createdAt: formatDate(item?.createdAt),
            }));
            setTableData(data);
        }

    }, [apiLogData]);

    return (
        <div className={"dynamic-table"}>
            <LogsSearchAndFilter
                performSearchHandler={performSearchHandler}
                searchValue={searchValue}
                performFilterHandler={performFilterHandler}
            />
            <Table
            
                dataSource={tableData}
                scroll={{ y: "calc(70vh - 133px)" }}
                pagination={{
                    total: totalRecords,
                    current: currentPageNo,
                    onChange: paginationChangeHandler,
                    pageSize: pageSize,
                    pageSizeOptions: pageSizeOptionsPaging,
                    showSizeChanger: true,
                    onShowSizeChange: modifyPageSize,
                }}
                loading={isLoading}
                className="table-global"
                onChange={tableChangeHandler}
                style={{cursor: "pointer"}}
                
                onRow={(record: any) => {
                    return {
                        onClick: (() => { openSideBarDrawer(record) }),
                    };
                }}
            >
                <Column
                    title="Methods"
                    dataIndex="method"
                    key="method"
                    width={"10%"}
                    className="bg-white"
                />
                <Column
                    title="URL"
                    dataIndex="apiUrl"
                    key="apiUrl"
                    className="bg-white "
                    width={"13%"}
                    render={(value: string) => {
                        return (
                            <>
                                <Space>
                                    {formatUrl(value)}
                                </Space>
                            </>)

                    }}
                />
                <Column
                    title="Status"
                    dataIndex="status"
                    key="status"
                    className="bg-white"
                    width={"11%"}
                    render={(value: string) => {
                        return (
                            <>
                                <Space>
                                    <Badge
                                        count={0}
                                        style={
                                            value == "200"
                                                ? { backgroundColor: "#52c41a", color: "#52c41a" }
                                                : { backgroundColor: "#faad14", color: "#faad14" }
                                        }
                                        showZero={true}
                                    />
                                    <span className={"dynamic-table__status-text"}>{value}</span>
                                </Space>
                            </>
                        );
                    }}
                />
                <Column
                    title="Integration"
                    dataIndex="Integration"
                    key="Integration"
                    className="bg-white"
                    width={"13%"}
                    render={(value: string) => {
                        return (
                            <>
                                <Space style={value === null ? { paddingLeft: "40px" } : {}}>
                                    {value === null ? "-" : value}
                                </Space>
                            </>)

                    }}

                />
                <Column
                    title="Timestamp"
                    dataIndex="createdAt"
                    key="createdAt"
                    className="bg-white"
                    width={"14%"}
                    showSorterTooltip={{ title: "" }}
                    sorter={() => {
                        return null as any;
                    }}
                    sortDirections={["ascend", "descend", "ascend"]}
                    sortIcon={(data) => <SortingIcon data={data} />}
                />
            </Table>

            {isSideDrawerOpen && (
                <SideDrawerWrapper
                    isOpen={drawerAnimation}
                    removeDrawerFromDom={removeDrawerFromDom}
                    closeDrawerByAnimation={closeDrawerByAnimation}
                    headerTitle={drawerInfo.drawerTitle}
                    position="right"
                    width="half"
                >
                    <APIKeyDetailedRequestDrawer drawerData={drawerData} />
                </SideDrawerWrapper>
            )}
        </div>
    );
};

export default DynamicLogsTable;
