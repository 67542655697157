import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import styles from "./index.module.scss";
import { getApi, postApi } from "redux/apis";
import { v4 as uuidv4 } from "uuid";
import { Button, Col, Image, Row, Select } from "antd";

const ERPCallbackComponent = () => {
  // Create a Redux dispatch function

  const [isLoading, setIsLoading] = useState(false);
  const [isDropDownLoading, setisDropDownLoading] = useState(false);
  const [isConnectButtonloading, setisConnectButtonloading] = useState(false);
  // Create a Redux dispatch function

  const [queryParams] = useSearchParams();
  const location = useLocation();
  const [isBusinessCentralEnvArray, setisBusinessCentralEnvArray] = useState(
    []
  );
  const [
    isBusinessCentralCompanyDropdownArray,
    setisBusinessCentralCompanyDropdownArray,
  ] = useState([]);

  const [businessCentralTokenDetails, setbusinessCentralTokenDetails]=useState<any>();

  const [businessCentralEnvValue, setBusinessCentralEnvValue] = useState<
    string | null
  >(null);
  const [businessCentralCompanyValue, setBusinessCentralCompanyValue] =
    useState<string | null>(null);
  const [
    isBusinessCentralCompanySelectedName,
    setisBusinessCentralCompanySelectedName,
  ] = useState<string | null>(null);

  const handleChangeBusinessEnvDropdown = async (value: string) => {
    // Set loading to true when fetching data starts
    setisDropDownLoading(true);
    setBusinessCentralEnvValue(value);
    setBusinessCentralCompanyValue(null);
    const res = await getApi(
      `/dynamic/comapny-by-env?env_name=${value}&accessToken=${businessCentralTokenDetails?.access_token}`
    );
    const companyArray = res?.data?.data?.value;
    const requiredCompanies: any = companyArray.map((element: any) => {
      return {
        label: element?.name,
        value: element?.id,
      };
    });

    // Set loading to false after data is fetched
    setisBusinessCentralCompanyDropdownArray(requiredCompanies);
    setisDropDownLoading(false);
  };


  const handleChangeBusinessCompany = (value: string) => {
    const selectedCompany: any = isBusinessCentralCompanyDropdownArray.find(
      (company: any) => company?.value === value
    );
    setisBusinessCentralCompanySelectedName(selectedCompany?.label);
    setBusinessCentralCompanyValue(value);
  };

  const handleConnectButton = async () => {
    setisConnectButtonloading(true);
    await postApi(
      `/dynamic/company-by-id?accessToken=${businessCentralTokenDetails?.access_token}&company_id=${businessCentralCompanyValue}&env_name=${businessCentralEnvValue}`,
      {
        selctedCompanyName: isBusinessCentralCompanySelectedName,
        selectedEnvironment: businessCentralEnvValue,
        tokenDetails:businessCentralTokenDetails
      }
    );
    setisConnectButtonloading(false);
    const callbackData = {
      uuid: uuidv4(),
      data: true,
    };

    localStorage.setItem("callBackUpdatedData", JSON.stringify(callbackData));
    window.close();
  };

  const saveAndCloseWindow = (errorMessage?: string) => {
    const callbackData = {
      uuid: uuidv4(),
      data: true,
    };

    localStorage.setItem(
      "callBackUpdatedData",
      JSON.stringify({
        ...callbackData,
        error: Boolean(errorMessage),
        message: errorMessage,
      })
    );
    window.close();
  };

  const getTokenInfo = async () => {
    setIsLoading(true);

    if (location.pathname.startsWith("/dynamic/callback")) {
      const code = queryParams.get("code");

      postApi(`/dynamic/callback?code=${code}`, {})
        .then((res: any) => {
          if (res?.status === 200) {
            if (res?.data?.data?.isCRM) {
              saveAndCloseWindow();
            }
            setbusinessCentralTokenDetails(res?.data?.data?.tokenDetails);
            const EnvironmentsArray =
              res?.data?.data?.environments?.value || [];
            const labelsTypeArray: string[] = [];

            EnvironmentsArray.forEach((e: any) => {
              if (!labelsTypeArray.includes(e.type)) {
                labelsTypeArray.push(e.type);
              }
            });

            if (labelsTypeArray.length) {
              const options: any = [];

              labelsTypeArray.forEach((type) => {
                const labelOptions = EnvironmentsArray.filter(
                  (x: any) => x.type === type
                );
                const mappedOptions = labelOptions.map((element: any) => ({
                  label: element?.name,
                  value: element?.name,
                }));

                options.push({
                  label: type,
                  options: mappedOptions,
                });
              });

              setisBusinessCentralEnvArray(options);
            }
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error, "error");
          saveAndCloseWindow(error?.response?.data?.data ?? error.message);
        });
    }
  };

  useEffect(() => {
    getTokenInfo();
  }, []);

  return (
    <div className={styles["main-container"]}>
      {isLoading ? (
        <img
          src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading.gif`}
          height={60}
          crossOrigin={
            process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
          }
        />
      ) : null}

      {location.pathname.startsWith("/dynamic/callback") && !isLoading ? (
        <div style={{ width: "300px" }}>
          <Row>
            <Col
              span={24}
              style={{
                marginBottom: "10px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Image
                src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/ERP/microsoft-business-central.png`}
                height={55}
                crossOrigin={
                  process.env.REACT_APP_ENV === "local"
                    ? undefined
                    : "anonymous"
                }
                width={215}
                preview={false}
              />
            </Col>
            <Col span={24}>
              <Select
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(value: any) =>
                  handleChangeBusinessEnvDropdown(value)
                }
                options={isBusinessCentralEnvArray}
                value={businessCentralEnvValue}
                placeholder="Select Enviorment"
              />
            </Col>
            <Col span={24}>
              <Select
                style={{ width: "100%" }}
                value={businessCentralCompanyValue}
                onChange={(value: any) => handleChangeBusinessCompany(value)}
                options={isBusinessCentralCompanyDropdownArray}
                loading={isDropDownLoading}
                disabled={isDropDownLoading}
                placeholder="Select Company"
              />
            </Col>
            <Col
              span={24}
              style={{
                marginTop: "10px",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Button
                type="primary"
                loading={isConnectButtonloading}
                onClick={handleConnectButton}
                disabled={
                  !businessCentralCompanyValue || !businessCentralEnvValue
                }
                style={{
                  width: "100%",
                }}
              >
                Connect
              </Button>
            </Col>
          </Row>
        </div>
      ) : null}
    </div>
  );
};

export default ERPCallbackComponent;
