import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  //  Select,
  Tag,
} from "antd";
import { FC, useState } from "react";
import { CloseSvg, OpenNewTab } from "utils/svgs";
import styles from "./index.module.scss";
import { IntegrationModalProps } from "./types";
import { getApi, postApi } from "redux/apis";
import { toastText } from "utils/utils";
import "./index.scss";
import { InfoCircleOutlined } from "@ant-design/icons";
import { IntegrationUrl } from "constants/Data";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { qbdUserAction } from "redux/action/qbdUserAction";
import { netSuiteAction } from "redux/action/netSuiteAction";
import { AppDispatch } from "redux/store";

// IntegrationModal popup
const IntegrationModal: FC<IntegrationModalProps> = (props) => {
  // Inits
  const {
    handleCancel,
    handleOk,
    isModalOpen,
    titleKey,
    formData,
    SetButtonLabel,
    ButtonLabel,
    softwareType,
    selectCompany,
    setSelectCompany,
    uniqueUsername,
  } = props;

  const [form] = Form.useForm(); // Ant Design Form instance
  const [formValues, setFormValues] = useState({});
  const [selectOptions, setSelectOptions] = useState(null);
  const [isConnecting, setisConnecting] = useState(false);
  const [magentoStoreList, setmagentoStoreList] = useState<any[]>([])

  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleFormReset = () => {
    form.resetFields(); // Reset form fields
    setFormValues({}); // Reset controlled form values
    if (titleKey === "Netsuite" || titleKey == "Magento") {
      setSelectCompany(false);
    }
  };

  // const profileData = useSelector((state: any) => state?.userProfile?.data);

  const subsidaryData = useSelector(
    (state: any) => state?.netSuite?.data?.items
  );

  const netSuiteOptionsHandler = (e: any) => {
    setSelectOptions(JSON.parse(e));
  };

  const successConnectionHandler = () => {
    handleOk();
    handleFormReset();
    navigate("/settings/integrations/connectedApps");
  };

  const options = subsidaryData?.map((data: any) => {
    return { label: data.name, value: JSON.stringify(data.name) };
  });

  const magentoOptions = magentoStoreList?.map((data: any) => {
    return { label: data.name, value: JSON.stringify(data) };
  });
  const handleFormSubmit = async (values: any) => {
    // SWITCH CASE FOR DIFFRENT DIFFRENT INTEGRATIONS
    switch (titleKey) {
      case "Clear Books":
        {
          try {
            setisConnecting(true);
            const credentials = {
              APIKey: values.secret,
              componyName: values.companyName,
              isSave: ButtonLabel,
            };
            // Check credentials
            const isValidCredentials = await postApi(
              "/clearBook/credentials",
              credentials
            );
            if (isValidCredentials?.data?.data) {
              !ButtonLabel && SetButtonLabel(true);
              toastText(
                isValidCredentials?.data?.message || "Connection Successful",
                "success"
              );
              if (ButtonLabel) {
                successConnectionHandler();
              }
            } else {
              if (isValidCredentials?.data?.statusCode == 201) {
                successConnectionHandler();
              }
              toastText(
                isValidCredentials?.data?.message || "Invalid Credentials",
                "error"
              );
            }
          } catch (error: any) {
            toastText(error?.response?.data?.error ?? error?.message, "error");
          } finally {
            setisConnecting(false);
          }
        }
        break;

      default:
        {
          if (values.senderId) {
            const data = { toChannelConnection: { ...values } };

            if (ButtonLabel === false) {
              setisConnecting(true);

              try {
                const res: any = await postApi("/sage/getSession", values)
                if (res?.data?.data?.sessionId) {
                  toastText("Valid Credentials", "success");
                  SetButtonLabel(true);
                  //
                }
                setisConnecting(false);

              } catch (error: any) {
                setisConnecting(false);
                toastText(error?.response?.data?.error?.message, "error");
              }
            }

            if (ButtonLabel === true) {
              setisConnecting(true);
              try {
                postApi(`/sage/sessionId`, {
                  data: data,
                  companyName: values.companyName,
                  companyId: values.companyId,
                })
                  .then(async (res: any) => {
                    if (res?.data?.statusCode) {
                      toastText("Connection Successful", "success");
                      await handleOk(); // Handle submission logic
                      navigate("/settings/integrations/connectedApps");
                    } else {
                      await handleCancel();
                      toastText("Failed to Connect Sage", "error");
                      SetButtonLabel(false);
                    }
                    handleFormReset();
                    setisConnecting(false);
                  })
                  .catch((error) => {
                    if (error?.response?.data?.error?.status === 409) {
                      toastText(error?.response?.data?.error?.message, "error");
                    } else {
                      toastText("Fail to Connect", "error");
                    }
                    handleCancel();

                    SetButtonLabel(false);
                    handleFormReset();
                    setisConnecting(false);
                  });
              } catch (error) {
                await handleCancel();
                toastText("Fail to Connect", "error");
              }
            }
          }

          if (values.baseUrl && values.key && values.secret) {
            const requestBody = {
              // Assuming you want to send specific values from the form
              storeUrl: values.baseUrl,
              consumerKey: values.key,
              consumerSecret: values.secret,
            };
            if (ButtonLabel === false) {
              setisConnecting(true);
              try {
                const res = await postApi("/wooComm/checkcredentials", requestBody)

                if (res.status === 200) {
                  toastText("Valid Credentials", "success");
                  SetButtonLabel(true);
                }
                setisConnecting(false);


              } catch (error: any) {
                setisConnecting(false);
                toastText(error?.response?.data?.error?.message, "error");
              }
            }
            if (ButtonLabel === true) {
              setisConnecting(true);
              try {
                postApi(`/wooComm/savecredentials`, {
                  data: requestBody,
                  companyName: values.companyName,
                  companyId: values.companyId,
                })
                  .then(async (res: any) => {
                    if (res.status === 200) {
                      toastText("Connection Successful", "success");
                      await handleOk(); // Handle submission logic
                      navigate("/settings/integrations/connectedApps");
                    } else {
                      await handleCancel();
                      toastText("Failed to Connect Sage", "error");
                      SetButtonLabel(false);
                    }
                    handleFormReset();
                    setisConnecting(false);
                  })
                  .catch((error) => {
                    if (error?.response?.data?.error?.status === 409) {
                      toastText(error?.response?.data?.error?.message, "error");
                    } else {
                      toastText("Fail to Connect", "error");
                    }
                    handleCancel();

                    SetButtonLabel(false);
                    handleFormReset();
                    setisConnecting(false);
                  });
              } catch (error) {
                await handleCancel();
                toastText("Fail to Connect", "error");
              }
            }
          }

          if (values.store) {
            if (ButtonLabel === false) {
              setisConnecting(true);
              const SHOP = values?.store;
              const res = await getApi(`/shopify/getshopifyurl?SHOP=${SHOP}`);
              setisConnecting(false);
              if (res?.data?.data) {
                // Calculate the position for the new window
                const width = 800;
                const height = 600;
                const left = window.innerWidth / 2 - width / 2;
                const top = window.innerHeight / 2 - height / 2;
                const windowName = "shopifyWindow";

                window.open(
                  res?.data?.data,
                  windowName,
                  `width=${width},height=${height},left=${left},top=${top}`
                );
              }
            }
          }

          if (values.magentostore && values.username && values.password) {

            if (ButtonLabel === false) {
              try {
                setisConnecting(true);
                const shopURL = "https://" + values?.magentostore;
                const res = await postApi(`/magento/checkcredentails?storeUrl=${shopURL}`, { username: values.username, password: values.password });
                setisConnecting(false);
                if (res?.data?.statusCode === 200) {
                  toastText("Valid Credentials", "success");
                  SetButtonLabel(true);
                  setmagentoStoreList(res?.data?.data)
                  setSelectCompany(true);
                  setSelectOptions(null)

                }
              }
              catch (error: any) {
                setisConnecting(false);
                SetButtonLabel(false);
                toastText(error?.response?.data?.error?.message, "error");
              }
            }
            if (ButtonLabel === true) {
              if (selectOptions === null) {
                return toastText("Please select Company Name", "error");
              }
              setisConnecting(true);

              try {
                setisConnecting(true);
                const shopURL = "https://" + values?.magentostore;
                const res = await postApi(`/magento/savecredentails?storeUrl=${shopURL}`, { username: values.username, password: values.password, companyName: selectOptions });
                setisConnecting(false);
                if (res?.data?.statusCode === 200) {
                  toastText("Connection Successful", "success");
                  handleOk(); // Handle submission logic
                  navigate("/settings/integrations/connectedApps");

                }

                handleCancel();

                SetButtonLabel(false);
                handleFormReset();
                setisConnecting(false);
              }
              catch (error: any) {

                if (error) {
                  toastText(error?.response?.data?.error?.message, "error");
                }
                else {
                  toastText("Fail to Connect", "error");
                }
                handleCancel();

                SetButtonLabel(false);
                handleFormReset();
                setisConnecting(false);
              }
            }
          }
          if (
            values.apiLoginId &&
            values.transactionKey &&
            values.companyName
          ) {
            if (ButtonLabel === false) {
              setisConnecting(true);

              try {
                postApi("/authorize-net/checkCredential", values)
                  .then(async (res) => {
                    if (res.status === 200) {
                      toastText("Valid Credentials", "success");
                      SetButtonLabel(true);
                    } 
                    setisConnecting(false);
                  })
                  .catch(async (error) => {
                    SetButtonLabel(false);
                    setisConnecting(false);
                    toastText(error?.response?.data?.error?.message, "error");
                  });
              } catch (error) {
                await handleCancel();
                toastText("Fail to Connect", "error");
              }
            }
            if (ButtonLabel === true) {
              setisConnecting(true);
              try {
                postApi(`/authorize-net/saveCredential`, {
                  data: values,
                  companyName: values.companyName,
                  // companyId: values.companyId,
                  // tokenDetails: JSON.stringify(values),
                })
                  .then(async (res: any) => {
                    if (res.status === 200) {
                      toastText("Connection Successful", "success");
                      await handleOk(); // Handle submission logic
                      navigate("/settings/integrations/connectedApps");
                    }
                    handleFormReset();
                    setisConnecting(false);
                  })
                  .catch((error) => {
                    if (error?.response?.data?.error?.status === 409) {
                      toastText(error?.response?.data?.error?.message, "error");
                    } else {
                      toastText("Fail to Connect", "error");
                    }
                    handleCancel();

                    SetButtonLabel(false);
                    handleFormReset();
                    setisConnecting(false);
                  });
              } catch (error) {
                await handleCancel();
                toastText("Fail to Connect", "error");
              }
            }
          }
          if (
            values.consumerKey &&
            values.consumerSecret &&
            values.accessToken &&
            values.tokenSecret &&
            values.accountId
          ) {
            const requestBody = {
              consumerKey: values.consumerKey,
              consumerSecret: values.consumerSecret,
              accessToken: values.accessToken,
              tokenSecret: values.tokenSecret,
              accountId: values.accountId,
            };

            try {
              if (ButtonLabel === false) {
                try {
                  setisConnecting(true);
                  dispatch(netSuiteAction(requestBody))
                    .then((res: any) => {
                      if (res.meta.requestStatus === "fulfilled") {
                        toastText("Valid Credentials", "success");
                        setSelectCompany(true);
                        SetButtonLabel(true);
                      } else {
                        toastText("Invalid Credentials", "error");
                        SetButtonLabel(false);
                        setSelectCompany(false);
                      }
                      setisConnecting(false);
                    })
                    .catch((error: any) => {
                      console.log("error: ", error);
                    });
                } catch (error) {
                  await handleCancel();
                  toastText("Fail to Connect", "error");
                }
              }
              if (ButtonLabel === true) {
                if (selectOptions === null) {
                  return toastText("Please select Company Name", "error");
                }
                try {
                  setisConnecting(true);
                  postApi(`/netsuite/save`, {
                    data: requestBody,
                    organizationId: localStorage.getItem("companyId"),
                    channelName: titleKey,
                    channelType: softwareType,
                    companyName: selectOptions,
                    companyId: values.companyId,
                    tokenDetails: JSON.stringify(requestBody),
                  })
                    .then(async (res: any) => {
                      if (res.status === 200) {
                        toastText("Connection Successful", "success");
                        await handleOk(); // Handle submission logic
                        navigate("/settings/integrations/connectedApps");
                      }
                      handleCancel();
                      SetButtonLabel(false);
                      handleFormReset();
                      setisConnecting(false);
                    })
                    .catch((error) => {
                      if (error?.response?.status === 409) {
                        toastText(error?.response?.data?.message, "error");
                      } else {
                        toastText("Invalid Credentials", "error");
                      }
                      SetButtonLabel(false);
                      handleFormReset();
                      handleCancel();
                      setisConnecting(false);
                    });
                } catch (error) {
                  await handleCancel();
                  toastText("Fail to save", "error");
                }
              }
            } catch (error) {
              await handleCancel();
              toastText("Fail to Connect", "error");
            }
          }

          if (!values.username && values.password) {
            values.username = uniqueUsername;
            if (ButtonLabel === false) {
              setisConnecting(true);
              try {
                dispatch(qbdUserAction(values) as any).then(
                  async (res: any) => {
                    if (res?.payload?.id) {
                      toastText("Saved Credentials Successfully", "success");
                      setisConnecting(false);
                      await handleOk();
                    } else {
                      toastText("Invalid Credentials", "error");
                    }
                  }
                );
              } catch (error) {
                await handleCancel();
                toastText("Fail to Connect", "error");
              }
            }
          }
        }
        break;
    }
  };

  // To handle the title
  const newTitleKey = titleKey?.replace(/\s/g, "");
  const filterData = IntegrationUrl.filter((item) => newTitleKey in item);

  //  getting the url from the constants
  let urlToUse: any;
  if (filterData.length > 0) {
    urlToUse = filterData[0][newTitleKey as keyof (typeof filterData)[0]]; // Access the URL
  }

  //   JSX
  return (
    <Modal
      className={`${styles["integration-model_width"]} integration-model_width `}
      title={
        <h3 className={styles["integration-model_title"]}>
          {titleKey === "Shopify"
            ? "Please provide store name"
            : `Provide credential to connect with ${titleKey}`}
        </h3>
      }
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
        SetButtonLabel(false);
        handleFormReset(); // Reset form fields and values when modal is closed
      }}
      centered={true}
      width={500}
      closeIcon={<CloseSvg />}
      footer={null}
    >
      <hr className={styles["hr_width"]} />

      {titleKey === "Sage Intacct" && (
        <>
          <Tag color="processing" className={styles["tag-attribute"]}>
            <div className="integration--tag-space">
              <span>
                <InfoCircleOutlined />
                &nbsp;To obtain the Sender credentials you can follow a link{" "}
              </span>
              <Button
                className={styles["tag-attribute--button"]}
                href={urlToUse}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles["tag-attribute--view_docs"]}>
                  View docs{" "}
                  <span className="svg-space">
                    {" "}
                    <OpenNewTab />
                  </span>{" "}
                </div>
              </Button>{" "}
            </div>
          </Tag>
          <Tag
            color="processing"
            className={`${styles["tag-attribute"]} second_tag`}
          >
            <div className="integration--tag-space">
              <span>
                <InfoCircleOutlined />
                &nbsp;To obtain the User credentials you can follow a link{" "}
              </span>
              <Button
                className={styles["tag-attribute--button"]}
                href="https://developer.intacct.com/support/faq/#why-am-i-getting-an-error-about-an-invalid-web-services-authorization"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles["tag-attribute--view_docs"]}>
                  View docs
                  <span className="svg-space">
                    <OpenNewTab />
                  </span>
                </div>
              </Button>
            </div>
          </Tag>
        </>
      )}

      {titleKey !== "Shopify" && titleKey !== "Sage Intacct" && (
        <Tag
          color="processing"
          className={`${styles["tag-attribute"]} second_tag`}
        >
          <div className="integration--tag-space">
            <span>
              <InfoCircleOutlined />
              &nbsp;To obtain these credentials you can follow a link{" "}
            </span>
            <Button
              className={styles["tag-attribute--button"]}
              href={urlToUse}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className={styles["tag-attribute--view_docs"]}>
                View docs
                <span className="svg-space">
                  <OpenNewTab />
                </span>
              </div>
            </Button>
          </div>
        </Tag>
      )}

      <div className={styles["form_margin"]}>
        <Form
          form={form}
          initialValues={formValues}
          onFinish={handleFormSubmit}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {formData?.map((fieldData: any, index: any) => (
            <div className={styles["input-icon"]} key={index}>
              <div className={styles["input-icon__title"]}>
                {fieldData.svg && (
                  <div className={styles["input-icon__title--svg"]}>
                    {fieldData.svg}
                  </div>
                )}
                <label className={styles["input-icon__title--label"]}>
                  {fieldData.title}{" "}
                  {fieldData?.required && (
                    <span className="required-color">*</span>
                  )}
                </label>
              </div>
              <div className={styles["input-icon__form"]}>
                <Form.Item name={fieldData.name} rules={fieldData.rules}>
                  {fieldData.type === "text" || fieldData.type === "number" ? (
                    <Input
                      placeholder={fieldData.placeHolder}
                      size="large"
                      className={styles["input-icon__form--input"]}
                      type={fieldData.type}
                      disabled={ButtonLabel}
                    />
                  ) : fieldData.type === "password" ? (
                    <Input.Password
                      placeholder={fieldData.placeHolder}
                      size="large"
                      className={styles["input-icon__form--input"]}
                      autoComplete="new-password"
                      disabled={ButtonLabel}
                    />
                  ) : fieldData.type === "shopifyStoretextField" ? (
                    <Input
                      placeholder={fieldData.placeHolder}
                      size="large"
                      className={styles["input-icon__form--input"]}
                      type={fieldData.type}
                      disabled={ButtonLabel}
                      addonBefore="https://"
                      addonAfter=".myshopify.com"
                    />
                  ) : fieldData.type === "qbd" ? (
                    <Input
                      placeholder={fieldData.placeHolder}
                      size="large"
                      className={styles["input-icon__form--input"]}
                      type={fieldData.type}
                      disabled={true}
                      defaultValue={uniqueUsername}
                    />
                  ) : fieldData.type === "magentoStoretextField" ? (
                    <Input
                      placeholder={fieldData.placeHolder}
                      size="large"
                      className={styles["input-icon__form--input"]}
                      type={fieldData.type}
                      disabled={ButtonLabel}
                      addonBefore="https://"
                    // addonAfter=".myshopify.com"
                    />
                  ) : null}
                </Form.Item>
              </div>
            </div>
          ))}
          {/* To add company in the NetSuite */}
          {selectCompany && (
            <>
              <label className={styles["input-icon__title--label"]}>
                {"Select company"}
                <span className="required-color">*</span>
              </label>
              <div className={styles["input-icon__form"]}>
                <Select
                  size="large"
                  defaultValue={"Select"}
                  style={{ width: "100%" }}
                  options={titleKey === "Magento" ? magentoOptions : options}
                  onChange={(e) => {
                    netSuiteOptionsHandler(e);
                  }}
                />
              </div>
            </>
          )}

          <hr className={styles["hr_width"]} />
          <div key={"wrapper"} className={styles["integration-model__button"]}>
            <button
              style={ButtonLabel ? { width: 120 } : undefined}
              type="submit"
              className={`${styles["integration-model__button--save"]} ${styles["integration-model__button--btn"]}`}
              disabled={isConnecting}
            >
              {isConnecting ? (
                <img
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                  height={40}
                  crossOrigin={
                    process.env.REACT_APP_ENV === "local"
                      ? undefined
                      : "anonymous"
                  }
                />
              ) : ButtonLabel ||
                titleKey === "Shopify" ||
                titleKey === "QuickbooksDesktop" ? (
                "Connect"
              ) : (
                "Test Credentials"
              )}
            </button>
            <button
              type="button"
              onClick={() => {
                handleCancel();
                handleFormReset();
                SetButtonLabel(false); // Reset form fields and values when Cancel is clicked
              }}
              className={`${styles["integration-model__button--cancel"]} ${styles["integration-model__button--btn"]}`}
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default IntegrationModal;
