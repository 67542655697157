import { Button, Checkbox, Form } from "antd";
import { InputWithLabelAndSvgReg } from "components/Global";
import { FC } from "react";
import styles from "./index.module.scss";

import { RegisterLayoutBodyProps } from "./types";
import { Link, useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

// register layout body designing
const RegisterLayoutBody: FC<RegisterLayoutBodyProps> = (props) => {
  const navigate = useNavigate();
  // Inits
  const {
    title,
    description,
    formData: registerFields,
    buttonTitle,
    onSubmit,
    isLoading,
    accountText,
    accountUrl,
    setPhoneNumber,
    phoneNumber,
    phoneError,
    setPhoneError,
    setIsPasswordValidate,
  } = props;

  // Handle form submission failure
  const onFinishFailed = () => {
    // Regular expression to validate a phone number
    // const re = /^(\+\d{1,3}[- ]?)?\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    const re = /\S/;


    if (phoneNumber === "") {
      setPhoneError("Please enter phone number");
    }
    else if (!re.test(phoneNumber)) {
      setPhoneError("Please enter a valid phone number");
    } 
    else {
      setPhoneError("");
    }


  };

  // JSX
  return (
    <div className={styles["register-body"]}>
      <Form
        className={styles["register-body__wrapper"]}
        name="basic"
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
      >
        <div className={styles["register-body__top"]}>
          <div className={styles["register-body__top--title"]}>
            <h4 className={styles["register-body__top--title--maintitle"]}>
              {title}{" "}
            </h4>
            <div
              onClick={() => {
                navigate("/on-boarding");
                localStorage.clear();
              }}
              style={{ cursor: "pointer", color: "#0b78c2" }}
            >
              <LeftOutlined /> Back
            </div>
          </div>
          {description && (
            <div className={styles["register-body__top--description"]} style={{marginBottom:'3%'}}>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          )}
        </div>
        <div className={styles["register-body__center"]}>
          {registerFields.map((singleUserInput, key) => {
            return (
              <InputWithLabelAndSvgReg
                key={key}
                singleUserInput={singleUserInput}
                setPhoneNumber={setPhoneNumber}
                phoneError={phoneError}
                setPhoneError={setPhoneError}
                setIsPasswordValidate={setIsPasswordValidate}
              />
            );
          })}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <Checkbox style={{ color: "gray", margin: "10px 5px" }}>
              Opt in to receive updates and news from Synctools
            </Checkbox>
          </div>
        </div>

        <div className={styles["register-body__end"]}>
          <Button
            type="primary"
            className={`${styles["register-body__end--button"]} ${
              isLoading && "pointer-event-none"
            }`}
            htmlType="submit"
          >
            {isLoading ? (
              <img
                src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                height={40}
                crossOrigin={
                  process.env.REACT_APP_ENV === "local"
                    ? undefined
                    : "anonymous"
                }
              />
            ) : (
              <>{buttonTitle}</>
            )}
          </Button>
        </div>
        <div className={styles["register-body__account-text"]}>
          <div className={styles["register-body__account-text--text"]}>
            {accountText}
          </div>
          <Link
            to={"/login"}
            className={styles["register-body__account-text--link"]}
          >
            {accountUrl}
          </Link>
        </div>
      </Form>
    </div>
  );
};

export default RegisterLayoutBody;
