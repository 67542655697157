import { Space, Switch, Table } from "antd";
import { FC, useEffect, useState } from "react";
import styles from "./index.module.scss";
// import IntegrationModal from "components/Global/IntegrationModel";
import { DynamicCategoryTableProps } from "./types";
// import { getApi } from "redux/apis";
import { useNavigate } from "react-router";
import { checkPermission, toastText } from "utils/utils";
import {
  CssSpriteNames,
  DescriptionKeys,
  IntegrationDescriptions,
} from "utils/staticObject";
import { UserProfileInterface } from "interfaces/user.interface";
import { useDispatch, useSelector } from "react-redux";
import { PermissionsNames } from "constants/Data";
import { UpdateIntegrationStatuAction } from "redux/action/IntegrationAction";
import AddScope from "../addScopeModal";

const DynamicCategoryTable: FC<DynamicCategoryTableProps> = (props) => {
  // Inits
  const { dataSource, type, isLoading } = props;
  const { Column } = Table;
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [isScopeModalOpen, setIsScopeModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedCompanyPermission = useSelector(
    (state: UserProfileInterface) =>
      state?.userProfile?.data?.companies[0]?.role?.permissions
  );

  // Determine if 'view' permission is granted for 'Integrations'
  const hasPermission = checkPermission(selectedCompanyPermission, {
    permissionName: PermissionsNames.AccessAllIntegrations,
    permission: ["add", "edit"],
  });

  const handleStorageChange = (data: any) => {
    const { key } = data;
    if (key === "callBackUpdatedData") {
      const callbackdata = JSON.parse(localStorage.getItem(key) as string);
      if (!callbackdata.error) {
        toastText("App Connected Successfully", "success");
        navigate("/settings/integrations/connectedApps");
      } else {
        toastText(callbackdata?.message || "Fail to Connect", "error");
        // setIsModalOpen(false);
      }
    }
  };

  const statusHandler = async (value: any, data: any) => {
    if (value) {
      setSelectedIntegration({
        ...data,
      });
      setIsScopeModalOpen(true);
    } else {
      dispatch(UpdateIntegrationStatuAction(data?.id) as any);
    }
  };

  const handleCancel = () => {
    setSelectedIntegration(undefined);
    setIsScopeModalOpen(false);
  };
  const handleOk = () => {
    setIsScopeModalOpen(false);
    setSelectedIntegration(undefined);
  };
  const EditConfigurationData = (data: any) => {
    setSelectedIntegration({
      ...data,
    });
    setIsScopeModalOpen(true);
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);
  // JSX
  return (
    <>
      <div className={"dynamic-table"}>
        <Table
          dataSource={dataSource}
          scroll={{ y: "calc(85vh - 190px)"}}
          className="connectButtons table-global"
          pagination={false}
          // onChange={tableChangeHandler}
          loading={isLoading}
        >
          <Column
            title={`${type.charAt(0).toUpperCase() + type.slice(1)} Apps`}
            dataIndex="titleKey"
            key="titleKey"
            width={"30%"}
            showSorterTooltip={{ title: "" }}
            className="bg-white"
            render={(_, record: any) => (
              <>
                <div
                  className={
                    styles[
                      `bg-${
                        CssSpriteNames[
                          record.integrationName as keyof typeof CssSpriteNames
                        ]
                      }`
                    ]
                  }
                >
                  {" "}
                </div>
              </>
            )}
          />

          <Column
            title="Description"
            dataIndex="description"
            key="description"
            width={"80%"}
            showSorterTooltip={{ title: "" }}
            className="bg-white"
            render={(_, record: CardInterface) => {
              return (
                <>
                  <span style={{ fontWeight: "bold" }}>
                    {DescriptionKeys[
                      record.integrationName as keyof typeof CssSpriteNames
                    ] + " "}
                  </span>
                  <span>
                    {
                      IntegrationDescriptions[
                        record.integrationName as keyof typeof IntegrationDescriptions
                      ]
                    }
                  </span>
                </>
              );
            }}
          />
          <Column
            title="Action"
            dataIndex="buttonText"
            key="buttonText"
            className="bg-white"
            width={"20%"}
            render={(values, data: CardInterface) => (
              <Space size={20}>
                {hasPermission ? (
                  <>
                    <div className="cursor-pointer flex align-center justify-center">
                      <div>
                        <Switch
                          key={Math.random()}
                          defaultChecked={data?.isOn}
                          onChange={(e) => statusHandler(e, data)}
                        ></Switch>
                      </div>
                      {data?.isOn && (
                        <div
                          style={{
                            marginLeft: 20,
                            color: "#0b78c2",
                            fontWeight: 600,
                          }}
                          onClick={() => EditConfigurationData(data)}
                        >
                          Configure
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
              </Space>
            )}
          />
        </Table>
      </div>
      {selectedIntegration && (
        <AddScope
          isModalOpen={isScopeModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          selectedIntegration={selectedIntegration}
          isLoading={isLoading as boolean}
        />
      )}
    </>
  );
};

export default DynamicCategoryTable;

interface CardInterface {
  isOn: boolean | undefined;
  integrationName: string;
  cssSpriteKey: string;
  title: string;
  description: string;
  buttonText: string;
  logo: string;
  ghost: boolean;
  select: boolean;
  type: string;
  connect: boolean;
  titleKey: string;
  formData?: FormData;
  isCompanyAdmin: boolean;
  isAdmin: boolean;
  DescriptionKey: string;
}

interface FormData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}
