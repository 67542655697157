// phoneNumberFormatter formats a phone number into (XXX) XXX-XXXX format.
// It removes non-digit characters from the input phone number and adds parentheses and hyphens.
const phoneNumberFormatter = (phoneNumber:any) => {
  // Remove non-digit characters from the input phone number
  const inputPhoneNumber = phoneNumber?.replace(/\D/g, '');

  let formattedPhoneNumber = '';

  // Check if there are digits in the input phone number
  if (inputPhoneNumber.length > 0) {
    // Format the area code (first 3 digits)
    formattedPhoneNumber = `(${inputPhoneNumber.slice(0, 3)}`;

    // Add a space and format the next 3 digits
    if (inputPhoneNumber.length >= 4) {
      formattedPhoneNumber += `) ${inputPhoneNumber.slice(3, 6)}`;
    }

    // Add a hyphen and format the last 4 digits
    if (inputPhoneNumber.length >= 7) {
      formattedPhoneNumber += `-${inputPhoneNumber.slice(6, 10)}`;
    }
  }

  return formattedPhoneNumber;
};

export default phoneNumberFormatter;


const phoneNumberFormatterr = (phoneNumber: any, dialCodeLength: string) => {
  // Remove non-digit characters from the input phone number
  const inputPhoneNumber = phoneNumber?.replace(/\D/g, '');

  let formattedPhoneNumber = '';

  // Check if there are digits in the input phone number
  if (inputPhoneNumber.length > 0) {
    // Extract the dial code
    const dialCode = inputPhoneNumber.slice(0, dialCodeLength);

    // Format the next 10 digits
    const remainingDigits = inputPhoneNumber.slice(dialCodeLength, dialCodeLength + 10); // Assuming 10 digits after dial code
    formattedPhoneNumber = `${dialCode} ${remainingDigits}`;

    // If there are more than 10 digits after the dial code, format the remaining digits with parentheses and hyphens
    if (inputPhoneNumber.length > dialCodeLength + 10) {
      formattedPhoneNumber += ` (${inputPhoneNumber.slice(dialCodeLength + 10, dialCodeLength + 13)}) ${inputPhoneNumber.slice(dialCodeLength + 13)}`;
    }
  }
 

  return formattedPhoneNumber;
};

export  {phoneNumberFormatterr};
