import { Button, Card, Col, Row } from "antd";
import { useSelector } from "react-redux";
import styles from "./index.module.scss";
import { CssSpriteNames } from "utils/staticObject";
import "./index.scss";
import {CloseCircleOutlined } from "@ant-design/icons";

const FailToConnectAppComponent = () => {
  const {data,isLoading} = useSelector((state: any) => {
    return state?.companies;
  });
  return !isLoading ? (
    <div className={styles["connetedapps"]}>
      <span className={styles["connetedapps__title"]}>
        Fail to connected with {data?.companyName}
      </span>
      <div className={styles["connetedapps__images"]}>
        <Row justify={"center"} gutter={25}>
          <Col
            // span={6}
            xs={24}
            md={8}
            lg={6}
            sm={12}
            style={{ marginBottom: 12 }}
          >
            <Card bordered={true} className={`card failconnect`}>
              <div
                className={
                  styles[
                    `bg-${
                      CssSpriteNames[
                        localStorage.getItem(
                          "integration"
                        ) as keyof typeof CssSpriteNames
                      ]
                    }`
                  ]
                }
              >
                {" "}
              </div>
              <div className="right-mark">
                <CloseCircleOutlined
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    margin: "8px",
                    color: "red",
                  }}
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Button
            className={`${styles["connetedapps__connectbtn"]} edit`}
            onClick={() => {
              window.close();
            }}
          >
            Finish
          </Button>
        </Row>
      </div>
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <img
        src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading.gif`}
        height={120}
        crossOrigin={
          process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
        }
      />
    </div>
  );
};

export default FailToConnectAppComponent;
