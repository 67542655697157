import { Button, Input } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { emailVerificationAction } from 'redux/action/emailVerificationAction';
import { clearRedux as clearReduxEmail } from 'redux/slice/emailVerificationSlice';
import { AppDispatch } from 'redux/store';
import { VerificationCodeBody } from '..';

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const OnBoardingEmailRegister = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [isVerification, setIsVerification] = useState(true);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [formValidError, setFormValidError] = useState('');
    const dispatch = useDispatch<AppDispatch>();
    const emailState = useSelector((data: any) => data?.emailVerification?.data?.data?.email)
    const emailError = useSelector((data: any) => data?.emailVerification?.error?.responseStatus)

    const handleChangeEmail = (e: any) => {
        if (e?.target?.value !== '' && e?.target?.value !== undefined) {
            setIsValidEmail(!!emailRegex.test(e?.target?.value));
            setEmail(e?.target?.value);
            setFormValidError('')
        } else {
            setFormValidError('Please enter an email')
        }

    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsValidEmail(!!emailRegex.test(email));
        if (emailRegex.test(email)) {
            setIsLoading(true);
            dispatch(emailVerificationAction({ email }))
        } else {
            if (email ===''){
                setFormValidError('Please enter an email')
            }else{
                setFormValidError('Please enter a valid email')
            }
        }

    }

    const handleNewEmail = () => {
        localStorage.clear();
        setIsVerification(true);
        dispatch(clearReduxEmail())
    }

    useEffect(() => {
        const emailLocal = localStorage.getItem('email');
        if (emailLocal !== undefined && emailLocal !== null) {
            setIsVerification(false);
            setEmail(emailLocal);
            setIsLoading(false);
        }
    }, [emailState]);

    useEffect(() => {
        setIsLoading(false);
        dispatch(clearReduxEmail())
    }, [emailError])

    return (
        <>
            {isVerification ? <div className={styles['main']} >
                <div className={styles['header']} >
                    <img src='assets/SyncToolLogo/syncToolLogo.png' alt='SyncTools' width={'200px'} />
                    <p className={styles['header__text']} >Create Your Account</p>
                </div>
                <div className={styles['body']} >
                    <form onSubmit={handleSubmit}>
                        <div className={styles['body__email-field']}>
                            <p style={{ color: 'black', fontSize: '16px', fontWeight: 400, paddingBottom: '10px' }} >Email address <span style={{ color: 'red' }} >*</span></p>
                            <Input
                                placeholder="name@example.com"
                                onChange={(e) => handleChangeEmail(e)}
                                status={!isValidEmail ? 'error' : ''}
                                size='large'

                            />
                            <div className={styles["emailError"]}>{formValidError}</div>
                        </div>
                        <div className={styles['body__email-submit']} >
                            <Button loading={isLoading} type='primary' size='large' htmlType='submit' className={styles['body__email-submit__button']} >Continue</Button>
                        </div>
                    </form>

                    <div style={{ marginTop: 29 }}><p className={styles['body__helper-text']} >By creating an account, you agree to the <a href='https://synctools.io/' target='__blank'>Terms of Service</a></p></div>
                    <div className={styles['body__helper-text-sub']} >
                        <img style={{ marginRight: '5px' }} src='/assets/OnBoarding/privacy-policy.svg' alt='' />
                        <p>We Respect Your Privacy. <a href='https://synctools.io/privacypolicy.html' target='__blank'>Privacy Policy</a></p>
                    </div>
                    <div className={styles['body__steps']} >
                        <div className={styles['body__steps-content']} >
                            <img src='/assets/OnBoarding/24_7.svg' alt='24*7' width={50} height={50} />
                            <div className={styles['body__steps-content__text']}>
                                24 * 7 Support
                            </div>
                        </div>
                        <div className={styles['body__steps-content']} >
                            <img src='/assets/OnBoarding/sync.svg' alt='sync' width={50} />
                            <div className={styles['body__steps-content__text']}>
                                We are not storing any
                                data we just syncing !!
                            </div>
                        </div>
                        <div className={styles['body__steps-content']} >
                            <img src='/assets/OnBoarding/spam-email.svg' alt='spam-email' width={50} />
                            <div className={styles['body__steps-content__text']}>
                                We are not sending any
                                marketing or spam emails.
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <VerificationCodeBody email={email} handleNewEmail={handleNewEmail} />}
        </>
    )
}

export default OnBoardingEmailRegister