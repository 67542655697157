import { Loader, TableActionHeader } from "components/Global";
import React, {
  // useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePasswordAction,
  // fetchProfileAction,
} from "redux/action/profileAction";
import styles from "./index.module.scss";
import { Avatar, Button, Card, Col, Input, QRCode, Row } from "antd";
import { hasText } from "utils/utils";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import UserProfileModal from "components/Profile";
const MyProfile = () => {
  const {
    data: userData,
    isLoading: loadingData,
    changePassLoader,
  } = useSelector((state: any) => state?.userProfile);

  const company = userData?.companies.find(
    (singleCompany: any) =>
      singleCompany.companyId === localStorage.getItem("companyId")
  );
  const [currPassword, setCurrPassword] = useState<any>("");
  const [newPassword, setNewPassword] = useState<any>("");
  const [confirmPassword, setConfirmPassword] = useState<any>("");

  const [isBetween8to16, setIsBetween8to16] = useState(false);
  const [passwordConditions, setHasPasswordConditions] = useState(false);
  const [editing, setEditing] = useState(false);

  const [showPassError, setShowPassError] = useState(false);

  const [showError, setShowError] = useState(false);

  const getInitials = (fullname: string) => {
    // const name = logedinUserInfo()?.name;
    const name = fullname;

    let initials = "";
    if (name) {
      name.split(" ")?.forEach((e: any) => {
        initials += e[0].toUpperCase();
      });
    }
    return initials;
  };

  const handleChangeNewPassword = (value: string | null | any) => {
    const checkLength = /^.{8,}$/.test(value);
    setIsBetween8to16(checkLength);

    const checkSpecialChar =
      /^(?=.*\d)(?=.*[a-z])(?=.*[!@#$%^&*()\-_=+{};:,<.>/?])/.test(value);
    setHasPasswordConditions(checkSpecialChar);

    setShowPassError(true);

    if (checkLength && checkSpecialChar) {
      setShowPassError(false);
    }

    setNewPassword(value);
  };
  const dispatch = useDispatch();

  const handleCancel = () => {
    setNewPassword("");
    setCurrPassword("");
    setConfirmPassword("");
    setShowError(false);
  };

  const editingCancel = () => {
    setEditing(false);
  };
  const handleUpdatePassword = async () => {
    if (
      newPassword &&
      currPassword &&
      confirmPassword &&
      isBetween8to16 &&
      passwordConditions
    ) {
      if (newPassword !== confirmPassword) {
        return;
      }

      const passwordData = {
        currentPassword: currPassword,
        newPassword: newPassword,
        confirmPassword: confirmPassword,
      };
      dispatch(changePasswordAction(passwordData) as any);

      handleCancel();
    } else {
      setShowError(true);
    }
  };
  return (
    <>
      {!loadingData ? (
        <>
          <TableActionHeader title={"My Profile"}></TableActionHeader>

          <Row gutter={24} style={{ marginBottom: 20 }}>
            <Col span={16}>
              <Card style={{ height: "100%" }}>
                <Row gutter={24}>
                  <Col span={24}>
                    <div className={styles["profile-header"]}>
                      <div className={styles["profile-header__info"]}>
                        <Avatar
                          size={54}
                          style={{
                            background: "#D2E3ED",
                            color: "#1D75A7",
                            fontSize: "22px",
                            fontWeight: "500",
                          }}
                        >
                          {getInitials(userData?.fullName)}
                        </Avatar>
                        <div>
                          <p className={styles["profile-header__info-name"]}>
                            {userData?.fullName}
                          </p>
                          <p className={styles["profile-header__info-email"]}>
                            {userData?.email}
                          </p>
                        </div>
                      </div>
                      <div>
                        <Button
                          onClick={() => {
                            setEditing(true);
                          }}
                          className="edit"
                        >
                          Edit
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles["profile-content"]}>
                      <p className={styles["profile-content__label"]}>
                        Full Name
                      </p>
                      <p className={styles["profile-content__value"]}>
                        {userData?.fullName}
                      </p>
                    </div>
                    <div className={styles["profile-content"]}>
                      <p className={styles["profile-content__label"]}>Role</p>
                      <p className={styles["profile-content__value"]}>
                        {company?.role?.roleName ||
                          (company?.role?.roleName == undefined &&
                            userData?.isFirstCompanyAdmin &&
                            "Company Admin")}
                      </p>
                    </div>
                    <div className={styles["profile-content"]}>
                      <p className={styles["profile-content__label"]}>
                        Country
                      </p>
                      <p className={styles["profile-content__value"]}>
                        {userData?.country}
                      </p>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={styles["profile-content"]}>
                      <p className={styles["profile-content__label"]}>Email</p>
                      <p className={styles["profile-content__value"]}>
                        {userData?.email}
                      </p>
                    </div>
                    <div className={styles["profile-content"]}>
                      <p className={styles["profile-content__label"]}>
                        Company Name
                      </p>
                      <p className={styles["profile-content__value"]}>
                        {company?.company?.companyName}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <Row gutter={24}>
                  <Col span={24}>
                    <div className={styles["password-header"]}>
                      Change Password
                    </div>
                  </Col>
                  <Col span={24} style={{ marginTop: "10px" }}>
                    <div className={styles["password__input-field"]}>
                      <p className={styles["password__input-field__label"]}>
                        Current password <span style={{ color: "red" }}>*</span>
                      </p>
                      <Input.Password
                        placeholder="Current password"
                        value={currPassword}
                        size="large"
                        onChange={(e) => setCurrPassword(e.target.value)}
                        status={
                          showError && !hasText(currPassword) ? "error" : ""
                        }
                      />
                    </div>
                  </Col>
                  <Col span={24} style={{ marginTop: "10px" }}>
                    <div className={styles["password__input-field"]}>
                      <p className={styles["password__input-field__label"]}>
                        New Password <span style={{ color: "red" }}>*</span>
                      </p>
                      <Input.Password
                        placeholder="New Password"
                        value={newPassword}
                        size="large"
                        onChange={(e) =>
                          handleChangeNewPassword(e.target.value)
                        }
                        status={
                          (showError && !hasText(newPassword)) ||
                          (hasText(newPassword) && showPassError)
                            ? "error"
                            : ""
                        }
                      />
                      {(showError && !hasText(newPassword)) ||
                      (hasText(newPassword) && showPassError) ? (
                        <div style={{ marginTop: "5px" }}>
                          <p
                            style={{
                              color: isBetween8to16 ? "#008000" : "red",
                            }}
                          >
                            {" "}
                            <span>
                              {isBetween8to16 ? (
                                <CheckCircleOutlined />
                              ) : (
                                <CloseCircleOutlined />
                              )}
                            </span>{" "}
                            More than 8 characters
                          </p>
                          <p
                            style={{
                              color: passwordConditions ? "#008000" : "red",
                            }}
                          >
                            {" "}
                            <span>
                              {passwordConditions ? (
                                <CheckCircleOutlined />
                              ) : (
                                <CloseCircleOutlined />
                              )}
                            </span>{" "}
                            Includes capital and lowercase characters, special
                            characters, and numbers
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                  <Col span={24} style={{ marginTop: "10px" }}>
                    <div className={styles["password__input-field"]}>
                      <p className={styles["password__input-field__label"]}>
                        Confirm password <span style={{ color: "red" }}>*</span>
                      </p>
                      <Input.Password
                        placeholder="Confirm password"
                        value={confirmPassword}
                        size="large"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        status={
                          showError && !hasText(confirmPassword) ? "error" : ""
                        }
                      />
                      {hasText(confirmPassword) &&
                      newPassword !== confirmPassword ? (
                        <p style={{ color: "red", marginTop: "5px" }}>
                          Passwords do not match. Please enter the same password
                        </p>
                      ) : null}
                    </div>
                  </Col>
                  <Col span={24} style={{ marginTop: "15px", display: "flex" }}>
                    <Button
                      loading={changePassLoader}
                      className="edit"
                      onClick={() => handleUpdatePassword()}
                    >
                      Update
                    </Button>
                    <Button
                      className="cancel"
                      style={{ marginLeft: "10px" }}
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {editing && (
            <UserProfileModal
              isProfileModalOpen={editing}
              handleCancel={editingCancel}
            />
          )}
          <div className={styles["twofa"]}>
            <div className={styles["twofa__title"]}>
              <TableActionHeader
                title={" Two-factor authentication"}
              ></TableActionHeader>
              <Row gutter={24}>
                <Col className={styles["twofa__title--des"]} span={10}>
                  Adds an Extra layer of security to your account
                </Col>
                <Col className={styles["twofa__title--des"]} span={4}>
                  <Button>Enable</Button>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col className={styles["twofa__subtitle"]} span={14}>
                  Integrate Synctools into your two-factor authentication
                  application.
                </Col>
              </Row>
              <Row gutter={24}>
                <Col className={styles["twofa__subtitle--des"]} span={10}>
                  <Row gutter={24}>
                    <Col className={styles["twofa__subtitle--des"]} span={16}>
                      Scan the QR code displayed and enter the code generated
                      below.
                    </Col>
                    <Col
                      span={16}
                      className={styles["twofa__subtitle--textfield"]}
                    >
                      <Input
                        placeholder={"Authentication code"}
                        size="large"
                        type={"text"}
                        disabled={false}
                      />
                    </Col>
                    <Col span={16}>
                      <Button className="edit">Submit</Button>
                    </Col>
                  </Row>
                </Col>
                <Col className={styles["twofa__subtitle--des"]} span={4}>
                  <QRCode type="svg" value="https://ant.design/" />
                </Col>
              </Row>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default MyProfile;
