import { apiKeysTableColumns } from "constants/Data";
import { useEffect, useState } from "react";
import DynamicAPIKeysTable from "./Table";
// import { TableActionHeader } from "components/Global";
import './index.scss';
// import { AddSvg } from "utils/svgs";
// import CreateAPIKeyModal from "./Modal";
import { useDispatch, useSelector } from "react-redux";
import {
    // deleteApiKey,
    getApiKey
} from "redux/action/apikeyAction";
import { AppDispatch } from "redux/store";

const APIKeysComponent = () => {
    const [pageSize, setPageSize] = useState(20);
    const [currentPageNo, setCurrentPageNo] = useState(1);
   

    const dispatch = useDispatch<AppDispatch>()
    const getApiKeyData = useSelector((data: any) => data?.apikeys)

    const tempData = getApiKeyData?.data?.data
    const totalRecords = tempData?.length;

    const modifyPageSize = (current: number, size: number) => {
        if (!size) {
            setPageSize(10);
        } else {
            setPageSize(size);
            localStorage.setItem("userTableSize", size.toString());
        }
        setCurrentPageNo(1);
    };

    
   

    useEffect(() => {
        dispatch(getApiKey())
    }, [])

    return (
        <>

            <DynamicAPIKeysTable
                apiKeyDataSource={tempData}
                apiKeyColums={apiKeysTableColumns}
                PageSize={pageSize}
                currentPage={currentPageNo}
                totalRecords={totalRecords}
                modifyPageSize={modifyPageSize}
                isLoading={getApiKeyData?.isLoading}
            />
            
           
        </>

    )
}

export default APIKeysComponent;
