import { createAsyncThunk } from '@reduxjs/toolkit';
import { postApi } from 'redux/apis';


export const emailVerificationAction = createAsyncThunk(
    "auth/send-verification-code",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await postApi("/auth/send-verification-code", data);
            localStorage.setItem('email',response?.data?.data?.email )
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);