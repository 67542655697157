/* eslint-disable react-hooks/rules-of-hooks */
import DynamicCategoryTable from "components/Global/DynamicCategoryTable";
import { PermissionsNames } from "constants/Data";
import { UserProfileInterface } from "interfaces/user.interface";
import { FC, useEffect } from "react";
import {  useSelector} from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { checkPermission } from "utils/utils";
const ERPTable: FC = () => {
  const location = useLocation();

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const integrationList = useSelector((state: any) => state?.Integration?.data);
  const isLoading = useSelector((state: any) => state?.Integration?.isLoading);

  const erpDataList = integrationList?.filter((items: any) => items.integrationType === 'ERP')

  // Select user's company permissions
  const selectedCompanyPermission = useSelector(
    (state: UserProfileInterface) =>
      state?.userProfile?.data?.companies[0]?.role?.permissions
  );

  // Check if user has permission to view Integrations
  const hasViewPermission = checkPermission(selectedCompanyPermission, {
    permissionName: PermissionsNames.AccessAllIntegrations,
    permission: ["view"],
  });

  useEffect(() => {
    // Redirect to the home page if the path starts with '/settings/integrations'
    if (
      location.pathname.startsWith("/settings/integrations") &&
      selectedCompanyPermission?.length &&
      !hasViewPermission
    ) {
      navigate("/");
    } 
  }, [location, selectedCompanyPermission?.length]);

  // Render the DynamicCategoryTable component
  return (
    <DynamicCategoryTable
      dataSource={erpDataList}
      type={"ERP".toLowerCase()}
      isLoading={isLoading}
    />
  );
};

export default ERPTable;
