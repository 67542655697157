import { useEffect, useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import { postApi } from "redux/apis";
import { toastText } from "utils/utils";

const CallbackComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const location = useLocation();
const navigate = useNavigate();


  // Define a function to handle API calls and error handling
  const handleApiCall = async (apiEndpoint: any, data: any) => {
    try {
      // Make an API request
      const res = await postApi(apiEndpoint, data);
       navigate("/connectedapp");
       const message = res?.data?.data?.message ;
       toastText(message ?? "Connection successfull", "success");
      
      
    } catch (error: any) {
      const errMessage = error?.response?.data?.error?.message;
         toastText(errMessage, "error");
          navigate("/failtoconnect");
    }
    // finally{
    //   navigate("/connectedapp");
    // }
    // Close the window after the API call
    // window.close();
  };

  useEffect(() => {
    const run = async () => {
      setIsLoading(true);

      // Check the current pathname to determine the callback logic
      if (location.pathname.startsWith("/freshbook/callback")) {
        await handleApiCall("/fresh-books/callback", {
          code: queryParams.get("code"),
        });
      } else if (location.pathname.startsWith("/qbo/callback")) {
        const url = window.location.href;
        await handleApiCall("/qbo/callback", {
          url,
          companyId: localStorage.getItem("t"),
        });
      } else if (location.pathname.startsWith("/zoho/callback")) {
        await handleApiCall("/zoho/callback", {
          code: queryParams.get("code"),
          companyId: localStorage.getItem("t"),
        });
      } else if (location.pathname.startsWith("/xero/callback")) {
        const currentURL = window.location.href;
        const origin = window.location.origin;
        const url = currentURL.replace(origin, "");
        await handleApiCall("/xero/callback", {
          url,
          companyId: localStorage.getItem("t"),
        });
      } else if (location.pathname.startsWith("/wave/callback")) {
        await handleApiCall("/wave/callback", {
          code: queryParams.get("code"),
          companyId:localStorage.getItem('t')
        });
      }
      setIsLoading(false);
    };

    run();
  }, []);

  return (
    <div className={styles["main-container"]}>
      {isLoading && (
        <img
          src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading.gif`}
          height={60}
          crossOrigin={
            process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
          }
        />
      )}
    </div>
  );
};

export default CallbackComponent;
