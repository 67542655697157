import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi, postApi, putApiNoHeader } from 'redux/apis';

export const fetchProfileAction = createAsyncThunk(
	'auth/fetchProfile',
	async (_, { rejectWithValue }) => {
		try {	
			const response = await getApi('/auth/fetch-profile');
			return response.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const updateProfileAction = createAsyncThunk(
	'auth/updateProfile',
	async (data: any, { rejectWithValue }) => {
		try {
			const response = await putApiNoHeader('/auth', data);
			return response?.data;
		} catch (error: any) {
			if (!error.response) {
				throw error;
			}
			return rejectWithValue(error?.response?.data);
		}
	}
);

export const changePasswordAction = createAsyncThunk(
  "auth/changePassword",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await postApi("/auth/change-password", data);
      return response?.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);