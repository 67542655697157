import LogsComponent from "components/Developer/Logs"

const LogsPage = () => {
    return (
        <div>
            <LogsComponent />
        </div>
    )
}

export default LogsPage
