import { AuthLayout } from "components/Global/AuthLayout";
import GlobalLayout from "layouts/Global";
import { ForgotPassword, Home, Login, ResetPassword } from "pages";
import Roles from "pages/settings/roles";
import Settings from "pages/settings/settings";
import Users from "pages/settings/users";
import Register from "pages/Register";
import { createBrowserRouter } from "react-router-dom";
import Subcription from "pages/settings/subscription";
import IntegrationPage from "pages/settings/Integration";
import EcommerceTable from "components/settings/Integration/Ecommerce";
import ERPTable from "components/settings/Integration/ERP";
import AccountingTable from "components/settings/Integration/Accouting";
import AccessDeniedPage from "pages/AccessDeniedPage";
import CallbackComponent from "components/settings/Integration/Accouting/AccountingCallback";
import CRMCallbackComponent from "components/settings/Integration/CRM/CRMCallback";
import EcommerceCallbackComponent from "components/settings/Integration/Ecommerce/EcommerceCallback";
import ERPCallbackComponent from "components/settings/Integration/ERP/ERPCallback";
import QuickbookDesktop from "components/Global/QuickbookDesktop";
import PaymentGatewayCallbackComponent from "components/settings/Integration/PaymentGateway/PaymentGatewayCallback/PaymentGatewayCallback";
import PageNotFoundPage from "pages/PageNotFound";
import Configurations from "pages/settings/Configurations";
import { EmailLayoutBody } from "components/Register";
import Profile from "pages/settings/myProfile";
import APIKeysPage from "pages/Developer/APIKeys";
import WebhooksPage from "pages/Developer/Webhooks";
import LogsPage from "pages/Developer/Logs";
import { OauthConnectApps } from "components/Global";
import ConnectedApp from "pages/ConnectedApp";
import ConnectedAppsTable from "components/settings/Integration/ConnectedApps";
import FailToConnectApp from "pages/FailToConnectApp";
// import { OauthConnectApps } from "components/Global";

const router = createBrowserRouter([
  {
    path: "preview",
    element: <OauthConnectApps />,
  },
  {
    path: "connectedapp",
    element: <ConnectedApp />,
  },
  {
    path: "failtoconnect",
    element: <FailToConnectApp />,
  },
  {
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <GlobalLayout />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: "playground",
            element: <Configurations />,
          },
          {
            path: "/developer",
            children: [
              {
                path: "api-keys",
                element: <APIKeysPage />,
              },
              {
                path: "webhooks",
                element: <WebhooksPage />,
              },
              {
                path: "logs",
                element: <LogsPage />,
              },
            ],
          },
          {
            path: "integrations",
            element: <IntegrationPage />,
            children: [
              // check and remove
              {
                index: true,
                path: "connectedapps",
                element: <ConnectedAppsTable />,
              },
              // {
              //   path: "connectedApps",
              //   element: <ConnectedAppsTable />,
              // },
              {
                path: "ecommerce",
                element: <EcommerceTable />,
              },
              {
                path: "erp",
                element: <ERPTable />,
              },
              { index: true, path: "accounting", element: <AccountingTable /> },
              // {
              //   path: "paymentGateway",
              //   element: <PaymentGatewayTable />,
              // },
              // {
              //   path: "marketplace",
              //   element: <MarketPlaceTable />,
              // },
              // {
              //   path: "crm",
              //   element: <CRMTable />,
              // },
              {
                path: "qbd",
                element: <QuickbookDesktop />,
              },
            ],
          },
        ],
      },
      {
        path: "/settings",
        element: <Settings />,
        children: [
          {
            index: true,
            path: "users",
            element: <Users />,
          },
          {
            path: "roles",
            element: <Roles />,
          },
          {
            path: "my-profile",
            element: <Profile />,
          },
          {
            path: "subscription",
            element: <Subcription />,
          },
        ],
      },

      {
        element: <Login />,
        path: "/login",
      },
      {
        path: "/on-boarding",
        element: <EmailLayoutBody />,
      },
      {
        path: "/register",
        element: <Register />,
      },

      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/access-denied",
        element: <AccessDeniedPage />,
      },
    ],
  },
  {
    path: "/qbo/callback",
    element: <CallbackComponent />,
  },
  {
    path: "/freshbook/callback",
    element: <CallbackComponent />,
  },
  {
    path: "/zoho/callback",
    element: <CallbackComponent />,
  },
  {
    path: "/bc/oauth",
    element: <EcommerceCallbackComponent />,
  },
  {
    path: "/force/callback",
    element: <CRMCallbackComponent />,
  },
  {
    path: "/xero/callback",
    element: <CallbackComponent />,
  },
  {
    path: "wave/callback",
    element: <CallbackComponent />,
  },
  {
    path: "/shopify/callback",
    element: <EcommerceCallbackComponent />,
  },
  {
    path: "/dynamic/callback",
    element: <ERPCallbackComponent />,
  },
  {
    path: "/stripe/callback",
    element: <PaymentGatewayCallbackComponent />,
  },
  {
    path: "*",
    element: <PageNotFoundPage />,
  },
]);

export default router;
