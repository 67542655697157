import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUnifiedApi } from "redux/apis";

export const getVendorsAction = createAsyncThunk(
  "vendors/getVendors",
  async (data: any, { rejectWithValue }) => {
    const { params, headers } = data;
    try {
      const response = await getUnifiedApi(
        `/vendor/v1/vendors`,
        params,
        headers
      );

      console.log("response.data: ", response.data);
      return response.data.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
