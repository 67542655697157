import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApi, patchApi } from 'redux/apis';

// export const CreateIntegrationAction = createAsyncThunk(
// 	'integration/createIntegration',
// 	async ( values:any,{ rejectWithValue }) => {
// 		try {
            
// 			const response = await postApi(`/integration/connect`,values);
			
// 			return response.data.data;
// 		} catch (error: any) {
// 			if (!error.response) {
// 				throw error;
// 			}
// 			return rejectWithValue(error?.response?.data);
// 		}
// 	}
// );

export const GetIntegrationAction:any = createAsyncThunk(
  "integration/getIntegration",
  async (paramas: any = {}, { rejectWithValue }) => {
    try {
      const response = await getApi(`/integrations/organization-integrations`, {
        ...paramas,
      });

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const GetActiveIntegrationAction = createAsyncThunk(
  "integration/getActiveIntegration",
  async (paramas: any = {}, { rejectWithValue }) => {
    try {
      const response = await getApi(`/integrations/getActiveIntegration`, {
        ...paramas,
      });

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const UpdateIntegrationStatuAction = createAsyncThunk(
  "integration/updateIntegrationStatus",
  async (paramas: any = {}, { rejectWithValue }) => {
    try {
      const response = await patchApi(
        `/integrations/status-update/${paramas}`,
        {isOn:false}
      );


      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const UpdateIntegrationDataAction = createAsyncThunk(
  "integration/updateIntegrationData",
  async (data: any = {}, { rejectWithValue }) => {
    const {id} = data;
    try {
      const response = await patchApi(`/integrations/integration-update/${id}`, data);
     GetIntegrationAction('');

      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);


