import { Space, Table } from "antd";
import Column from "antd/es/table/Column";
import React from "react";
import { EditActionSvg, DeleteActionSvg } from "utils/svgs";

const WebhookEventTable = (props: any) => {
  const { webhookDataSource, setEditSelectedWebhook,showModal } = props;
  return (
    <div>
      <Table
        dataSource={webhookDataSource}
        scroll={{ y: "calc(85vh - 190px)"}}
        // scroll={{ y: "61vh", x: "63vh" }}
        // pagination={{
        //   pageSize: 30,
        // }}
        pagination={false}
        className="table-global"
      >
        {/* Table Columns */}
        <Column
          title={"URL"}
          dataIndex="url"
          key="url"
          width={"25%"}
          showSorterTooltip={{ title: "" }}
          className="bg-white"
          render={(_, record: any) => {
            // Display connected app logo
            return (
              <div>
                {record.url}
              </div>
            );
          }}
        />
        <Column
          title={"Secure Token"}
          dataIndex="secureToken"
          key="url"
          width={"30%"}
          showSorterTooltip={{ title: "" }}
          className="bg-white"
          render={(_, record: any) => {
            // Display connected app logo
            return (
              <div>
                {record.secureToken}
              </div>
            );
          }}
        />
        <Column
          title={"Event Type"}
          dataIndex="eventType"
          key="eventType"
          width={"25%"}
          showSorterTooltip={{ title: "" }}
          className="bg-white"
          render={(_, record: any) => {
            // Display connected app logo
            return (
              <div>
                {record.eventType}
              </div>
            );
          }}
        />
        <Column
          title="Action"
          dataIndex="action"
          key="action"
          className="bg-white"
          width={"20%"}
          render={(values: any, data: any) => {
            return (
              <Space size={20}>
                <>
                  {/* {isEditUserPermission ? ( */}
                  <div
                    className="cursor-pointer flex align-center justify-center"
                    // onClick={() => {setEditSelectedWebhook(data)
                    //  showModal()}}
                    style={{ marginRight: "2rem" }}
                  >
                    <EditActionSvg />
                  </div>

                  <div
                    style={{ marginRight: "2rem" }}
                    className="cursor-pointer flex align-center justify-center"
                    onClick={() => {
                      setEditSelectedWebhook(data);
                      showModal();
                    }}
                  >
                    <DeleteActionSvg />
                  </div>
                </>
              </Space>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default WebhookEventTable;
