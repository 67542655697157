import { createAsyncThunk } from '@reduxjs/toolkit';
import { postApi } from 'redux/apis';


export const codeVerificationAction = createAsyncThunk(
    "auth/send-verification-code",
    async (data: any, { rejectWithValue }) => {
        try {
            const response = await postApi("/auth/verify-verification-code", data);
            localStorage.setItem('isVerified', response?.data?.data?.isVerified)
            return response.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);