import APIKeysComponent from "components/Developer/APIKeys"

const APIKeysPage = () => {
    return (
        <div>
            <APIKeysComponent />
        </div>
    )
}

export default APIKeysPage
