import { Space, Table, Tag, Tooltip } from "antd";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import {
  deleteConnection,
  getActiveConnectionAction,
} from "redux/action/activeConnectionsActions";
import { AppDispatch } from "redux/store";
import { Channels } from "utils/constant";
import { DeleteActionSvg } from "utils/svgs";
import { colorChannelTypeMapping, formatDate, toastText } from "utils/utils";
import "./index.scss";
import { DynamicTableProps } from "./types";

const ConnectedTable: FC<DynamicTableProps> = (props) => {
  // Initialize Table Column and State
  const { Column } = Table;
  const { integrationDataSource } = props;
  // const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteConnectionId, setDeleteConnectionId] = useState<string | null>(
    null
  );

  // Get loading state from Redux
  const isLoading = useSelector(
    (State: any) => State?.ActiveServices?.isLoading
  );
  // const [channelName, setChannelName] = useState("");
  // const [companyName, setCompanyName] = useState("");
  // const [connectedId, setConnectedId] = useState("");

  // Create a Redux dispatch function
  const dispatch = useDispatch<AppDispatch>();

  // Fetch active connections on component mount
  useEffect(() => {
    dispatch(getActiveConnectionAction());
  }, []);

  // Handle delete action for a connection
  const deleteDataHandler = (connectionId: string) => {
    setDeleteConnectionId(connectionId);
    setIsModalOpen(true);
  };

  // const arrowButtonHandler = (value: any) => {
  //   localStorage.setItem("selectedSidebar","playground")
  //   navigate("/playground");
  //   localStorage.setItem('connectedPlayground', JSON.stringify({
  //     channelName: value?.channelName,
  //     companyName: value?.companyName,
  //     connectionId: value?.id
  //   }));
    
  // };

  // Handle confirmation modal OK action
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // Handle confirmation modal Cancel action
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Handle the actual deletion of a connection
  const deleteHandler = async () => {
    if (deleteConnectionId) {
      const data = await dispatch(
        deleteConnection({ id: deleteConnectionId })
      ).unwrap();
      if (data.statusCode === 200) {
        // Show success message
        toastText(data?.message, "success");
        setTimeout(() => {
          setIsModalOpen(false);
        }, 200);
        // Refresh active connections
        await dispatch(getActiveConnectionAction());
      } else if (data.statusCode !== 401) {
        // Show error message
        toastText(data?.message, "error");
      }
    }
  };

  // JSX
  return (
    <div className="dynamic-table">
      <Table
        dataSource={integrationDataSource}
        // scroll={{ y: "calc(85vh - 190px)", x: "63vh" }}
        // scroll={{ y: "61vh", x: "63vh" }}
        pagination={{
          pageSize: 30,
        }}
        loading={isLoading}
        className="table-global"
      >
        {/* Table Columns */}
        <Column
          title="Company Name"
          dataIndex="companyName"
          key="companyName"
          width={"15%"}
          showSorterTooltip={{ title: "" }}
          className="bg-white"
          render={(_, record: any) => {
            const companyName = record?.companyName;
            const companyId = record?.companyId;
            const selectedEnvironment =
              record?.channelName === Channels.BUSINESS_CENTRAL
                ? record?.selectedEnvironment
                : null;

            return (
              <div>
                {/* Display company name with optional selected environment */}
                <div style={{ color: companyName ? "black" : "#00000066" }}>
                  {companyName || "No company available"}
                </div>
                <div style={{ color: "#00000066", marginTop: "5px" }}>
                  {companyId || "No company Id available"}
                </div>
                {selectedEnvironment && (
                  <div style={{ color: "#00000066", fontSize: "x-small" }}>
                    {selectedEnvironment}
                  </div>
                )}
              </div>
            );
          }}
        />
        <Column
          title={"Last update"}
          dataIndex="modifiedDate"
          key="modifiedDate"
          width={"10%"}
          showSorterTooltip={{ title: "" }}
          className="bg-white"
          render={(_, record: any) => {
            // Display connected app logo
            return (
              <div>
                <div
                  style={{
                    textAlign: "start",
                  }}
                >
                  {formatDate(record?.modifiedDate)}
                </div>
              </div>
            );
          }}
        />
        <Column
          title={"Connections"}
          dataIndex="channelName"
          key="channelName"
          width={"10%"}
          showSorterTooltip={{ title: "" }}
          className="bg-white"
          render={(_, record: any) => {
            // Display connected app logo
            return (
              <div>
                <div
                  style={{
                    color: "#00000066",
                    fontSize: "small",
                    textAlign: "start",
                  }}
                >
                  <Tag
                    bordered={true}
                    color={colorChannelTypeMapping(record?.channelName)}
                  >
                    {record.channelName}
                  </Tag>
                </div>
              </div>
            );
          }}
        />

        <Column
          title="Action"
          dataIndex="action"
          key="action"
          className="bg-white"
          width={"7%"}
          render={(value: any, data: any) => {
            return (
              <div className="connectedTableButtons">
                <Space size={25}>
                  {/* Render action buttons if not an admin */}
                  {!(data.isCompanyAdmin || data.isAdmin) ? (
                    <>
                      {/* <Tooltip placement="top" title="Configuration">
                        <div className="cursor-pointer flex align-center justify-center">
                          <SettingOutlined
                            style={{ fontSize: "18px", color: "#153fcd" }}
                          />
                        </div>
                      </Tooltip> */}
                      <Tooltip placement="top" title="Delete">
                        <div
                          className="cursor-pointer flex align-center justify-center"
                          onClick={() => deleteDataHandler(data.id)}
                        >
                          <DeleteActionSvg />
                        </div>
                      </Tooltip>
                      {/* <Tooltip placement="top" title="Playground">
                  
                          <RightCircleFilled onClick={() => arrowButtonHandler(data)}
                          className="cursor-pointer flex align-center justify-center"/>
                       
                      </Tooltip> */}
                    </>
                  ) : null}
                </Space>
              </div>
            );
          }}
        />
      </Table>
      {/* Confirm Delete Modal */}
      <ConfirmDelete
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        deleteHandler={deleteHandler}
        isAddUserLoading={isLoading}
      />
    </div>
  );
};

export default ConnectedTable;
