import { RegistrationLayout } from "layouts";
import { FORMDATA } from "constants/Data";
import { loginAction } from "redux/action/loginAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { RegisterLayoutBody } from "components/Register";
import { getApi } from "redux/apis";
import { registerAction } from "redux/action/registerAction";
import { toastText } from "utils/utils";
import { AppDispatch } from "redux/store";

// Register page
const Register = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [isPasswordValidate, setIsPasswordValidate] = useState(false);


  const onSubmit = (values: any) => {
    const getEmail = localStorage.getItem("email");
    const newValues = {
      ...values,
      phone: phoneNumber,
      email: getEmail,
    };
    if (newValues.phone !== undefined && newValues.phone !== '') {

      if (phoneError === "" && isPasswordValidate  ) {
   
        setIsLoading(true);

        const { email } = newValues;
        getApi(`/auth/get-email`, { email })
          .then((res: any) => {

            if (res?.data?.data === null) {
              dispatch(registerAction(newValues))
                .unwrap()
                .then((res: any) => {
                  if (res.statusCode === 200) {
                    toastText(
                      "Registration successfully, Please check your inbox to verify your email. ",
                      "success"
                    );
                    navigate("/login");
                  }
                })
                .catch((error: any) => {
                  setIsLoading(false);
                  navigate("/register");
                  toastText(error.message, "error");
                });
            } else {
              toastText("User Already exist", "error");
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log('err: ', err);
            setIsLoading(false);
          });
      }

     
    }
    else {
      setPhoneError('Phone number should not be empty !!')
    }
  };



  return (
    <RegistrationLayout>
      <RegisterLayoutBody
        title="Register"
        description="<p>Please Enter your Details.</p>"
        formData={FORMDATA.registerFields}
        buttonTitle={"Sign Up"}
        action={loginAction}
        onSubmit={onSubmit}
        isLoading={isLoading}
        accountText={"Have an account?"}
        accountUrl={"Sign In"}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
        setPhoneError={setPhoneError}
        phoneError={phoneError}
        setIsPasswordValidate={setIsPasswordValidate}
      ></RegisterLayoutBody>
    </RegistrationLayout>
  );
};

export default Register;
