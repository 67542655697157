import { createSlice } from "@reduxjs/toolkit";
import { emailVerificationAction } from "redux/action/emailVerificationAction";
import { toastText } from "utils/utils";

const initialState: any = {
    data:null,
    isLoading: false,
    error: null,
};

const emailVerificationSlice = createSlice({
    name: "emailVerifcationSlice",
    initialState,
    reducers: {
        clearRedux: () => {
            return {
                ...initialState,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(emailVerificationAction.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(emailVerificationAction.fulfilled, (state, action: any) => {
            state.data = action?.payload
            state.isLoading = false;
            toastText(action?.payload?.message, "success");
        });
        builder.addCase(emailVerificationAction.rejected, (state, action: any) => {
            state.isLoading = false;
            state.error = action.payload;
            toastText(action?.payload?.message, "error");
        });
    },
});

export default emailVerificationSlice;
export const { clearRedux } = emailVerificationSlice.actions;
