/* eslint-disable @typescript-eslint/no-unused-vars */
import { ConfigProvider, Table } from "antd";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import moment from "moment";
import { useState } from "react";
import { deleteUnifiedApi, postUnifiedApi, putUnifiedApi } from "redux/apis";
import { invalidText, toastText } from "utils/utils";
import AddAccountModal from "../AddAccountModal";
import "./index.module.scss";

interface OptionInterface {
  label: string;
  value: string;
}

interface QBOAccount {
  key?: number | string;
  accountId: string;
  accountName: string;
  accountType: string;
  isActive: boolean;
  accountCode: string;
  classification: string;
  description: string;
  currency: string;
  modifiedDate: string;
  bankAccountNumber: string;
  isBankAccount: boolean;
  accountSubType: string;
}

interface Sorting {
  sortByFieldName: string;
  sortFieldValue: string | null;
}

interface Props {
  selectedEntity: string[];
  selectedConnection: OptionInterface | null;
  handleConnection: (value: OptionInterface | null) => void;
  selectedApp: string | null;
  filterHandler: any;
  selectedApiKey: any;
  datasource: any;
  entityTableColumns: any;
  loading: boolean;
}

const DynamicTable = (props: Props) => {
  const {
    selectedConnection,
    selectedApp,
    selectedApiKey,
    selectedEntity,
    datasource,
    entityTableColumns,
    loading,
  } = props;

  // const data = useSelector((state: any) => state[selectedEntity]);

  const { Column } = Table;

  const [accounts, setAccounts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<QBOAccount | null>(
    null
  );
  const [isAccLoading, setIsAccLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const handleModalClose = () => {
    setSelectedAccount(null);
    setIsModalOpen(false);
    setIsDeleteModalOpen(false);
  };

  const removeSelectedAccount = () => {
    setSelectedAccount(null);
  };

  const editAccountHandler = (account: QBOAccount) => {
    setSelectedAccount(account);
    setIsModalOpen(true);
  };

  const deleteAccountHandler = (account: QBOAccount) => {
    setSelectedAccount(account);
    setIsDeleteModalOpen(true);
  };

  const handleSubmit = async (values: any) => {
    if (values) {
      try {
        setIsAccLoading(true);

        if (invalidText(values.description)) {
          delete values["description"];
        }
        if (invalidText(values.accountSubType)) {
          delete values["accountSubType"];
        }
        if (invalidText(values.bankAccountNumber)) {
          delete values["bankAccountNumber"];
        }
        if (invalidText(values.currency)) {
          delete values["currency"];
        }
        if (
          (values.isActive == true || values.isActive == false) &&
          selectedAccount?.isActive === values.isActive
        ) {
          delete values["isActive"];
        }
        if (
          (values.isActive == true || values.isActive == false) &&
          selectedAccount?.isActive !== values.isActive &&
          selectedApp === "Xero"
        ) {
          const _values = {
            isActive: values["isActive"],
          };
          values = _values;
        }
        if (selectedAccount) {
          values["accountId"] = selectedAccount?.accountId;
          const acc: any = await putUnifiedApi(
            "/accounting/v1/accounts",
            values,
            {
              connectionId: selectedConnection,
              tenantId: localStorage.getItem("companyId"),
              accessToken: selectedApiKey,
            }
          );
          const _newAccounts: QBOAccount[] = accounts;
          const index = _newAccounts.findIndex(
            (item: any) => item.accountId === values.accountId
          );
          _newAccounts[index] = {
            key: acc?.data?.data.accountId,
            accountId: acc?.data?.data.accountId,
            accountName: acc?.data?.data.accountName,
            accountCode: acc?.data?.data.accountCode,
            accountType: acc?.data?.data.accountType,
            accountSubType: acc?.data?.data.accountSubType,
            description: acc?.data?.data.description,
            bankAccountNumber: acc?.data?.data.bankAccountNumber,
            isActive: acc?.data?.data?.isActive,
            classification: acc?.data?.data.classification,
            currency: acc?.data?.data.currency,
            modifiedDate: moment(acc?.data?.data.modifiedDate).format(
              "MM/DD/YYYY HH:mm:ss"
            ),
            isBankAccount: acc.isBankAccount,
          };
        } else {
          await postUnifiedApi("/accounting/v1/accounts", values, {
            connectionId: selectedConnection,
            tenantId: localStorage.getItem("companyId"),
            accessToken: selectedApiKey,
          });
        }
        handleModalClose();
        // checkFilterValues();
        // accountsHandler();
        // removeHandler()
        toastText(
          `Accounts ${selectedAccount ? "updated" : "created"} successfully`,
          "success"
        );

        removeSelectedAccount();
      } catch (err: any) {
        let message = `Something went wrong in ${
          selectedAccount ? "editing" : "creating"
        } account.`;
        if (err?.response?.data?.message) {
          message = err.response?.data?.message;
        }
        toastText(message, "error");
      } finally {
        setIsAccLoading(false);
      }
    }
  };

  const handleDelete = async () => {
    setIsDeleteLoading(true);
    try {
      await deleteUnifiedApi(
        `/accounting/v1/accounts/${selectedAccount?.accountId}`,
        {
          connectionId: selectedConnection,
          tenantId: localStorage.getItem("companyId"),
          accessToken: selectedApiKey,
        }
      );

      const _newAccounts: QBOAccount[] = accounts;
      const __newAccounts: any = _newAccounts.filter(
        (item: any) => item.accountId !== selectedAccount?.accountId
      );
      handleModalClose();
      setAccounts(__newAccounts);
      toastText("Accounts deleted successfully", "success");
    } catch (err: any) {
      let message = "Something went wrong in deleting account.";
      if (err?.response?.data?.message) {
        message = err.response?.data?.message;
      }
      toastText(message, "error");
    } finally {
      setIsDeleteLoading(false);
    }
  };

  return (
    <div style={{ marginTop: "10px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}></div>
      <div
        style={{
          marginTop: "2rem",
        }}
      >
        
          <Table
            dataSource={datasource}
            pagination={false}
            scroll={{ y: "calc(88vh - 200px)" }}
            loading={loading}
            columns={entityTableColumns}
          ></Table>

        {isModalOpen && (
          <AddAccountModal
            handleModalClose={handleModalClose}
            handleSubmit={handleSubmit}
            isNewAccountModalOpen={isModalOpen}
            selectedAccount={selectedAccount}
            isLoading={isAccLoading}
            removeSelectedAccount={removeSelectedAccount}
            selectedApp={selectedApp}
          />
        )}
        {isDeleteModalOpen && (
          <ConfirmDelete
            isModalOpen={isDeleteModalOpen}
            handleCancel={handleModalClose}
            deleteHandler={handleDelete}
            handleOk={handleModalClose}
            isAddUserLoading={isDeleteLoading}
          />
        )}
      </div>
    </div>
  );
};

export default DynamicTable;
