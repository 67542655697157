import moment from "moment";

export const capitalizeFirstLetter = (inputString: string) => {
  // Check if the inputString is a string and not empty
  if (typeof inputString !== "string" || inputString?.length === 0) {
    throw new Error("Input must be a non-empty string");
  }
  // Convert the first character to uppercase
  const firstCharUpperCase = inputString?.charAt(0)?.toUpperCase();

  // Combine the first uppercase character with the rest of the string
  return firstCharUpperCase + inputString?.slice(1);
};

export const formatUrl = (urlData: string) => {
  if (urlData) {
    // Remove query parameters if they exist
    const urlWithoutParams = urlData.split("?")[0];
    const parts = urlWithoutParams.split("/");
    // Check if parts array contains at least 2 elements
    if (parts.length >= 2) {
      // Extract the last two parts to get v1/accounts
      const endpoint = parts[parts.length - 2] + "/" + parts[parts.length - 1];
      return endpoint;
    }
  }
  return null;
};

export function maskValue(value: string) {
  if (value?.length > 3) {
    const firstThreeChars = value.substring(0, 3);
    const remainingChars = value.substring(3).replace(/./g, "*");
    return firstThreeChars + remainingChars;
  }
  return value;
}

export interface Customer {
  iD: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobileNumber: string;
  billAddress: {
    street1: string;
    city: string;
    countrySubDevisionCode: string | null;
    zipCode: string | null;
    country: string;
  };
}

export interface Vendor {
  id: number;
  firstName: string;
  lastName: string;
  emailAddress: string;
  createdDate: string;
  companyName: string;
  mobileNumber: string;
  isActive: boolean;
  taxNumber: string;
  accountNumber: string;
  accountsPayableTaxType: string;
  isSupplier: boolean;
  purchasesDefaultAccountCode: string;
  purchasesTrackingCategories: string[];
  billAddress: {
    city: string;
    street1: string;
    zipCode: string;
    countrySubDivisionCode: string;
    country: string;
  }[];
}

export interface Account {
  accountId: number;
  accountCode: string;
  accountName: string;
  description: string;
  currency: string;
  bankAccountNumber: string | null;
  accountType: string;
  accountSubType: string;
  isBankAccount: boolean;
  isActive: boolean;
  modifiedDate: string;
  isSubAccount: boolean;
  currentBalance: number;
}

type EntityType = "customers" | "vendors" | "accounts";

export const generateColumns = async (entityType: EntityType) => {
  let columns: any[] = [];

  // Common columns for all entities
  const commonColumns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "10%",
      className: "",
      render: (value: string | null) => (value ? value : "-"),
      sorter: (a: any, b: any) => {
        const firstNameA = a.firstName || "";
        const firstNameB = b.firstName || "";
        return firstNameA.localeCompare(firstNameB);
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      width: "10%",
      className: "",
      render: (value: string | null) => (value ? value : "-"),
      sorter: (a: any, b: any) => {
        const lastNameA = a.lastName || "";
        const lastNameB = b.lastName || "";
        return lastNameA.localeCompare(lastNameB);
      },
    },
    {
      title: "Email Address",
      dataIndex: "emailAddress",
      key: "emailAddress",
      width: "15%",
      className: "",
      render: (value: string | null) => (value ? value : "-"),
    },
    {
      title: "Contact",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      width: "10%",
      className: "",
      render: (value: string | null) => (value ? value : "-"),
    },
    {
      title: "Tax Number",
      dataIndex: "taxNumber",
      key: "taxNumber",
      width: "10%",
      className: "",
      render: (value: string | null) => (value ? value : "-"),
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: "8%",
      className: "",
      render: (value: boolean) => <span>{value ? "Active" : "Inactive"}</span>,
    },
  ];

  // Columns specific to each entity type
  switch (entityType) {
    case "customers":
      columns = [
        ...commonColumns,
        {
          title: "Modified Date",
          dataIndex: "modifiedDate",
          key: "modifiedDate",
          width: "10%",
          className: "",
          render: (value: string | null) => {
            // Use moment to format the date
            const formattedDate = value
              ? moment(value).format("YYYY-MM-DD")
              : "-";
            return <span>{formattedDate}</span>;
          },
          sorter: (a: any, b: any) => {
            if (!a.modifiedDate) return -1;
            if (!b.modifiedDate) return 1;
            return (
              moment(a.modifiedDate).unix() - moment(b.modifiedDate).unix()
            );
          },
        },
      ];
      break;
    case "vendors":
      columns = [
        ...commonColumns,
        {
          title: "Company Name",
          dataIndex: "companyName",
          key: "companyName",
          width: "15%",
          className: "",
          render: (value: string | null) => (value ? value : "-"),
        },
        {
          title: "Modified Date",
          dataIndex: "modifiedDate",
          key: "modifiedDate",
          width: "10%",
          className: "",
          render: (value: string | null) => {
            // Use moment to format the date
            const formattedDate = value
              ? moment(value).format("YYYY-MM-DD")
              : "-";
            return <span>{formattedDate}</span>;
          },
          sorter: (a: any, b: any) => {
            if (!a.modifiedDate) return -1;
            if (!b.modifiedDate) return 1;
            return (
              moment(a.modifiedDate).unix() - moment(b.modifiedDate).unix()
            );
          },
        },
        // Add more vendor-specific columns as needed
      ];
      break;
    case "accounts":
      columns = [
        {
          title: "Name",
          dataIndex: "accountName",
          key: "accountName",
          width: "10%",
          className: "",
          render: (value: string | null) => (value ? value : "-"),
        },
        {
          title: "Code",
          dataIndex: "accountCode",
          key: "accountCode",
          width: "6%",
          className: "",
          render: (value: string | null) => (value ? value : "-"),
        },
        {
          title: "Type",
          dataIndex: "accountType",
          key: "accountType",
          width: "10%",
          className: "",
          render: (value: string | null) => (value ? value : "-"),
        },
        {
          title: "Sub Type",
          dataIndex: "accountSubType",
          key: "accountSubType",
          width: "10%",
          className: "",
          render: (value: string | null) => (value ? value : "-"),
        },
        {
          title: "Is Active",
          dataIndex: "isActive",
          key: "isActive",
          width: "4%",
          className: "",
          render: (value: boolean) => (
            <span>{value ? "Active" : "Inactive"}</span>
          ),
        },
        {
          title: "Currency",
          dataIndex: "currency",
          key: "currency",
          width: "8%",
          className: "",
          render: (value: string | null) => (value ? value : "-"),
        },
        {
          title: "Bank Account Number",
          dataIndex: "bankAccountNumber",
          key: "bankAccountNumber",
          width: "12%",
          className: "",
          render: (value: string | null) => (value ? value : "-"),
        },
        {
          title: "Is Bank Account",
          dataIndex: "isBankAccount",
          key: "isBankAccount",
          width: "6%",
          className: "",
          render: (value: boolean) => <span>{value ? "Yes" : "No"}</span>,
        },
        {
          title: "Modified Date",
          dataIndex: "modifiedDate",
          key: "modifiedDate",
          width: "10%",
          className: "",
          render: (value: string | null) => {
            // Use moment to format the date
            const formattedDate = value
              ? moment(value).format("YYYY-MM-DD")
              : "-";
            return <span>{formattedDate}</span>;
          },
          sorter: (a: any, b: any) => {
            if (!a.modifiedDate) return -1;
            if (!b.modifiedDate) return 1;
            return (
              moment(a.modifiedDate).unix() - moment(b.modifiedDate).unix()
            );
          },
        },
      ];
      break;
    default:
      // Default columns if entity type is unknown
      columns = commonColumns;
  }

  return columns;
};
