import styles from './index.module.scss';
// import { MySubscriptionProps } from './types';

const MySubscriptionComponent = () => {

    // const manageSubscriptions = () => {
    //     stateHandler(true);
    // }

    return (
        <div className={styles['subscription-card']}>
            <div className={styles['d-flex']}>
                <div>
                    <h3 className={styles['plan-details-title']}>Plan Details</h3>
                </div>
                <div>
                    <button className={styles['button']}>Manage Subscriptions</button>
                </div>
            </div>
            <hr className={styles['divider-line']} />
            <div className={styles['d-flex']}>
                <div>
                    <h2 className={styles['curr-plan-title']}>Current Plan:</h2><br />
                    <p className={styles['plan-type']}>FREE</p>
                </div>
                <div className={styles['mrg-right']}>
                    <h2 className={styles['curr-plan-title']}>Pricing:</h2><br />
                    <p className={styles['plan-pricing']}>$0<span className={styles['plan-pricing__sub-text']}>/Month</span></p>
                </div>
            </div>
        </div>
    )
}

export default MySubscriptionComponent
