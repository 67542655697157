import ConnectedAppComponent from "components/Global/ConnectedApp";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCompanyDetailsAction } from "redux/action/companyDetails";
import { AppDispatch } from "redux/store";

const ConnectedApp = () => {
  const dispatch = useDispatch<AppDispatch>();
  const nevigate = useNavigate();
  useEffect(() => {
    const connectionType = localStorage.getItem("integration");
    const companyId = localStorage.getItem("t");
    if (!connectionType && !companyId) {
      nevigate("/preview");
    } else {
        dispatch(
          getCompanyDetailsAction({ companyId: localStorage.getItem("t") })
        );
    }
  }, []);
  return <ConnectedAppComponent />;
};

export default ConnectedApp;
