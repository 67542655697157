import { Button, Form, Input } from "antd";
import { FORMDATA } from "constants/Data";
import { FC, useState } from "react";
import styles from "./index.module.scss";
import "./index.scss";
import { SideDrawerBodyProps } from "./types";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import {
  addRoleActionTable,
  cloneRoleActionTable,
  editRoleActionTable,
} from "redux/action/roleTableAction";
import { userCheck } from "redux/slice/userCheckSlice";

const AddRoleBody: FC<SideDrawerBodyProps> = (props) => {
  // Inits
  const {
    closeDrawerByAnimation,
    editSelectedRole,
    copyrole
    // setEditSelectedRole
  } = props;
  const { addRoleFields } = FORMDATA;
  const [form] = Form.useForm();
  const [saveAndAddLoader, setSaveAndAddLoader] = useState(false);
  const { addRoleLoading } = useSelector((state: any) => state?.roleTable);

  const dispatch = useDispatch<AppDispatch>();

  // If form gets successfully submitted
  const onFinish = (values: any, buttonType = "save") => {
    const trimmedRoleName = (values?.roleName || "").trim();
    const trimmedRoleDescription = (values?.roleDescription || "").trim();

    // Assign the trimmed values back to values
    values.roleName = trimmedRoleName;
    values.roleDescription = trimmedRoleDescription;

    if (editSelectedRole) {
      if(!copyrole){
      const data = {
        roleId: editSelectedRole?.id,
        roleName: values?.roleName,
        roleDescription: values?.roleDescription,
      };
      dispatch(editRoleActionTable(data)).then((res: any) => {
        if (res?.payload?.error?.status === 401) {
          const response = {
            isLoggedIn: true,
            isErrorMessage: res?.payload?.error?.message,
          };
          dispatch(userCheck(response));
        } else {
          closeDrawerByAnimation();
        }
      });
    }
    else{
      const data = {
        roleId: editSelectedRole?.id,
        roleName: values?.roleName,
        roleDescription: values?.roleDescription
      };
      dispatch(cloneRoleActionTable(data)).then((res: any) => {
        if (res?.payload?.error?.status === 401) {
          const response = {
            isLoggedIn: true,
            isErrorMessage: res?.payload?.error?.message,
          };
          dispatch(userCheck(response));
        } else {
          closeDrawerByAnimation();
        }
      });
    }
    } else {
      buttonType === "SaveAndAdd" && setSaveAndAddLoader(true);
      dispatch(addRoleActionTable(values)).then((res: any) => {
        if (res?.payload?.error?.status === 401) {
          const response = {
            isLoggedIn: true,
            isErrorMessage: res?.payload?.error?.message,
          };
          dispatch(userCheck(response));
        }
        if (buttonType === "SaveAndAdd" && res?.payload?.statusCode === 201)
          form.resetFields();
        buttonType === "SaveAndAdd" && setSaveAndAddLoader(false);
        if (buttonType === "save" && res?.payload?.statusCode === 201) {
          closeDrawerByAnimation();
        }
      });
    }
  };

  // Handle 'Save and Add' button click
  const saveAndAdd = () => {
    const formValues = form.getFieldsValue();
    form.validateFields();
    if (formValues?.roleName && formValues?.roleDescription)
      onFinish(formValues, "SaveAndAdd");
  };

  // If form submission fails
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={styles["side-drawer-body"]}>
      <Form
        form={form}
        name="basic"
        initialValues={editSelectedRole}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        labelAlign="left"
        className={styles["side-drawer-form"]}
      >
        <div className={styles["side-drawer-form__inputs"]}>
          {addRoleFields.map((singleField, index) => {
            return (
              <div
                key={index}
                className={styles["side-drawer-form__single-input"]}
              >
                <label
                  className={styles["side-drawer-form__single-input--label"]}
                >
                  {singleField?.title}{" "}
                  {singleField?.required && (
                    <span className="required-color">*</span>
                  )}
                </label>
                {singleField.type == "textArea" ? (
                  <Form.Item
                    name={singleField.name}
                    className={`${styles["side-drawer-form__single-input--input"]} text-area-error`}
                    rules={singleField.rules as []}
                  >
                    <textarea
                      className={
                        styles["side-drawer-form__single-input--textarea"]
                      }
                      placeholder={singleField.placeholder}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name={singleField.name}
                    className={styles["side-drawer-form__single-input--input"]}
                    rules={singleField.rules as []}
                  >
                    <Input
                      placeholder={singleField.placeholder}
                      size="large"
                      type={singleField.type}
                    />
                  </Form.Item>
                )}
              </div>
            );
          })}
        </div>
        <div className={styles["side-drawer-form__buttons"]}>
          {!editSelectedRole && (
            <Form.Item>
              <button
                type="button"
                className={`${styles["side-drawer-form__save-and-add"]} ${
                  saveAndAddLoader && "pointer-event-none"
                }`}
                onClick={saveAndAdd}
              >
                {saveAndAddLoader ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                    crossOrigin={
                      process.env.REACT_APP_ENV === "local"
                        ? undefined
                        : "anonymous"
                    }
                    height={40}
                  />
                ) : (
                  "Save And New"
                )}
              </button>
            </Form.Item>
          )}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className={`${
                styles[
                  !editSelectedRole
                    ? "side-drawer-form__save"
                    : "side-drawer-form__save-and-add"
                ]
              } ${
                addRoleLoading && !saveAndAddLoader && "pointer-event-none"
              } `}
            >
              {addRoleLoading && !saveAndAddLoader ? (
                <img
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                  crossOrigin={
                    process.env.REACT_APP_ENV === "local"
                      ? undefined
                      : "anonymous"
                  }
                  height={40}
                />
              ) : (
                "Save"
              )}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="button"
              size="large"
              className={styles["side-drawer-form__cancel"]}
              onClick={() => {
                closeDrawerByAnimation();
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddRoleBody;
