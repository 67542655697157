import { createSlice } from "@reduxjs/toolkit";
import { GetActiveIntegrationAction, GetIntegrationAction, UpdateIntegrationDataAction, UpdateIntegrationStatuAction } from "redux/action/IntegrationAction";

// Define the initial state for the slice
const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

// Create the IntegrationSlice using createSlice
const IntegrationSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    // Handler for setting integration data
    getintegrationHandler: (state: any, action: any) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Handle the pending state of GetIntegrationAction
    builder.addCase(GetIntegrationAction.pending, (state: any) => {
      state.isLoading = true;
    });

    // Handle the fulfilled state of GetIntegrationAction
    builder.addCase(GetIntegrationAction.fulfilled, (state: any, action: any) => {
      state.data = action.payload.data;
      state.isLoading = false;
    });

    // Handle the rejected state of GetIntegrationAction
    builder.addCase(GetIntegrationAction.rejected, (state: any) => {
      state.isLoading = false;
    });

    // Update....

    builder.addCase(UpdateIntegrationDataAction.pending, (state: any) => {
      state.isLoading = true;
    });

    // Handle the fulfilled state of GetIntegrationAction
    builder.addCase(
      UpdateIntegrationDataAction.fulfilled,
      (state: any, action: any) => {
        
        const updatedData = action?.payload?.data?.integration
        state.isLoading = false;

        state.data = state.data.map((integration: any) => {
          if (integration?.id === updatedData.id) {
            return {
              ...integration,
              clientId: updatedData?.clientId || integration?.clientId,
              clientSecret: updatedData?.clientSecret || integration?.clientSecret,
              scope: updatedData?.scope || integration?.scope,
              isOn: updatedData?.isOn ,
              modifiedDate: updatedData?.modifiedDate || integration?.modifiedDate,
            };
          }
          return integration;
        });
      }
    );

    // Handle the rejected state of GetIntegrationAction
    builder.addCase(UpdateIntegrationDataAction.rejected, (state: any) => {
      state.isLoading = false;
    });
  

    builder.addCase(UpdateIntegrationStatuAction.pending, (state: any) => {
      state.isLoading = true;
    });

    // Handle the fulfilled state of GetIntegrationAction
    builder.addCase(
      UpdateIntegrationStatuAction.fulfilled,
      (state: any, action: any) => {
        const updatedData = action?.payload?.data;
       
        state.isLoading = false;

        state.data = state.data.map((integration: any) => {
          if (Number(integration?.id) === Number(updatedData?.id)) {
            return {
              ...integration,
              isOn: updatedData?.isOn,
              modifiedDate:
              updatedData?.modifiedDate || integration?.modifiedDate,
            };
          }
          return integration;
        });
        
      }
    );

    // Handle the rejected state of GetIntegrationAction
    builder.addCase(UpdateIntegrationStatuAction.rejected, (state: any) => {
      state.isLoading = false;
    });

    builder.addCase(GetActiveIntegrationAction.pending, (state: any) => {
      state.isLoading = true;
    });

    // Handle the fulfilled state of GetIntegrationAction
    builder.addCase(
      GetActiveIntegrationAction.fulfilled,
      (state: any, action: any) => {
        state.data = action.payload.data;
        state.isLoading = false;
      }
    );

    // Handle the rejected state of GetIntegrationAction
    builder.addCase(GetActiveIntegrationAction.rejected, (state: any) => {
      state.isLoading = false;
    });

  }
});

export default IntegrationSlice;
