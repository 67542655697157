import React, { useState } from "react";
import TableActionHeader from "../TableActionHeader";
import { Button } from "antd";
import { AddSvg } from "utils/svgs";
import WebhookEventTable from "./Table";
import ConfirmDelete from "../confirmDeleteModel";

const WebhookEvent = () => {
//   const [isAddWebhookModalOpen, setIsAddWebhookModalOpen] =
//     useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editSelectedWebhook, setEditSelectedWebhook] = useState<any>();
  const events = [
    {
      id: 1,
      url: "https://example.com",
      secureToken: "token1",
      eventType: "click",
    },
    {
      id: 2,
      url: "https://example.com/page2",
      secureToken: "token2",
      eventType: "hover",
    },
    {
      id: 3,
      url: "https://example.com/page3",
      secureToken: "token3",
      eventType: "scroll",
    },
  ];

  const handleDeleteModalOk = () => {
    setIsDeleteModalOpen(false);
  };

  // Cancel operation
  const handleDeleteModalCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const deleteHandler = () => {
    console.log("object");
  };
  const showModal = () => {
    setIsDeleteModalOpen(true);
  };
  return (
    <>
      <TableActionHeader title="">
        <Button
          className="save"
        //   onClick={() => {
        //     setIsAddWebhookModalOpen(true);
        //   }}
        >
          <AddSvg />
          Add Webhook
        </Button>
      </TableActionHeader>
      <WebhookEventTable
        webhookDataSource={events}
        setEditSelectedWebhook={setEditSelectedWebhook}
        showModal={showModal}
      />
      {isDeleteModalOpen && (
        <ConfirmDelete
          handleCancel={handleDeleteModalCancel}
          handleOk={handleDeleteModalOk}
          isModalOpen={isDeleteModalOpen}
          deleteHandler={deleteHandler}
          // isLoading={isLoading}
          isAddUserLoading={false}
        />
      )}
      {/* {isAddWebhookModalOpen && (
        <ConfirmDelete
          handleDeleteModalCancel={handleDeleteModalCancel}
          handleDeleteModalOk={handleDeleteModalOk}
          isModalOpen={isAddWebhookModalOpen}
          deleteHandler={deleteHandler}
          // isLoading={isLoading}
          isAddUserLoading={false}
        />
      )} */}
    </>
  );
};

export default WebhookEvent;
