import { Button, Form, Input, Modal, Select,SelectProps} from "antd";
import { FORMDATA } from "constants/Data";
import { FC, useEffect, useState } from "react";
import {CountryList, CountryWiseTimeZones, DepartmentOptions, IndustryOptions} from "utils/staticObject";
import styles from "./index.module.scss";
import { ModalProps } from "./types";

const WelcomeModal: FC<ModalProps> = (props) => {
  const { handleCancel, onSubmit, isModalOpen, loading, companyName } = props;
  const [showOtherIndustryInput, setShowOtherIndustryInput] = useState(false);
  const [showOtherDepartmentInput, setShowOtherDepartmentInput] = useState(false);
  const [otherIndustryValue, setOtherIndustryValue] = useState("");
  const [industryOtherError, setIndustryOtherError] = useState("");
  const [otherDepartmentValue, setOtherDepartmentValue] = useState("");
  const [departmentOtherError, setDepartmentOtherError] = useState("");
  const [countryValue, setCountryValue] = useState("");
  const [timezoneArray, setTimezoneArray] = useState([]);

  const options: SelectProps["options"] = CountryList?.map((d) => ({
    value: d.name,
    label: d.name,
  }));

  const timeZoneOptions: SelectProps["options"] = timezoneArray?.map((d) => ({
    value: d,
    label: d,
  }));

  const optionsIndustry: SelectProps["options"] = IndustryOptions?.map((d) => ({
    value: d.label,
    label: d.value,
  }));

  const optionsDepartment: SelectProps["options"] = DepartmentOptions?.map(
    (d) => ({
      value: d.label,
      label: d.value,
    })
  );

  const industryChangeHandler = (e: any) => {
    if (e.target.value !== "") {
      setOtherIndustryValue(e.target.value);
      setIndustryOtherError("");
    } else {
      setOtherIndustryValue("");
      setIndustryOtherError("Please enter a industry name");
    }
  };

  const departmentChangeHandler = (e: any) => {
    if (e.target.value !== "") {
      setOtherDepartmentValue(e.target.value);
      setDepartmentOtherError("");
    } else {
      setOtherDepartmentValue("");
      setDepartmentOtherError("Please enter a department name");
    }
  };

  const onFinish = (values: any) => {
    const newValues = { ...values };
    if (values?.department === "Department" && otherDepartmentValue === "") {
      setDepartmentOtherError("Please enter a department name");
      return;
    }
    if (values?.industry === "Industry" && otherIndustryValue === "") {
      setIndustryOtherError("Please enter an industry name");
      return;
    }
    // Update values based on conditions
    if (values?.department === "Department") {
      newValues.department = otherDepartmentValue;
    }
    if (values?.industry === "Industry") {
      newValues.industry = otherIndustryValue;
    }
    // Pass the form values to the onSubmit function
    onSubmit(newValues);
  };

  useEffect(() => {
    if (countryValue !== "" && countryValue !== undefined) {
      const val = Object.entries(CountryWiseTimeZones);
      val.forEach(([key, value]) => {
        if (key === countryValue) {
          setTimezoneArray(value as []);
        }
      });
    }
  }, [countryValue]);

  return (
    <>
      <Modal
        title={
        <>
            <p style={{ fontSize: "24px" }}>Welcome to Synctools !</p>
            <p style={{ fontWeight: "lighter", fontSize: "14px", color: "gray" }} >
              This will enhance your synctools experience
            </p>
        </>
        }
        open={isModalOpen}
        closable={false}
        footer={false}
      >
        <Form onFinish={onFinish} name="basic">
          {FORMDATA?.welcomeFields.map((field, index) => (
            <div key={index} className={styles["select-dropdown"]}>
              {/* Render the label based on the field type */}
              {field.name === "country" && (
                <div className={styles["select-dropdown__label-div"]}>
                  <label className={styles["select-dropdown__label"]}>
                    {`Where is `}
                    <span style={{ fontWeight: "900" }}>
                      {companyName}
                    </span>{" "}
                    {`located ?`}
                  </label>
                </div>
              )}
              {field.name !== "country" && (
                <div className={styles["select-dropdown__label-div"]}>
                  <label className={styles["select-dropdown__label"]}>
                    {field.name === "department" ||
                    field.name === "timeZone" ? (
                      field.name === "timeZone" ? (
                        <>
                          {"Select the "}
                          <span style={{ fontWeight: 900 }}>
                            {field.title}
                            {" !"}
                          </span>
                        </>
                      ) : (
                        <span>{field.title}</span>
                      )
                    ) : (
                      <>
                        {field.title}{" "}
                        <span style={{ fontWeight: "900" }}>{companyName}</span>{" "}
                        {" ?"}
                      </>
                    )}
                  </label>
                </div>
              )}
              <Form.Item name={field.name} rules={field.rules as []}>
                {/* Render the input/select based on the field type */}
                {field.name !== "designation" && field.name !== "timeZone" && (
                  <Select
                    size={"large"}
                    showSearch={true}
                    options={
                      field.name === "country"
                        ? options
                        : field.name === "department"
                        ? optionsDepartment
                        : optionsIndustry
                    }
                    placeholder={field.placeHolder}
                    onChange={(value: any, opt: any) => {
                      switch (field.name) {
                        case "industry":
                          if (opt?.label === "Others") {
                            setShowOtherIndustryInput(true);
                          } else {
                            setShowOtherIndustryInput(false);
                          }
                          break;
                        case "department":
                          if (opt?.label === "Others") {
                            setShowOtherDepartmentInput(true);
                          } else {
                            setShowOtherDepartmentInput(false);
                          }
                          break;
                        case "country":
                          setCountryValue(opt?.value);
                          break;
                        default:
                          break;
                      }
                    }}
                  />
                )}
                {field.name === "designation" && (
                  <Input
                    placeholder={field.placeHolder}
                    size="large"
                    className={styles["input-icon__form--input"]}
                    type={field.type}
                  />
                )}
                {field.name === "timeZone" && (
                  <Select
                    size={"large"}
                    showSearch={true}
                    options={timeZoneOptions}
                    placeholder={field.placeHolder}
                  />
                )}
              </Form.Item>
              {/* Render the input for other industry/department if necessary */}
              {showOtherDepartmentInput && field.name === "department" && (
                <>
                  <div style={{ marginTop: 8 }}>
                    <label className={styles["input-icon__title--label"]}>
                      <span>Please specify</span>
                      <span className="required-color">*</span>
                    </label>
                  </div>
                  <Input
                    placeholder="Enter Other Department"
                    size="large"
                    style={{ marginTop: 9 }}
                    className={styles["input-icon__form--input"]}
                    onChange={departmentChangeHandler}
                  />
                  <span className={styles["other-erorr"]}>
                    {departmentOtherError}
                  </span>
                </>
              )}
              {showOtherIndustryInput && field.name === "industry" && (
                <>
                  <div style={{ marginTop: 8 }}>
                    <label className={styles["input-icon__title--label"]}>
                      <span>Please specify</span>
                      <span className="required-color">*</span>
                    </label>
                  </div>
                  <Input
                    placeholder="Enter Other Industry"
                    size="large"
                    style={{ marginTop: 9 }}
                    className={styles["input-icon__form--input"]}
                    onChange={industryChangeHandler}
                  />
                  <span className={styles["other-erorr"]}>
                    {industryOtherError}
                  </span>
                </>
              )}
            </div>
          ))}
          <div className={styles["form__btn"]}>
            <Button
              key="submit"
              className={styles["form__btn--save"]}
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Submit
            </Button>
            <Button
              key="back"
              className={styles["form__btn--cancel"]}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default WelcomeModal;
