import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteApiWithData, getApi, postApi } from "redux/apis";

export const createApiKey = createAsyncThunk(
    "apikey/create",
    async (data: any, { rejectWithValue }) => {

        try {
            const response = await postApi(
                `/apiKey/create`,
                data,
            );
            return response?.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const getApiKey = createAsyncThunk(
    "apikey/get",
    async (_, { rejectWithValue }) => {

        try {
            const response = await getApi(`/apiKey/get`);
            return response?.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);

export const deleteApiKey = createAsyncThunk(
    "apikey/delete",
    async (data: any, { rejectWithValue }) => {

        try {
            const response = await deleteApiWithData(`/apiKey/delete`,data);
            return response?.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);

