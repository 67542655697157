import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import styles from "./index.module.scss";
import { postApi } from "redux/apis";
import { v4 as uuidv4 } from "uuid";
import { toastText } from "utils/utils";

const EcommerceCallbackComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const location = useLocation();

  // Define a function to handle API calls and error handling
  const handleApiCall = async (
    apiEndpoint: any,
    data: any,
    successMessage: any
  ) => {
    try {
      const res = await postApi(apiEndpoint, data);
      if (res?.status === 200) {
        // Store the callback data in local storage
        localStorage.setItem(
          "callBackUpdatedData",
          JSON.stringify(callbackData)
        );
        toastText(successMessage, "success");
      } else {
        throw new Error("Fail to Connect");
      }
    } catch (error: any) {
      toastText(error?.response?.data?.error?.message, "error");
      localStorage.setItem("callBackUpdatedData", JSON.stringify({ ...callbackData, error: true, message: error?.response?.data?.error?.message }));
    }
    // Close the window after API call
    window.close();
  };

  // Define an object to store callback-related data
  const callbackData = {
    uuid: uuidv4(),
    data: true,
    error: false,

  };

  const getTokenInfo = async () => {
    setIsLoading(true);

    if (location.pathname.startsWith("/shopify/callback")) {
      // Handle Shopify callback

      const code = queryParams.get("code");
      const shop = queryParams.get("shop");
      const apiEndpoint = `/shopify/callback?code=${code}&shop=${shop}`;
      await handleApiCall(apiEndpoint, {}, "Connection Successful");

    }

    if (location.pathname.startsWith("/bc/oauth")) {
      // Handle Bigcommerce callback
      const account_uuid = queryParams.get("account_uuid");
      const code = queryParams.get("code");
      const apiEndpoint = `/bigcommerce/callback?code=${code}&accountuuid=${account_uuid}`;
      await handleApiCall(apiEndpoint, {}, "Connection Successful");

    }
  };

  useEffect(() => {
    getTokenInfo();
  }, []);

  return (
    <div className={styles["main-container"]}>
      {isLoading && (
        <img
          src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading.gif`}
          height={60}
          crossOrigin={
            process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
          }
        />
      )}
    </div>
  );
};

export default EcommerceCallbackComponent;
