import { createSlice } from "@reduxjs/toolkit";
import { getApiLogs } from "redux/action/apiLogsAction";

// Define the initial state for the slice
const initialState: any = {
    data: null,
    deleteApiData: null,
    isLoading: false,
    error: null,
};

// Create the Apikey
const ApiLogsSlice = createSlice({
    name: 'apilogs',
    initialState,
    reducers: {},
    extraReducers: (builder) => {


        // Get ApiKeys
        builder.addCase(getApiLogs.pending, (state: any) => {
            state.isLoading = true;
        });

        builder.addCase(getApiLogs.fulfilled, (state: any, action: any) => {
            state.data = action.payload;
            state.isLoading = false;
        });

        builder.addCase(getApiLogs.rejected, (state: any, action: any) => {
            state.data = action.payload;
            state.isLoading = false;
        });
    }
});


export default ApiLogsSlice;
