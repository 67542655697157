import { Col, Image, Row, Space } from "antd";
import styles from "./index.module.scss";
import { Typography } from "antd";
import IntegrationModal from "../IntegrationModel";
import { useState } from "react";
import { FORMDATA } from "constants/Data";
import { useSelector } from "react-redux";
import { postApi } from "redux/apis";
import { UUID, exportXmlFile, toastText } from "utils/utils";
import { v4 as uuidv4 } from "uuid";

const QuickbookDesktop = () => {
  const { Title } = Typography;

  // State and variables
  const connectionID = useSelector((state: any) => state?.qbdUser?.data?.id);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ButtonLabel, SetButtonLabel] = useState(false);
  const [uniqueUsername, SetuniqueUsername] = useState("");

  // Handle cancel action for IntegrationModal
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Handle submit action for IntegrationModal
  const handleOk = () => {
    setIsModalOpen(false);
  };

  // Show IntegrationModal
  const showModal = () => {
    setIsModalOpen(true);
    const val = generateUniqueUsername();
    SetuniqueUsername(val);
  };

  // Download QWC (QuickBooks Configuration) file
  const downloadQWC = async () => {
    if (connectionID) {
      try {
        postApi(`/qbd/downloadQWC`, { id: connectionID }).then(
          async (res: any) => {
            if (res.status === 200) {
              exportXmlFile(res?.data?.data, `QBDIntegrationConfig_${UUID()}`);
              toastText("Downloaded Successfully", "success");
              await handleOk(); // Handle submission logic
            } else {
              await handleCancel();
              toastText("Failed to download", "error");
            }
          }
        );
      } catch (error) {
        await handleCancel();
        toastText("Fail to download", "error");
      }
    }
  };
  return (
    <div className={styles["main"]}>
      <Row>
        <Space direction="vertical" size={20}>
          <Col span={24}>
            <Image
              src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/Accounting/quickbooks-desktop.png`}
              preview={false}
              height={50}
              crossOrigin={
                process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
              }
            />
          </Col>
          <Col span={24}>
            <Title level={4} style={{ color: "#0b78c2" }}>
              Quickbooks Desktop Configuration Steps
            </Title>
          </Col>
          <Col span={24}>
            <Title level={5}>
              Step 1: Please Start Quickbook Desktop Application.
            </Title>
            <Title level={5}>
              Step 2: Select Your Company File and open (if you dont have any
              company then create by clicking on Create a new company)
            </Title>
            <Title level={5}>
              Step 3: Once Start your Quickbook with the company then open Web
              Connector for connecting with the Fintech app(You can download the
              web connector from here : Download.)
            </Title>
            <Title level={5}>
              Step 4: Once download completed install the web connector and run
              it.
            </Title>
            <Title level={5}>
              Step 5: Now Click on Set Password(You can use this password for
              web connector configuration as Step-13)
            </Title>
            <Title level={5}>
              <button
                className={`btn-blue`}
                onClick={() => {
                  showModal();
                }}
              >
                Set Password
              </button>
            </Title>
            <Title level={5}>
              Step 6: Now you need to download the Quickbook Desktop
              Configuation file from clicking on DOWNLOAD CONDFIGURATION FILE
              button.
            </Title>
            <Title level={5}>
              <button
                className={`btn-blue ${!connectionID ? "btn-disabled" : ""}`}
                onClick={() => {
                  downloadQWC();
                }}
                disabled={!connectionID}
              >
                Download Configuration File
              </button>
            </Title>
            <Title level={5}>
              Step 7: Now you open web connector and click on Add application
              button.
            </Title>
            <Title level={5}>Step 8: Go to File Save Path.</Title>
            <Title level={5}>
              Step 9: Select the download file and click on Open . Note : You
              must need to start QuickBooks desktop and open your company where
              you need to sync data.
            </Title>
            <Title level={5}>
              Step 10: Now you need permission for a certification of Encyption
              Connection . please allow For Certification.
            </Title>
            <Title level={5}>
              Step 11: One more Permission certificate of QuickBook connection
              .please allow For Certification.
            </Title>

            <Title level={5}>
              Step 12: Now you need to type the password into web connector ,
              this password you find from fintech app Connection Wizard.
            </Title>
            <Title level={5}>
              Step 13: Now your Web Connector is ready to serve data
            </Title>
          </Col>
        </Space>
      </Row>
      <IntegrationModal
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalOpen={isModalOpen}
        formData={FORMDATA.qbdIntegrationFields}
        titleKey={"QuickbooksDesktop"}
        SetButtonLabel={SetButtonLabel}
        ButtonLabel={ButtonLabel}
        softwareType="Accounting"
        uniqueUsername={uniqueUsername}
      />
    </div>
  );
};

export default QuickbookDesktop;

function generateUniqueUsername() {
  const timestamp = Date.now(); // Use a timestamp as a unique identifier
  const uniqueIdentifier = uuidv4();

  const uniqueUsername = `${timestamp}-${uniqueIdentifier}`;
  return uniqueUsername;
}
