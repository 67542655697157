import { configureStore } from '@reduxjs/toolkit';
import loginSlice from './slice/loginSlice';
import profileSlice from './slice/profileSlice';
import companySlice from './slice/companySlice';
import userSlice from './slice/userSlice';
import roleSlice from './slice/roleSlice';
import permissionsSlice from './slice/permissionSlice';
import RoleTableSlice from './slice/roleTableSlice';
// import ConnectionSlice from './slice/connectionSlice';
import ActiveConnectionSlice from './slice/activeConnectionslice';
import IntegrationSlice from './slice/integrationSlice';
import userCheckSlice from './slice/userCheckSlice';
import QbdUserSlice from './slice/qbdUserSlice';
import NetSuiteSlice from './slice/netSuiteSlice';
import AccountSlice from './slice/accountSlice';
import emailVerificationSlice from './slice/emailVerificationSlice';
import codeVerificationSlice from './slice/codeVerificationSlice';
import ApikeySlice from './slice/apikeySlice';
import ApiLogsSlice from './slice/apiLogsSlice';
import CustomerSlice from './slice/customerSlice';
import VendorSlice from './slice/vendorSlice';
const store = configureStore({
	reducer: {
		auth: loginSlice.reducer,
		userProfile: profileSlice.reducer,
		companies: companySlice.reducer,
		users: userSlice.reducer,
		roles: roleSlice.reducer,
		permissions: permissionsSlice.reducer,
		roleTable: RoleTableSlice.reducer,
		// Connection: ConnectionSlice.reducer,
		ActiveServices: ActiveConnectionSlice.reducer,
		Integration: IntegrationSlice.reducer,
		userCheck: userCheckSlice.reducer,
		qbdUser: QbdUserSlice.reducer,
		netSuite: NetSuiteSlice.reducer,
		accounts: AccountSlice.reducer,
		customers:CustomerSlice.reducer,
		vendors : VendorSlice.reducer,
		emailVerification: emailVerificationSlice.reducer,
		codeVerification: codeVerificationSlice.reducer,
		apikeys: ApikeySlice.reducer,
		apilogs: ApiLogsSlice.reducer
	},
});

export default store;
export type AppDispatch = typeof store.dispatch;
