
import { FC, useEffect, useState } from "react";
import { DynamicAPIKeysTableProps } from "./types";
import { Button, Space, Table } from "antd";
import { AddSvg, DeleteActionSvg, EditActionSvg } from "utils/svgs";
import "./index.scss";
import { formatDate } from "utils/utils";
import { CopyOutlined } from "@ant-design/icons";
import CreateAPIKeyModal from "../Modal";
import { TableActionHeader } from "components/Global";
import ConfirmDelete from "components/Global/confirmDeleteModel";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { deleteApiKey, getApiKey } from "redux/action/apikeyAction";

const DynamicAPIKeysTable: FC<DynamicAPIKeysTableProps> = (props) => {
    const { apiKeyDataSource, currentPage, PageSize, modifyPageSize, totalRecords, isLoading} = props;
    const { Column } = Table;

    const [formatData, setFormatData] = useState(apiKeyDataSource);
    const [editFormValue, setEditFormValue] = useState({});
    const [isApiKeyModalOpen, setApiKeyIsModalOpen] = useState(false);
    const [isDeleteModelOpen, setIsDeleteModelOPen] = useState(false);
    const [isDeleteModalLoading, setDeleteModalIsLoading] = useState(false);

    const dispatch = useDispatch<AppDispatch>()

    const openModal = () => {
        // Clear editFormValue to ensure empty form for creating new API key
        setEditFormValue({});
        setApiKeyIsModalOpen(true);
    };

    const handleClose = () => {
        setApiKeyIsModalOpen(false);
    };

    const editAPIKeyHandler = (data: any) => {
        setEditFormValue(data);
        setApiKeyIsModalOpen(true);
    };

    const deleteAPIKeyDataHandler = (data: any) => {
        setEditFormValue(data);
        setIsDeleteModelOPen(true);
    };

    const handleCopy = (values: string) => {
        navigator.clipboard.writeText(values);
    };


    const handleCancel = () => {
        setIsDeleteModelOPen(false);
    }

    const deleteHandler = () => {
        setDeleteModalIsLoading(true)
        if (editFormValue) {
            dispatch(deleteApiKey(editFormValue)).then(() => {
                setDeleteModalIsLoading(false)
                setIsDeleteModelOPen(false);
                setEditFormValue({});
                dispatch(getApiKey())
            });
        }
    };

    useEffect(() => {
        if (Array.isArray(apiKeyDataSource)) {
            const data = apiKeyDataSource?.map((item: any) => ({
                id: item?.id,
                userId: item?.userId,
                accessToken: item?.accessToken,
                uniqueId: item?.uniqueId,
                apiKey: item?.apiKey,
                apikeyName: item?.apikeyName,
                isActive: item?.isActive,
                createdAt: formatDate(item?.createdAt),
            }));
            setFormatData(data);
        }
    }, [apiKeyDataSource]);

    return (
      <>
        <TableActionHeader title={"API Keys"}>
          <div className={"mt-1 fr"}>
            <button className={`btn-blue`} onClick={openModal}>
              <AddSvg />
              <p>Create API Key</p>
            </button>
          </div>
        </TableActionHeader>
        <div className="subText__mt">
          <p className={"subText"}>
            These keys are essential for authenticating API requests. Keep them
            secure and never share them in plaintext with anyone.
            <br/> If
            compromised, please delete them and generate new ones.
          </p>
        </div>

            <div className={'dynamic-table apikeys'}>
                <Table
                    dataSource={formatData}
                    scroll={{ y: "calc(77vh - 133px)" }}
                    pagination={{
                        total: totalRecords,
                        current: currentPage,
                        // onChange: paginationChangeHandler,
                        pageSize: PageSize,
                        // pageSizeOptions: pageSizeOptionsPaging,
                        showSizeChanger: true,
                        onShowSizeChange: modifyPageSize,
                    }}
                    loading={isLoading}
                    className="table-global"
                >
                    <Column
                        title="Name"
                        dataIndex="apikeyName"
                        key="apikeyName"
                        width={"7%"}
                        className="bg-white"
                    />
                    <Column
                        title="Authorization Header"
                        dataIndex="accessToken"
                        key="accessToken"
                        className="bg-white"
                        width={"23%"}

                        render={(values: string) => {
                            return <>
                                <div className={"accessTokenDiv"} >
                                    <div>{`Basic **************************************`}</div>
                                    <Button className={'accessTokenDiv__copyBtn'} onClick={() => handleCopy(values)} >
                                        <CopyOutlined />
                                    </Button>
                                </div>
                            </>
                        }}
                    />
                    <Column
                        title="API Key"
                        dataIndex="apiKey"
                        key="apiKey"
                        className="bg-white"
                        width={"20%"}
                        render={(values: any) => {
                            return <>
                                <div className={"accessTokenDiv"} >
                                    <div>{`**************************************`}</div>
                                    <Button className={'accessTokenDiv__copyBtn'} onClick={() => handleCopy(values)} >
                                        <CopyOutlined />
                                    </Button>
                                </div>
                            </>
                        }}
                    />
                    <Column
                        title="Created At"
                        dataIndex="createdAt"
                        key="createdAt"
                        className="bg-white"
                        width={"14%"}
                    />
                    <Column
                        title="Action"
                        dataIndex="action"
                        key="action"
                        className="bg-white"
                        width={"10%"}
                        render={(values: any, data: any) => {
                            return (
                                <>
                                    <Space size={20}>
                                        <div
                                            className="cursor-pointer flex align-center justify-center"
                                            onClick={() => editAPIKeyHandler(data)}
                                            style={{ marginRight: "2rem" }}
                                        >
                                            <EditActionSvg />
                                        </div>

                                        <div
                                            style={{ marginRight: "2rem" }}
                                            className="cursor-pointer flex align-center justify-center"
                                            onClick={() => {
                                                deleteAPIKeyDataHandler(data);
                                            }}
                                        >
                                            <DeleteActionSvg />
                                        </div>
                                    </Space>
                                </>
                            )
                            //
                        }}
                    />
                </Table>

                <CreateAPIKeyModal isModalOpen={isApiKeyModalOpen} editFormValue={editFormValue}  handleClose={handleClose} />



                <ConfirmDelete
                    handleCancel={handleCancel}
                    handleOk={handleCancel}
                    isModalOpen={isDeleteModelOpen}
                    deleteHandler={deleteHandler}
                    isAddUserLoading={isDeleteModalLoading}
                />
            </div>
        </>
    );
};

export default DynamicAPIKeysTable;


