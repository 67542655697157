import { Image } from "antd";
import WebhookEvent from "components/Global/WebhookEvenet";
import { FaRegBuilding } from "react-icons/fa";
// import { RxDashboard } from "react-icons/rx";
import {
  Apikey,
  CompanySvg,
  ConfigurationSvg,
  ConnectedAppsSvg,
  Country,
  Designation,
  EmailSvg,
  Industry,
  Logs,
  PasswordSvg,
  PhoneSvg,
  Playground,
  RoleSvg,
  StoreSvg,
  SubscriptionSvg,
  UrlSvg,
  UserSvg,
  UsersSvg,
  Webhook,
} from "utils/svgs";

const phoneNumberValidator = (_: any, value: any) => {
  // Modify this regex pattern to match the format of phone numbers you want to validate
  const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  if (!value || value.match(phoneRegex)) {
    return Promise.resolve();
  }

  return Promise.reject(new Error("Please enter a valid phone number"));
};

export const PermissionsNames = {
  ManageUser: "Manage User",
  AccessAllIntegrations: "Access to All Integrations",
  ConfigureIntegrationSettings: "Configure Integration Settings",
  ManageSubscriptionDetails: "Manage Subscription Details",
  PerformFinancialAnalysis: "Perform Financial Analysis",
  PostingEntriesTransactions: "Posting of Entries/transactions",
  ManageAPIKeys: "API Key management",
  CommunicateWithUsers: "Communicate with users",
  ManageAPIDocumentation: "API Documentation",
  ManageWebhooks: "Webhooks management",
  OAuth2Integration: "OAuth2 Integration",
  ManageRoles: "Manage Roles",
  Profile: "Profile",
};

export const SidebarMenuIcons = {
  FinancialAnalysis:
    "https://staging.app.synctools.io/assets/Sidebar/NotSelected/DashBoard.png",
  DeveloperModule: "/assets/images/developer-module.png",
  APIKeys: "/assets/images/key.png",
  Webhooks: "/assets/images/webhooks-logo.svg",
  Logs: <Logs />,
};

export const FORMDATA = {
  loginFields: [
    {
      title: "Email Address",
      id: "email",
      type: "text",
      name: "email",
      svg: <EmailSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your email address",
          validateTrigger: "onChange",
        },
        {
          type: "email",
          message: "Please enter valid e-mail",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  registerFields: [
    {
      title: "Full Name",
      id: "fullName",
      type: "text",
      name: "fullName",
      svg: <UserSvg />,
      placeHolder: "Enter your Full Name",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your full name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Phone Number",
      id: "phone",
      type: "text",
      name: "phone",
      svg: <PhoneSvg />,
      placeHolder: "Enter your Phone Number",
      required: true,
      rules: [
        {
          required: true,
          message: "Please input your phone number!",
          validationTrigger: "onBlur",
        },
        ({ getFieldValue }: any) => ({
          validator() {
            const re = /^(\+\d{1,3}[- ]?)?\d{10}$/;
            if (getFieldValue("phone") !== undefined) {
              if (re.test(getFieldValue("phone"))) {
                return Promise.resolve();
              } else {
                return Promise.reject(new Error("Please enter phone number"));
              }
            }
            return Promise.reject();
          },
          validateTrigger: "onSubmit",
        }),
      ],
    },

    {
      title: "Company Name",
      id: "companyName",
      type: "text",
      name: "companyName",
      svg: <CompanySvg />,
      placeHolder: "Enter your Company Name",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your company name",
          validateTrigger: "onChange",
        },
      ],
    },

    {
      title: "Email Address",
      id: "email",
      type: "text",
      name: "email",
      svg: <EmailSvg />,
      placeHolder: "Enter your Email Name",
      required: true,
      rules: [],
    },
    {
      title: "Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "Enter your Password",
      required: true,
      rules: [
        {
          required: true,
          message: "Please Enter your Password ",
          validationTrigger: "onBlur",
        },
        ({ getFieldValue }: any) => ({
          validator() {
            const re =
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
            if (getFieldValue("password") !== undefined) {
              if (re.test(getFieldValue("password"))) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  new Error(
                    "Please enter a password that is 8 characters long and contains at least 1 digit, 1 lowercase letter, and 1 uppercase letter"
                  )
                );
              }
            }
            return Promise.reject();
          },
          validateTrigger: "onSubmit",
        }),
      ],
    },
    {
      title: "Re Enter Password",
      id: "confirmPassword",
      type: "password",
      name: "confirmPassword",
      svg: <PasswordSvg />,
      placeHolder: "Enter your Confirm Password",
      required: true,
      rules: [
        {
          required: true,
          message: "Please Re Enter your Password ",
          validationTrigger: "onBlur",
        },
        ({ getFieldValue }: any) => ({
          validator(_: any, value: any) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("Passwords do not match!"));
          },
          validateTrigger: "onSubmit",
        }),
      ],
    },
  ],
  welcomeFields: [
    {
      title: "Country",
      id: "Country",
      type: "select",
      name: "country",
      svg: <Country />,
      placeHolder: "Enter your country name",
      rules: [
        {
          required: true,
          message: "Please select a country from the drop down",
        },
      ],
    },
    {
      title: "Time Zone",
      id: "TimeZone",
      type: "select",
      name: "timeZone",
      svg: <Country />,
      placeHolder: "Enter your timezone",
      rules: [
        {
          required: true,
          message: "Please select the timeZone from the drop down",
        },
      ],
    },
    {
      title: "What department do you work in ?",
      id: "department",
      type: "select",
      name: "department",
      svg: <FaRegBuilding className="register-icon" size={20} />,
      placeHolder: "Enter your department.",
      rules: [
        {
          required: true,
          message: "Please add your department",
        },
      ],
    },

    {
      title: `What's your role at`,
      id: "designation",
      type: "text",
      name: "designation",
      svg: <Designation />,

      placeHolder: "Enter your role",
      rules: [
        {
          required: true,
          message: "Please add your role",
        },
      ],
    },

    {
      title: "To which industry does the",
      id: "industry",
      type: "select",
      name: "industry",
      svg: <Industry />,
      placeHolder: "Enter your  industry name",
      rules: [
        {
          required: true,
          message: "Please select a industry from the drop down",
        },
      ],
    },
  ],
  resetPassword: [
    {
      title: "New Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Confirm Password",
      id: "confirmPassword",
      type: "password",
      name: "confirmPassword",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password again",
          validationTrigger: "onChange",
        },
        ({ getFieldValue }: any) => ({
          validator(_: any, value: any) {
            if (!value || getFieldValue("password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("Passwords do not match"));
          },
          validateTrigger: "onChange",
        }),
      ],
    },
  ],
  forgotPassword: [
    {
      title: "Email Address",
      id: "email",
      type: "text",
      name: "email",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your email address",
          validateTrigger: "onChange",
        },
        {
          type: "email",
          message: "Please enter valid e-mail",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  settingsMenuItems: [
    {
      key: "users",
      icon: <UsersSvg />,
      label: "Users",
      roleName: PermissionsNames.ManageUser,
    },
    {
      key: "roles",
      icon: <RoleSvg />,
      label: "Roles",
      roleName: PermissionsNames.ManageRoles,
    },
    {
      key: "subscription",
      icon: <SubscriptionSvg />,
      label: "Subscriptions",
      roleName: PermissionsNames.ManageSubscriptionDetails,
    },
    // {
    //   key: "configurations",
    //   icon: <Playground />,
    //   label: "Playground",
    //   roleName: PermissionsNames.ConfigureIntegrationSettings,
    // },
    // {<Image src="/public/assets/Accounting/QBO.png" />
    //   key: "my-profile",
    //   icon: <ConfigurationsSvg />,
    //   label: "My Profile",
    //   roleName: PermissionsNames.Profile,
    // },
  ],
  newIntegrationMenuItems: [
    // {
    //   key: "connectedapps",
    //   label: "Connected Apps",
    // },
    {
      key: "accounting",
      label: "Accounting",
    },
    // {
    //   key: "crm",
    //   label: "CRM",
    // },
    {
      key: "ecommerce",
      label: "Ecommerce",
    },
    {
      key: "erp",
      label: "ERP",
    },
    // {
    //   key: "marketplace",
    //   label: "Marketplace",
    // },
    // {
    //   key: "paymentGateway",
    //   label: "Payment Gateway",
    // },
  ],
  // previewIntegrationMenuItems: [
  //   {
  //     key: "accounting",
  //     label: "Accounting",
  //   },
  //   // {
  //   //   key: "crm",
  //   //   label: "CRM",
  //   // },
  //   {
  //     key: "ecommerce",
  //     label: "Ecommerce",
  //   },
  //   {
  //     key: "erp",
  //     label: "ERP",
  //   },
  //   // {
  //   //   key: "marketplace",
  //   //   label: "Marketplace",
  //   // },
  //   // {
  //   //   key: "paymentGateway",
  //   //   label: "Payment Gateway",
  //   // },
  // ],
  pageMenuItems: [
    {
      key: "dashboard",
      icon: (
        <Image
          src={SidebarMenuIcons.FinancialAnalysis}
          preview={false}
          alt="Dashboard"
        />
      ),
      label: "Dashboard",
      roleName: PermissionsNames.PerformFinancialAnalysis,
    },
    // {
    //   key: "integrations",
    //   icon: <IntegrationsSvg />,
    //   label: "Integrations",
    //   roleName: PermissionsNames.AccessAllIntegrations,
    //   children: [
        {
          key: "configuration",
          label: "Configuration",
          icon: <ConfigurationSvg />,
          roleName: PermissionsNames.AccessAllIntegrations,
        },
        {
          key: "connectedapps",
          label: "Connected Companies",
          icon: <ConnectedAppsSvg />,
          roleName: PermissionsNames.AccessAllIntegrations,
        },
    //   ],
    // },
    // {
    //   key: "performfinancialanalysis",
    //   icon: (
    //     <Image
    //       src={SidebarMenuIcons.FinancialAnalysis}
    //       preview={false}
    //       alt="Dashboard"
    //     />
    //   ),
    //   label: "Financial Analysis",
    //   roleName: PermissionsNames.PerformFinancialAnalysis,
    // },

    {
      key: "developer",
      icon: (
        <Image
          src={SidebarMenuIcons.DeveloperModule}
          preview={false}
          alt="Developer"
        />
      ),
      label: "Developer",
      roleName: PermissionsNames.AccessAllIntegrations,
      children: [
        {
          key: "api-keys",
          label: "API Keys",
          icon: <Apikey />,
        },
        {
          key: "webhooks",
          label: "Webhooks",
          icon: <Webhook />,
        },
        {
          key: "logs",
          label: "Logs",
          icon: <Logs />,
        },
      ],
    },
    {
      key: "playground",
      icon: <Playground />,
      label: "Playground",
      roleName: PermissionsNames.ConfigureIntegrationSettings,
    },
    // {
    //   key: "roles",
    //   label: "Employee Costs",
    //   roleName: PermissionsNames.ManageAPIKeys,
    // },
    // {
    //   key: "timeActivity",
    //   label: "Time Activity",
    //   roleName: PermissionsNames.ManageAPIKeys,
    // },
    // {
    //   key: "journalEntries",
    //   label: "Journal Entries",
    //   roleName: PermissionsNames.ManageAPIKeys,
    // },
    // {
    //   key: "reports",
    //   label: "Reports",
    //   roleName: PermissionsNames.ManageAPIKeys,
    // },
  ],
  webhooksTabs: [
    {
      key: "1",
      label: "Security",
      children: "Security",
    },
    {
      key: "2",
      label: "Event",
      children: <WebhookEvent />,
    },
  ],
  addUserFields: [
    {
      title: "Full Name",
      id: "fullName",
      type: "text",
      name: "fullName",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your full name",
          validateTrigger: "onChange",
        },
        {
          max: 30,
          message: "Full name length must be less than 30 characters",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Select Role",
      id: "roleName",
      type: "text",
      name: "roleName",
      defaultValue: "",
      placeholder: "Enter role name",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please select role",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Email Address",
      id: "email",
      type: "text",
      name: "email",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter email address",
          validateTrigger: "onChange",
        },
        {
          type: "email",
          message: "Please enter valid email address",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Phone Number",
      id: "phone",
      type: "text",
      name: "phone",
      placeHolder: "",
      required: true,
      rules: [{ validator: phoneNumberValidator, validateTrigger: "onChange" }],
    },
  ],
  addRoleFields: [
    {
      title: "Role Name",
      id: "roleName",
      type: "text",
      name: "roleName",
      defaultValue: "",
      placeholder: "Enter role name",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter role name",
          validateTrigger: "onChange",
        },
        {
          max: 50,
          message: "Role name length must be less than 50 characters",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Description",
      id: "roleDescription",
      type: "textArea",
      name: "roleDescription",
      defaultValue: "",
      placeholder:
        "Please enter details about the access and permissions associated with this role",
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter role description",
          validateTrigger: "onSubmit",
        },
        {
          max: 200,
          message: "Role description length must be less than 200 characters",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  sageIntegrationFields: [
    {
      title: "Sender Id",
      id: "senderId",
      type: "text",
      name: "senderId",
      svg: <UserSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your senderId",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Sender Password",
      id: "senderPassword",
      type: "password",
      name: "senderPassword",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Sender Password",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "User Id",
      id: "userId",
      type: "text",
      name: "userId",
      svg: <UserSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your userId",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "User Password",
      id: "userPassword",
      type: "password",
      name: "userPassword",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your User Password",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Name",
      id: "Company Name",
      type: "text",
      name: "companyName",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Company Name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Id",
      id: "companyId",
      type: "text",
      name: "companyId",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your companyId",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  wooIntegrationFields: [
    {
      title: "Base Url",
      id: "baseUrl",
      type: "text",
      name: "baseUrl",
      svg: <UrlSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Base URL",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Consumer Key",
      id: "Consumer Key",
      type: "password",
      name: "key",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Consumer Key",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Consumer Secret",
      id: "Consumer Secret",
      type: "password",
      name: "secret",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Consumer Secret",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Name",
      id: "Company Name",
      type: "text",
      name: "companyName",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Company Name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Id",
      id: "companyId",
      type: "text",
      name: "companyId",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your companyId",
          validateTrigger: "onChange",
        },
      ],
    },
  ],

  clearBookIntegrationFields: [
    {
      title: "API Key",
      id: "API Key",
      type: "password",
      name: "secret",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your API Key",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Name",
      id: "Company Name",
      type: "text",
      name: "companyName",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Company Name",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  shopifyIntegrationFields: [
    {
      title: "Store Name",
      id: "store",
      type: "shopifyStoretextField",
      name: "store",
      svg: <StoreSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Store Name",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  netsuiteIntegrationFields: [
    {
      title: "Consumer Key",
      id: "consumerKey",
      type: "text",
      name: "consumerKey",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Consumer Key",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Consumer Secret",
      id: "consumerSecret",
      type: "text",
      name: "consumerSecret",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Consumer Secret",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Access Token",
      id: "accessToken",
      type: "text",
      name: "accessToken",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Access Token",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Token Secret",
      id: "tokenSecret",
      type: "text",
      name: "tokenSecret",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Token Secret",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Account Id",
      id: "accountId",
      type: "text",
      name: "accountId",
      svg: <UserSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Account Id",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  qbdIntegrationFields: [
    {
      title: "User Name",
      id: "username",
      type: "qbd",
      name: "username",
      svg: <UserSvg />,
      placeHolder: "",
    },
    {
      title: "Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password",
          validateTrigger: "onChange",
        },
        {
          min: 6,
          message: "Password must be at least 6 characters long",
        },
      ],
    },
  ],
  authorizeNetIntegrationFields: [
    {
      title: "API login ID",
      id: "apiloginid",
      type: "text",
      name: "apiLoginId",
      svg: <UserSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your API Login ID",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Transaction Key",
      id: "transactionkey",
      type: "text",
      name: "transactionKey",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your transaction Key",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Company Name",
      id: "companyName",
      type: "text",
      name: "companyName",
      svg: <CompanySvg />,
      placeHolder: "",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your companyName",
          validateTrigger: "onChange",
        },
      ],
    },
    // {
    //   title: "Company ID",
    //   id: "companyID",
    //   type: "text",
    //   name: "companyID",
    //   svg: "",
    //   placeHolder: "",
    //   required: true,
    //   rules: [
    //     {
    //       required: true,
    //       message: "Please enter your companyID",
    //       validateTrigger: "onChange",
    //     },
    //   ],
    // },
  ],
  magentoIntegrationFields: [
    {
      title: "Store url",
      id: "magentoStoreUrl",
      type: "magentoStoretextField",
      name: "magentostore",
      svg: <StoreSvg />,
      placeHolder: "Please enter store name",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your Store Name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "User Name",
      id: "username",
      type: "text",
      name: "username",
      svg: <UserSvg />,
      placeHolder: "Please enter user name",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your user name",
          validateTrigger: "onChange",
        },
      ],
    },
    {
      title: "Password",
      id: "password",
      type: "password",
      name: "password",
      svg: <PasswordSvg />,
      placeHolder: "Please enter password",
      required: true,
      rules: [
        {
          required: true,
          message: "Please enter your password",
          validateTrigger: "onChange",
        },
      ],
    },
  ],
  configurationFields: [
    {
      title: "Client Id",
      id: "clientId",
      type: "text",
      name: "clientId",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        // {
        //   required: true,
        //   message: "Please enter your client id",
        //   validateTrigger: "onChange",
        // },
      ],
    },
    {
      title: "Client Secret",
      id: "clientSecret",
      type: "text",
      name: "clientSecret",
      svg: <PasswordSvg />,
      placeHolder: "",
      required: true,
      rules: [
        // {
        //   required: true,
        //   message: "Please enter your client secret",
        //   validateTrigger: "onChange",
        // },
      ],
    },
  ],
};

export const userColumns = [
  {
    title: "Organization Name",
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any) => {
      return a.name.length - b.name.length;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Email Address",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Role",
    dataIndex: "role",
    key: "role",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

//API Key Table Columns (Code added by Dev-Dasani)
export const apiKeysTableColumns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Authorization Header",
    dataIndex: "authHeader",
    key: "authHeader",
  },
  {
    title: "API Key",
    dataIndex: "apiKey",
    key: "apiKey",
  },
  {
    title: "Created At",
    dataIndex: "createdDate",
    key: "createdDate",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export const roleDataSource: any = [];
roleDataSource.push({
  name: `Admin`,
  description: `Description for role`,
  permissions: `Permission Details`,
  status: true,
  action: `some action`,
});

for (let index = 0; index < 15; index++) {
  roleDataSource.push({
    name: `Role ${index}`,
    description: `Description for role ${index}`,
    permissions: `Permission Details`,
    status: index % 2 == 0 ? true : false,
    action: `some action`,
  });
}

export const roleColumns = [
  {
    title: "Role Name",
    dataIndex: "name",
    key: "name",
    sorter: (a: any, b: any) => {
      return a.name.length - b.name.length;
    },
    sortDirections: ["descend"],
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Permissions",
    dataIndex: "permissions",
    key: "permissions",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export const PermissionDataSource: any = [
  {
    moduleName: "Admin",
    isParentModule: true,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Dashboard",
    isParentModule: false,
    all: true,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Employee Cost",
    isParentModule: false,
    all: false,
    view: true,
    edit: true,
    delete: false,
  },
  {
    moduleName: "Cost Allocation",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Journal Entries",
    isParentModule: false,
    all: false,
    view: true,
    edit: true,
    delete: false,
  },
  {
    moduleName: "Time Activities",
    isParentModule: true,
    all: true,
    view: false,
    edit: false,
    delete: true,
  },
  {
    moduleName: "TimeLogs",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "TimeSheets",
    isParentModule: false,
    all: false,
    view: true,
    edit: true,
    delete: true,
  },
  {
    moduleName: "Settings",
    isParentModule: true,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Roles",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Users",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Integrations",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Configurations",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Subscriptions",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Custom Rules",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Reports",
    isParentModule: true,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Time Summary",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Payroll Summary",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
  {
    moduleName: "Customer Overview",
    isParentModule: false,
    all: false,
    view: false,
    edit: false,
    delete: false,
  },
];

export const UserProfileData = [
  {
    title: "Full Name",
    id: "fullName",
    type: "text",
    name: "fullName",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please enter your full name",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter your first name",
        validateTrigger: "onChange",
      },
      {
        max: 30,
        message: "First name length must be less than 30 characters",
        validateTrigger: "onChange",
      },
    ],
  },
  {
    title: "Email Address",
    id: "email",
    type: "text",
    name: "email",
    defaultValue: "",
    disabled: true,
    errorMessage: "Please enter your email",
    required: false,
    rules: [],
  },
  {
    title: "Country",
    id: "country",
    type: "text",
    name: "country",
    defaultValue: "",
    disabled: true,
    errorMessage: "Please enter your country",
    required: false,
    rules: [],
  },
  {
    title: "Company Name",
    id: "companyname",
    type: "text",
    name: "companyname",
    defaultValue: "",
    disabled: true,
    errorMessage: "Please enter your company name",
    required: false,
    rules: [],
  },
  {
    title: "Phone Number",
    id: "phone",
    type: "number",
    name: "phone",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please enter your number",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter your number",
        validateTrigger: "onChange",
      },
      { validator: phoneNumberValidator, validateTrigger: "onChange" },
    ],
  },
];

export const permissionObject = [
  // {
  // 	name: 'Admin',
  // 	items: [1, 2, 3, 4],
  // },
  // {
  // 	name: 'Time Activity',
  // 	items: [5, 6],
  // },
  {
    name: "DashBoard",
    items: [5, 6, 7, 8, 9, 10],
  },
  {
    name: "Settings",
    items: [1, 2, 3, 4, 11, 12],
  },

  // {
  // 	name: 'Reports',
  // 	items: [11, 12, 13, 14],
  // },
];

export const integrationDataSource: any = [
  {
    key: "1",
    Accounting_Software: {
      connection: "QBO",
      company: "sandbox",
    },
    Ecommerce_Software: {
      connection: "Magento",
      company: "Magento 1",
    },
    Status: true,
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    Accounting_Software: {
      connection: "XERO",
      company: "sandbox",
    },
    Ecommerce_Software: {
      connection: "Shopify",
      company: "shopify 1",
    },
    Status: false,
    tags: ["nice", "developer"],
  },
  {
    key: "3",
    Accounting_Software: {
      connection: "ZOHO",
      company: "ZohoBooks 1",
    },
    Ecommerce_Software: {
      connection: "ShopWare",
      company: "ShopWare 1",
    },
    Status: true,
    tags: ["nice", "developer"],
  },
];

export const TechnicalSupportEmailForm = [
  {
    title: "Subject",
    id: "emailSubject",
    type: "text",
    name: "emailSubject",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please enter subject",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter an email subject",
        validateTrigger: "onChange",
      },
      {
        min: 3,
        message: "Email subject should be at least 3 characters long",
        validateTrigger: "onChange",
      },
    ],
  },
  {
    title: "How can we help you today?",
    id: "emailBody",
    type: "textarea",
    name: "emailBody",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please describe the issue",
    required: true,
    rules: [
      {
        required: true,
        message: "Please enter details about your issue",
        validateTrigger: "onChange",
      },
      {
        max: 500,
        message: "Content should not exceed 500 characters",
        validateTrigger: "onChange",
      },
    ],
  },
  {
    title: "Attachments",
    id: "emailAttachment",
    type: "fileUpload",
    name: "emailAttachment",
    defaultValue: "",
    disabled: false,
    errorMessage: "Add Attachment if any",
    required: false,
    rules: [],
  },
  {
    title: "Phone Number",
    id: "phone",
    type: "phone",
    name: "phone",
    placeHolder: "",
    required: true,
    rules: [{ validator: phoneNumberValidator, validateTrigger: "onChange" }],
  },
  {
    title: "How critical is your request ?",
    id: "issueIntensity",
    type: "dropdown",
    name: "issueIntensity",
    defaultValue: "",
    disabled: false,
    errorMessage: "Please select the inetnsity of your issue",
    required: true,
    rules: [
      {
        required: true,
        message: "Please select the intensity of your issue",
        validateTrigger: "onChange",
      },
    ],
  },
];

export const IntegrationUrl = [
  {
    SageIntacct:
      "https://www.intacct.com/ia/docs/en_US/help_action/Administration/Users/web-services-only-users.htm?cshid=Web_services_users#AddaWebServicesuser",
  },
  {
    WooCommerce:
      "https://woocommerce.github.io/woocommerce-rest-api-docs/?javascript#index",
  },
  {
    Stripe: "https://stripe.com/docs/api/errors/handling?lang=node",
  },
  {
    Netsuite:
      "https://drive.google.com/file/d/10l3i2S4H80OTs7JQn-jpVwu5zHcrGF5v/view?usp=drive_link",
  },
  {
    ClearBooks: "https://www.clearbooks.co.uk/support/api/docs/soap/",
  },
  {
    "Authorize.net":
      "https://support.authorize.net/knowledgebase/Knowledgearticle/?code=000001560",
  },
];
