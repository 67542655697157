import { createAsyncThunk } from "@reduxjs/toolkit";
import { getApi } from "redux/apis";

export const getApiLogs = createAsyncThunk(
    "apiLogs/get",
    async (params: any = {}, { rejectWithValue }) => {
        try {
            const response = await getApi(`/apiLogs/get`, {
                ...params
            });
            return response?.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error?.response?.data);
        }
    }
);