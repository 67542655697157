import IntegrationLayout from "layouts/Settings/Integration";
import { TableActionHeader } from "components/Global";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { GetIntegrationAction } from "redux/action/IntegrationAction";
import { AppDispatch } from "redux/store";
import { FORMDATA } from "constants/Data";
import EmbedScript from "components/Global/EmbedScriptModal";

export default function IntegrationPage() {
  const location = useLocation();
  const { newIntegrationMenuItems } = FORMDATA;
  // Get the current pathname and initialize selectedSidebar with the last segment.
  const pathname = window.location.pathname;
  const pathSegments = pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];
  // const currentUrl = window.location.href;
  // const [baseUrl] = currentUrl.split("/integrations/accounting");
  const companyId = localStorage.getItem("companyId");
  const dispatch = useDispatch<AppDispatch>();

  const [selectedSidebar, setSelectedSidebar] = useState<string>(lastSegment);
  const [previewDisable, setPreviewDisable] = useState<boolean>(true);
  const [isEmbedModalOpen, setIsEmbedModalOpen] = useState<boolean>(false);

  const integrationList = useSelector((state: any) => state?.Integration?.data);

  const onApps = integrationList?.filter((items: any) => items?.isOn === true);

  const navigate = useNavigate();

  // Handle sidebar selection change.
  const sideBarChangeHandler = (selectedValue: any) => {
    navigate(selectedValue.key);
  };

  // Initialize selectedSidebar based on the current pathname.
  const initialFunction = () => {
    const splitArray = pathname.split("/");
    setSelectedSidebar(splitArray[splitArray.length - 1]);
  };

  // const previewHandler = () => {
  //   navigate("preview/connectedApps");
  // };

  // Call initialFunction when the component mounts or the window location changes.
  useEffect(() => {
    initialFunction();
  }, [window.location.href]);

  useEffect(() => {
    dispatch(GetIntegrationAction(""));
  }, []);

  useEffect(() => {
    if (onApps?.length > 0) {
      setPreviewDisable(false);
    }
  });

  const handleCancel = () => {
    setIsEmbedModalOpen(false);
  };
  const handleOk = () => {
    setIsEmbedModalOpen(false);
  };

  // Render the component.
  return (
    <div>
      <TableActionHeader title={`${location.pathname !== "/integrations/connectedapps" ? "Configuration" : "Companies"}`}>
        {location.pathname !== "/integrations/connectedapps" && (
          <div
            className={styles["user-table__action"]}
            style={{ display: "flex", gap: 20 }}
          >
            {/* {localStorage.getItem("companyId") !== "undefined" && DashboardPermission?.add && ( */}
            <button
              className={`btn-blue ${styles["user-table__action--button"]}`}
              onClick={() => {
                setIsEmbedModalOpen(true);
              }}
            >
              <p>Embedd</p>
            </button>
            {/* {localStorage.getItem("companyId") !== "undefined" && DashboardPermission?.add && ( */}
            <a
              style={{ color: "white" }}
              target="_blank"
              href={`${window.location.origin}/preview?t=${btoa(
                companyId ? companyId : "companyId"
              )}`}
              rel="noreferrer"
            >
              {" "}
              <button
                className={styles["previewBtn"]}
                style={{
                  backgroundColor: previewDisable ? "gray" : "#0b78c2",
                  cursor: previewDisable ? "not-allowed" : "pointer",
                }}
                // onClick={previewHandler}
                disabled={previewDisable}
              >
                Preview
              </button>
            </a>
          </div>
        )}
      </TableActionHeader>
      {location.pathname !== "/integrations/connectedapps" ? (
        <IntegrationLayout
          onSideBarChange={sideBarChangeHandler}
          selectedSidebar={selectedSidebar}
          sidebarMenu={newIntegrationMenuItems}
        >
          <Outlet />
        </IntegrationLayout>
      ) : (
        <Outlet />
      )}
      {isEmbedModalOpen && (
        <EmbedScript
          isModalOpen={isEmbedModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}
    </div>
  );
}
