import { createSlice } from "@reduxjs/toolkit";
import { getAccountsAction } from "redux/action/accountsAction";
import { toastText } from "utils/utils";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const AccountSlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get All Users
    builder.addCase(getAccountsAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getAccountsAction.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.isLoading = false;
      state.error = null;
      toastText("Accounts fetched successfully", "success");
    });
    builder.addCase(getAccountsAction.rejected, (state, action: any) => {
      state.error = action.payload;
      state.isLoading = false;
      toastText(action?.payload?.message, "error");
    });
  },
});

export default AccountSlice;
