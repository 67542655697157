import { createSlice } from "@reduxjs/toolkit";
import { codeVerificationAction } from "redux/action/codeVerificationAction";
// import { toastText } from "utils/utils";

const initialState: any = {
    data: null,
    isLoading: false,
    error: null,
};

const codeVerificationSlice = createSlice({
    name: "codeVerificationSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(codeVerificationAction.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(codeVerificationAction.fulfilled, (state, action: any) => {
            state.data = action?.payload
            state.isLoading = false;
            // toastText(action?.payload?.message, "success");
        });
        builder.addCase(codeVerificationAction.rejected, (state, action: any) => {
            state.isLoading = false;
            state.error = action.payload;
            // toastText(action?.payload?.message, "error");
        });
    },
});

export default codeVerificationSlice;
