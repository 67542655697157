import { Tabs } from "antd";
import { TableActionHeader } from "components/Global";
import { FORMDATA } from "constants/Data";
const WebhooksPage = () => {
  const { webhooksTabs } = FORMDATA;
  const onChange = (key: string) => {
  console.log("🚀 ~ onChange ~ key:", key)
  };
  return (
    <div>
      <TableActionHeader
        title={"Webhooks"}
        horizontalLine={true}
      ></TableActionHeader>
      <Tabs
        size={"large"}
        defaultActiveKey="1"
        items={webhooksTabs}
        onChange={onChange}
      />
    </div>
  );
};

export default WebhooksPage;
