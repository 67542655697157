import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUnifiedApi } from "redux/apis";

export const getCustomersAction = createAsyncThunk(
  "customers/getCustomers",
  async (data: any, { rejectWithValue }) => {
    const { params, headers } = data;
    try {
      const response = await getUnifiedApi(
        `/customer/v1/customers`,
        params,
        headers
      );

      return response.data.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
