/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Cascader, DatePicker, Select, TreeSelect } from "antd";
import { useState } from "react";
import styles from "./index.module.scss";

interface OptionInterface {
  label: string;
  value: string;
}

interface CascadeOptionInterface {
  value: string | number;
  label: string;
  children?: CascadeOptionInterface[];
}

interface Props {
  selectedConnection: OptionInterface | null;
  handleConnection: (value: OptionInterface | null) => void;
  handleSelectEntityHandler: any;
  selectedEntity: string[];
  dataSourceHandler: any;
  connections: any;
  selectedApp: any;
  handleApiKey: any;
  apiKeyValues: any;
  selectedApiKey: any;
  handlePlaygroundStartDate: any;
  handlePlaygroundEndDate: any;
  playgroundEndDate: any;
  playgroundStartDate: any;
}

const options: CascadeOptionInterface[] = [
  {
    value: "income",
    label: "Income",
    children: [
      {
        value: "customers",
        label: "Customers",
      },
    ],
  },
  {
    value: "expenses",
    label: "Expenses",
    children: [
      {
        value: "vendors",
        label: "Vendors",
      },
    ],
  },
  {
    value: "accounting",
    label: "Accounting",
    children: [
      {
        value: "accounts",
        label: "Accounts",
      },
    ],
  },
];

const SearchAndFilter = (props: Props) => {
  const {
    // selectedConnection,
    apiKeyValues,
    handleSelectEntityHandler,
    selectedEntity,
    handleApiKey,
    selectedApiKey,
    dataSourceHandler,
    handlePlaygroundEndDate,
    handlePlaygroundStartDate,
    playgroundEndDate,
    playgroundStartDate,
  } = props;

  const disabledDate = (current: any) => {
    // Disable future dates
    return current && current > new Date();
  };

  return (
    <>
      <div className={styles["search-and-filter"]}>
        <div className={styles["search-and-filter-left"]}>
          <Cascader
            options={options}
            onChange={handleSelectEntityHandler}
            placeholder="Select entity"
            defaultValue={selectedEntity}
            displayRender={(labels) => labels.join(" - ")}
            style={{ width: 200 }}
          />
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Api Key"
            optionFilterProp="children"
            filterOption={(input, option) =>
              ((option?.label as string).toLowerCase() ?? "").includes(
                input?.toLowerCase()
              )
            }
            options={apiKeyValues}
            onChange={handleApiKey}
            value={selectedApiKey}
          />
          <DatePicker
            style={{ width: 200 }}
            title="Start date"
            placeholder="Start date"
            onChange={handlePlaygroundStartDate}
            value={playgroundStartDate}
            disabledDate={disabledDate}
          />
          <DatePicker
            style={{ width: 200 }}
            title="End date"
            placeholder="End date"
            value={playgroundEndDate}
            onChange={handlePlaygroundEndDate}
            disabledDate={(current: any) =>
              current && (current < playgroundStartDate || current > new Date())
            }
            disabled={!playgroundStartDate}
          />
          <Button
            onClick={() => dataSourceHandler()}
            type="primary"
            disabled={
              !selectedEntity ||
              !selectedApiKey ||
              (playgroundStartDate && !playgroundEndDate)
            }
          >
            {`Fetch ${selectedEntity ? selectedEntity[1] :""}`}
          </Button>
        </div>
      </div>
    </>
  );
};

export default SearchAndFilter;
