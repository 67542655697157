import { Button, Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetActiveIntegrationAction } from "redux/action/IntegrationAction";
import { AppDispatch } from "redux/store";
import styles from "./index.module.scss";
// import { SearchOutlined } from "@ant-design/icons";
import { getApi } from "redux/apis";
import { CssSpriteNames } from "utils/staticObject";
import TableActionHeader from "../TableActionHeader";
import "./index.scss";
import { toastText } from "utils/utils";

const OauthConnectApps = () => {
  const [loading , setLoading] = useState(false)
  const dispatch = useDispatch<AppDispatch>();
  const { data: integrationList, isLoading } = useSelector(
    (state: any) => state?.Integration
  );

  const [selectedIntegration, setSelectedIntegration] = useState<any>();
  // const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const t = params.get("t");
    localStorage.setItem("t", t ?? "");
  }, []);

  useEffect(() => {
    dispatch(
      GetActiveIntegrationAction({ companyId: localStorage.getItem("t") })
    );
  }, []);

  const selctIntegration = (integration: any) => {
    setSelectedIntegration(integration);
    localStorage.setItem('integration',integration.integrationName)
  };

  // const performSearchHandler = (e: any) => {
  //   setSelectedIntegration(undefined);
  //   const { value } = e.target;
  //   setSearchValue(value);
  // };
  const connect = () => {
    setLoading(true);
    if (selectedIntegration?.integrationName === "XERO") {
      try {
        getApi(`/xero/getxerourl/${selectedIntegration.id}`).then(
          (res: any) => {
            if (res?.status === 200) {
              const authURL = res?.data?.data;
              // Open the new window
              window.open(authURL, "_self");
            }
          }
        );
      } catch (error) {
        // console.error("Error fetching Xero URL:", error);
        toastText("Fail to get auth url","error")
        // Handle the error here
      }
    }
    if (selectedIntegration?.integrationName === "QUICKBOOKS") {
      try {
        getApi(`/qbo/authurl/${selectedIntegration.id}`).then((res: any) => {
          if (res?.status === 200) {
            const authURL = res?.data?.data;
            // Open the new window
            window.open(authURL, "_self");
          }
        });
      } catch (error) {
        console.error("Error fetching QBO URL:", error);
        toastText("Fail to get auth url", "error");
        // Handle the error here
      }
    }
    if (selectedIntegration?.integrationName === "ZOHOBOOK") {
      try {
        getApi(`/zoho/authurl/${selectedIntegration.id}?type=zohoBook`).then(
          (res: any) => {
            if (res?.status === 200) {
              const authURL = res?.data?.data;
              // Open the new window
              window.open(authURL, "_self");
            }
          }
        );
      } catch (error) {
        console.error("Error fetching QBO URL:", error);
        toastText("Fail to get auth url", "error");
        // Handle the error here
      }
    }
    if (selectedIntegration?.integrationName === "WAVEACCOUNT") {
      try {
        getApi(`/wave/authurl/${selectedIntegration.id}`).then((res: any) => {
          if (res?.status === 200) {
            const authURL = res?.data?.data;
            // Open the new window
            window.open(authURL, "_self");
          }
        });
      } catch (error) {
        console.error("Error fetching Xero URL:", error);
        toastText("Fail to get auth url", "error");
        // Handle the error here
      }
    }

    if (location.pathname !== '/preview'){
      setLoading(false)
    }
  };
  return (
    <>
      {!isLoading ? (
        <div className={styles["integrations"]}>
          <TableActionHeader title="Select a software you wish to connect" />
          <span className={styles["integrations__subtitle"]}>
            You will be prompted to sign in to your accounts to authorize the
            secure sharing of your financial information
          </span>
          {/* <Input
            className={styles["integrations__search"]}
            placeholder="Search by Name"
            prefix={<SearchOutlined />}
            onChange={performSearchHandler}
            value={searchValue}
            size="large"
          /> */}
          <div className={styles["integrations__images"]}></div>
          <Row justify={"start"} gutter={25} style={{cursor:'pointer'}}>
            {integrationList?.map((item: any) => (
              <Col
                // span={6}
                xs={24}
                md={8}
                lg={6}
                sm={12}
                key={item.id}
                onClick={() => {
                  selctIntegration(item);
                }}
                style={{ marginBottom: 12 }}
              >
                <Card
                  bordered={true}
                  className={`card ${
                    item.id === selectedIntegration?.id ? "selected" : ""
                  }`}
                >
                  <div
                    className={
                      styles[
                        `bg-${
                          CssSpriteNames[
                            item.integrationName as keyof typeof CssSpriteNames
                          ]
                        }`
                      ]
                    }
                  >
                    {" "}
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <Row justify={"center"}>
            <Button
              className={`${styles["integrations__connectbtn"]} ${selectedIntegration ? "connectBtn": "disableBtn"}`}
              disabled={selectedIntegration ? false : true}
              onClick={connect}
              type="primary"
              loading={loading}
              
            >
              Connect
            </Button>
          </Row>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <img
            src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading.gif`}
            height={120}
            crossOrigin={
              process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
            }
          />
        </div>
      )}
    </>
  );
};

export default OauthConnectApps;
