import { Form, Select } from "antd";
import { InputWithLabelAndSvg } from "components/Global";
import { FORMDATA } from "constants/Data";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "redux/store";
import styles from "./index.module.scss";
import { SideDrawerBodyProps } from "./types";
import { editUserAction, inviteUserAction } from "redux/action/userAction";
import PhoneInput from "react-phone-input-2";
import { phoneNumberFormatterr } from "helper/phoneNumberFormatter";

const AddUserBody: FC<SideDrawerBodyProps> = (props) => {
  // Inits
  const { addUserFields } = FORMDATA;
  const { closeDrawerByAnimation, editSelectedUser } = props;
  const [form] = Form.useForm();

  // State Management
  const [roleOptions, setRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isSelected, setIsSelected] = useState<boolean>();
  const [showError, setShowError] = useState<boolean>();
    const [num, setNum] = useState(editSelectedUser ? editSelectedUser.simplePhone : "");

  const [phoneNumber, setPhoneNumber] = useState(
    editSelectedUser ? editSelectedUser.simplePhone : ""
  );

  
 const phoneChangeHandler = (value: string, country: any) => {
  !value && setShowError(true)
   const formattedValue = phoneNumberFormatterr(value, country?.dialCode);
   setNum(formattedValue);

   if (
     formattedValue.replace(/\D/g, "").length !==
     country?.dialCode.length + 10
   ) {
     // setPhoneError("Please enter a valid 10-digit phone number");
   } else {
     setPhoneError("");
   }
 };
  const [phoneError, setPhoneError] = useState("");

  const editData = {
    ...editSelectedUser,
    fullName: editSelectedUser?.name || "",
    phone: editSelectedUser?.simplePhone,
  };

  const dispatch = useDispatch<AppDispatch>();
  const { data: roleData } = useSelector((state: any) => state?.roles);
  const { isAddUserLoading } = useSelector((state: any) => state?.users);
  const [saveAndAddLoader, setSaveAndAddLoader] = useState(false);
  useEffect(() => {
    const data = roleData
      ?.map((role: any) => {
        if (role.roleName !== "Company Admin") {
          return {
            value: role.id,
            label: role.roleName,
          };
        }
        return null; // Return null for roles you want to exclude
      })
      .filter(Boolean);
    setRoleOptions(data);
  }, [roleData]);

  // If form get success
  const onFinish = (values: any, buttonType = "Save") => {
    const fullName = (values.fullName as string).trim();

    !num && setShowError(true);
    if (editSelectedUser) {
      if(num){
        let data: any = {
          userId: editSelectedUser?.userId,
          phone: num,
          fullName
        };

        if (selectedRole) {
          data = {
            ...data,
            roleId: selectedRole,
            isChangeStatus: false,
          };
        }

        dispatch(editUserAction(data)).then(() => {
          closeDrawerByAnimation();
        });
      }else{
         setShowError(true);
      }
    } else {
      if (selectedRole && num) {
          setIsSelected(true);
          buttonType === "SaveAndAdd" && setSaveAndAddLoader(true);
          const finalData = {
            email: values.email,
            role: selectedRole,
            // phone: phoneNumber,
            phone: num,
            fullName,
          };
          dispatch(inviteUserAction(finalData)).then((res) => {
            if (res?.payload?.data) {
              form.resetFields();
              setPhoneNumber(
                editSelectedUser ? editSelectedUser.simplePhone : ""
              );
              buttonType === "Save" && closeDrawerByAnimation();
            }
            buttonType === "SaveAndAdd" && setSaveAndAddLoader(false);
          });
        
          
        } else {
        selectedRole ? setIsSelected(true) : setIsSelected(false);
      }
    }
  };

  const checkEmailValidation = async () => {
    try {
      await form.validateFields(["email"]);
      return true;
    } catch (error) {
      return false;
    }
  };

  // handle Save and Add
  const saveAndAdd = async () => {

    const formValues = form.getFieldsValue();
    form.validateFields();
    const isEmailValidate = await checkEmailValidation();
    !selectedRole && setIsSelected(false);
    !num && setShowError(true)
    if (
      isEmailValidate &&
      selectedRole &&
      formValues?.fullName
    )
      onFinish(formValues, "SaveAndAdd");
  };

  // If form fails
  const onFinishFailed = () => {
 
    !num && setShowError(true)
    if (selectedRole) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  };

  // JSX
  return (
    <div className={styles["side-drawer-body"]}>
      <Form
        form={form}
        name="basic"
        initialValues={editData}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        labelAlign="left"
        className={styles["side-drawer-form"]}
      >
        <div className={styles["side-drawer-form__inputs"]}>
          {addUserFields.map((singleField, index) => {
            return (
              <React.Fragment key={index}>
                {singleField.id === "phone" && (
                  <>
                    {" "}
                    <Form.Item
                      className="side-drawer-form__role"
                      name={singleField.name}
                    >
                      <label
                        className={styles["side-drawer-form__role--label"]}
                      >
                        {singleField.title}{" "}
                        {singleField?.required && (
                          <span className="required-color">*</span>
                        )}
                      </label>
                      <PhoneInput
                        // inputProps={{
                        //   name: "phone",
                        //   required: true,
                        // }}
                        country={"us"}
                        value={num}
                        onChange={phoneChangeHandler}
                        inputClass={`${styles["input-icon__form--input"]} ${styles.customPhoneInput}`}
                      />
                      {num == false && showError && (
                        <p className="ant-form-item-explain-error">
                          Please enter phone number
                        </p>
                      )}
                    </Form.Item>
                  </>
                )}
                {singleField.id !== "roleName" &&
                  singleField.id !== "phone" && (
                    <InputWithLabelAndSvg
                      singleUserInput={singleField}
                      disabled={
                        singleField?.name == "email" && editSelectedUser && true
                      }
                      setPhoneNumber={setPhoneNumber}
                      phoneNumber={phoneNumber}
                      phoneError={phoneError}
                      setPhoneError={setPhoneError}
                    />
                  )}
                {singleField.id === "roleName" && (
                  <Form.Item
                    className="side-drawer-form__role"
                    name={singleField.name}
                  >
                    <label className={styles["side-drawer-form__role--label"]}>
                      {singleField.title}{" "}
                      {singleField?.required && (
                        <span className="required-color">*</span>
                      )}
                    </label>
                    <Select
                      placeholder="Select Role"
                      className={styles["side-drawer-form__role--select"]}
                      size="large"
                      onSelect={(role) => {
                        setSelectedRole(role);
                        setIsSelected(true);
                      }}
                      defaultValue={
                        editSelectedUser && editSelectedUser?.roleId
                      }
                    >
                      {roleOptions?.map((role: any, key) => {
                        return (
                          <Select.Option value={role?.value} key={key}>
                            {role?.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {isSelected == false && (
                      <p className="ant-form-item-explain-error">
                        Please select role
                      </p>
                    )}
                  </Form.Item>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div className={styles["side-drawer-form__buttons"]}>
          {!editSelectedUser && (
            <Form.Item>
              <button
                type="button"
                className={`${
                  styles["side-drawer-form__buttons--save-and-add"]
                } ${isAddUserLoading && "pointer-event-none"}`}
                onClick={saveAndAdd}
              >
                {saveAndAddLoader ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                    crossOrigin={
                      process.env.REACT_APP_ENV === "local"
                        ? undefined
                        : "anonymous"
                    }
                    height={40}
                  />
                ) : (
                  "Save And New"
                )}
              </button>
            </Form.Item>
          )}
          <Form.Item>
            <button
              type="submit"
              className={`${
                styles[
                  editSelectedUser
                    ? "side-drawer-form__buttons--edit"
                    : "side-drawer-form__buttons--save"
                ]
              } ${isAddUserLoading && "pointer-event-none"}`}
            >
              {isAddUserLoading && !saveAndAddLoader ? (
                <img
                  src={`${process.env.REACT_APP_IMAGES_BASE_URL}/assets/gifs/loading-black.gif`}
                  crossOrigin={
                    process.env.REACT_APP_ENV === "local"
                      ? undefined
                      : "anonymous"
                  }
                  height={40}
                />
              ) : (
                "Save"
              )}
            </button>
          </Form.Item>
          <Form.Item>
            <button
              type="reset"
              className={`${styles["side-drawer-form__buttons--cancel"]}`}
              onClick={closeDrawerByAnimation}
              disabled={isAddUserLoading}
            >
              Cancel
            </button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddUserBody;
