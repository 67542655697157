import { Button, Checkbox, Form, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { AddScopeProps } from "./types";
import {
  CssSpriteNames,
  DescriptionKeys,
  GetClientIdReference,
  QuickBookOnlineScopes,
  WaveAccountingScopes,
  XeroScopes,
  ZohoBooksScopes,
} from "utils/staticObject";
import { ExportOutlined } from "@ant-design/icons";
import { FORMDATA } from "constants/Data";
import { UpdateIntegrationDataAction } from "redux/action/IntegrationAction";
import { useDispatch } from "react-redux";
import React from "react";
import { AppDispatch } from "redux/store";
import InputWithLabelAndSvgMasked from "../InputWithLabelMasked";
// ConfirmDelete popup
const AddScope: FC<AddScopeProps> = (props) => {
  // Inits
  const {
    handleCancel,
    handleOk,
    isModalOpen,
    selectedIntegration,
    isLoading,
  } = props;
  const dispatch = useDispatch<AppDispatch>();

  const [selectedScopes, setSelectedScopes] = useState<string[]>([]);
  const [isScopeSelected, setIsScopeSelected] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({
    clientId: selectedIntegration?.clientId || "",
    clientSecret: selectedIntegration?.clientSecret || "",
  });
  const [isSubmitClick, setIsSubmitClick] = useState(false);
  // Ask Pratik
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPasswordValidate, setIsPasswordValidate] = useState(false);
  const [form] = Form.useForm();

  const handleCheckboxChange = (value: string) => {
    if (value) {
      setIsScopeSelected(true);
    }
    if (selectedScopes.includes(value)) {
      setSelectedScopes((prevSelected) =>
        prevSelected.filter((scope) => scope !== value)
      );
    } else {
      setSelectedScopes((prevSelected) => [...prevSelected, value]);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = async (values: any) => {
    setIsSubmitClick(true);
    if (
      selectedScopes.length &&
      isScopeSelected === true &&
      formValues.clientId != "" &&
      formValues.clientSecret !== ""
    ) {
      const data = {
        ...formValues,
        scope: selectedScopes,
        id: selectedIntegration.id,
      };
      const res = await dispatch(UpdateIntegrationDataAction(data));
      if (res?.payload?.statusCode === 200) {
        handleOkClick();
      }
    } else {
      !selectedScopes.length && setIsScopeSelected(false);
    }
  };

  const onFinishFailed = () => {
    setIsSubmitClick(true);
    if (selectedScopes.length) {
      setIsScopeSelected(true);
    } else {
      setIsScopeSelected(false);
    }
  };

  const ResetStateValues = () => {
    setIsScopeSelected(false);
    setIsSubmitClick(false);
    setIsPasswordValidate(false);
    setSelectedScopes([]);
    form.resetFields();
  };

  const handleCancelClick = () => {
    ResetStateValues();
    handleCancel();
  };

  const handleOkClick = () => {
    ResetStateValues();
    handleOk();
  };

  useEffect(() => {
    if (selectedIntegration.scope.length > 0) {
      setSelectedScopes(selectedIntegration.scope);
      setIsScopeSelected(true);
    }
  }, [selectedIntegration]);

  //   JSX
  return (
    <Modal
      title={`Configure ${
        DescriptionKeys[
          selectedIntegration.integrationName as keyof typeof CssSpriteNames
        ]
      }`}
      open={isModalOpen}
      onOk={handleOkClick}
      onCancel={handleCancelClick}
      centered={true}
      width={1000}
      closeIcon={
        <>
          <a
            href={`${
              GetClientIdReference[
                selectedIntegration.integrationName as keyof typeof GetClientIdReference
              ]
            }`}
            target="_blank"
            rel="noreferrer"
          >
            <Button className="primary" style={{ left: -82 }}>
              How to obtain client ID <ExportOutlined />
            </Button>
          </a>
        </>
      }
      footer={[]}
    >
      <div className={styles["configuration"]}>
        <Form
          className={styles["login-body__wrapper"]}
          form={form}
          name="basic"
          autoComplete="off"
          layout="vertical"
          labelAlign="left"
          onFinish={onSubmit}
          onFinishFailed={onFinishFailed}
          initialValues={selectedIntegration}
        >
          <div>
            {FORMDATA.configurationFields.map((singleField, index) => {
              return (
                <React.Fragment key={index}>
                  <InputWithLabelAndSvgMasked
                    singleUserInput={singleField}
                    value={formValues[singleField.id as keyof FormValues]}
                    setFormValues={setFormValues}
                    form={form}
                    isSubmitClick={isSubmitClick}
                  />
                </React.Fragment>
              );
            })}
            <div className={styles["configuration-checkbox-div"]}>
              <div className={styles["configuration-checkbox-div__title"]}>
                Select a scope
              </div>
              <div className={styles["configuration-checkbox-div__des"]}>
                Select scopes to connect your account and authorize to access
                following data:
              </div>
              {selectedIntegration.integrationName === "QUICKBOOKS" &&
                Object.entries(QuickBookOnlineScopes).map(
                  ([label, { value, description }]) => (
                    <div key={value} style={{ marginBottom: 5 }}>
                      <Checkbox
                        checked={selectedScopes?.includes(value)}
                        onChange={() => handleCheckboxChange(value)}
                      >
                        {label}
                      </Checkbox>
                      <span style={{ color: "gray" }}>
                        &nbsp;&nbsp;&nbsp; {description}
                      </span>
                    </div>
                  )
                )}
              {selectedIntegration.integrationName === "WAVEACCOUNT" &&
                Object.entries(WaveAccountingScopes).map(
                  ([label, { value, description }]) => (
                    <div key={value} style={{ marginBottom: 5 }}>
                      <Checkbox
                        checked={selectedScopes?.includes(value)}
                        onChange={() => handleCheckboxChange(value)}
                      >
                        {label}
                      </Checkbox>
                      <span style={{ color: "gray" }}>
                        &nbsp;&nbsp;&nbsp; {description}
                      </span>
                    </div>
                  )
                )}
              {selectedIntegration.integrationName === "XERO" &&
                Object.entries(XeroScopes).map(
                  ([label, { value, description }]) => (
                    <div key={value} style={{ marginBottom: 5 }}>
                      <Checkbox
                        checked={selectedScopes?.includes(value)}
                        onChange={() => handleCheckboxChange(value)}
                      >
                        {label}
                      </Checkbox>
                      <span style={{ color: "gray" }}>
                        &nbsp;&nbsp;&nbsp; {description}
                      </span>
                    </div>
                  )
                )}
              {selectedIntegration.integrationName === "ZOHOBOOK" &&
                Object.entries(ZohoBooksScopes).map(
                  ([label, { value, description }]) => (
                    <div key={value} style={{ marginBottom: 5 }}>
                      <Checkbox
                        checked={selectedScopes?.includes(value)}
                        onChange={() => handleCheckboxChange(value)}
                      >
                        {label}
                      </Checkbox>
                      <span style={{ color: "gray" }}>
                        &nbsp;&nbsp;&nbsp; {description}
                      </span>
                    </div>
                  )
                )}
              {!isScopeSelected && isSubmitClick && (
                <span style={{ color: "red" }}>
                  Please select at least 1 scope
                </span>
              )}
            </div>
          </div>

          <div className={styles["configuration__end"]}>
            <Button
              key="save"
              className={styles["configuration__end-save"]}
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Save
            </Button>
            <button
              key="cancel"
              className={styles["configuration__end-cancel"]}
              onClick={handleCancel}
              type="reset"
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddScope;
interface FormValues {
  clientId: string;
  clientSecret: string;
}
