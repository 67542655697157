import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DynamicCategoryTable from "components/Global/DynamicCategoryTable";
import { UserProfileInterface } from "interfaces/user.interface";
import { checkPermission } from "utils/utils";
// import { integrationsAccountCards } from "utils/staticObject";
import { PermissionsNames } from "constants/Data";
import { GetIntegrationAction } from "redux/action/IntegrationAction";
import { AppDispatch } from "redux/store";

const AccountingTable: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  

  const integrationList = useSelector((state: any) => state?.Integration?.data);
  const accountingDataList = integrationList?.filter((items: any) => items.integrationType === 'ACCOUNTING')

  // Fetch permissions for the selected company from Redux state
  const selectedCompanyPermission = useSelector(
    (state: UserProfileInterface) =>
      state?.userProfile?.data?.companies[0]?.role?.permissions
  );
  const isLoading = useSelector((state: any) => state?.Integration?.isLoading);

  // Determine if 'view' permission is granted for 'Integrations'
  const hasViewPermission = checkPermission(selectedCompanyPermission, {
    permissionName: PermissionsNames.AccessAllIntegrations,
    permission: ["view"],
  });

  useEffect(() => {
    // Check if the current location starts with '/settings/integrations',
    // the selected company has permissions, and 'view' permission is not granted
    if (
      location.pathname.startsWith("/settings/integrations") &&
      selectedCompanyPermission?.length &&
      !hasViewPermission
    ) {
      // Redirect to the home page if view permission is not granted for Integrations
      navigate("/");
    } else {
      dispatch(GetIntegrationAction(''));
    }
  }, [location, selectedCompanyPermission?.length, hasViewPermission]);

  return (
    // Render the DynamicCategoryTable component with the provided data source and type
    <DynamicCategoryTable
      dataSource={accountingDataList}
      type={"ACCOUNTING".toLocaleLowerCase()}
      isLoading={isLoading}
    />
    // <></>
  );
};

export default AccountingTable;
