import { createSlice } from "@reduxjs/toolkit";
import { createApiKey, getApiKey } from "redux/action/apikeyAction";

// Define the initial state for the slice
const initialState: any = {
    data: null,
    deleteApiData: null,
    isLoading: false,
    error: null,
};

// Create the Apikey
const ApikeySlice = createSlice({
    name: 'apikeys',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // Create
        builder.addCase(createApiKey.pending, (state: any) => {
            state.isLoading = true;
        });
        // Handle the fulfilled state of the createApiKey
        builder.addCase(createApiKey.fulfilled, (state: any, action: any) => {
            state.data = action.payload;
            state.isLoading = false;
        });
        // Handle the rejected state of the createApiKey
        builder.addCase(createApiKey.rejected, (state: any, action: any) => {
            state.data = action.payload;
            state.isLoading = false;
        });

        // Get ApiKeys
        builder.addCase(getApiKey.pending, (state: any) => {
            state.isLoading = true;
        });
        
        builder.addCase(getApiKey.fulfilled, (state: any, action: any) => {
            state.data = action.payload;
            state.isLoading = false;
        });
     
        builder.addCase(getApiKey.rejected, (state: any, action: any) => {
            state.data = action.payload;
            state.isLoading = false;
        });
    }
});


export default ApikeySlice;
