import { createSlice } from "@reduxjs/toolkit";
import { getVendorsAction } from "redux/action/vendorAction";
import { toastText } from "utils/utils";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const VendorSlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get All Users
    builder.addCase(getVendorsAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getVendorsAction.fulfilled, (state, action) => {
      state.data = action?.payload;
      state.isLoading = false;
      state.error = null;
      toastText("Vendors fetched successfully", "success");
    });
    builder.addCase(getVendorsAction.rejected, (state, action: any) => {
      state.error = action.payload;
      state.isLoading = false;
      toastText(action?.payload?.message, "error");
    });
  },
});

export default VendorSlice;
