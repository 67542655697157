import './index.scss';
import { Image } from "antd";
import { FC } from 'react'
import { CustomCardProps } from './type';
import { capitalizeFirstLetter, formatUrl } from 'utils/Common';

const CustomCardTitle: FC<CustomCardProps> = (props) => {
    const { drawerData } = props
    console.log(drawerData?.apiName
    )

    const ImageData: Record<string, string> = {
        Xero: `/assets/images/xero-logo.png`,
        QuickbooksOnline: `/assets/images/QBO.png`
    };

    const image = Object?.keys(ImageData)?.find((item: any) => item === drawerData?.Integration) || '';



    return (
        <div className={'d-flex'} style={{ alignItems: 'center' }}>
            <div style={{ paddingRight: '10px' }}>
                <Image
                    src={ImageData[image]}
                    preview={false}
                    alt="API keys"
                />
            </div>
            <div className={'d-flex__ml-1'}>
                <p className={'card-title'}> {capitalizeFirstLetter(drawerData?.apiName)}</p>
                <p className={'card-title__sub-text'}>{drawerData?.method}{" "} {formatUrl(drawerData?.apiUrl)}</p>
            </div>
        </div>
    )
}

export default CustomCardTitle