import { createAsyncThunk } from "@reduxjs/toolkit";
import { getApi } from "redux/apis";

export const getCompanyDetailsAction = createAsyncThunk(
  "company/getCompany",
  async (params: any = {}, { rejectWithValue }) => {
    try {
      const response = await getApi("/companies/public", {
        ...params,
      });
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
