import { TableActionHeader } from "components/Global";

// import { useState } from 'react';
// import SubscriptionComponent from 'components/settings/SubscriptionArea'; 3
import MySubscriptionComponent from "components/settings/SubscriptionArea/MySubscriptionComponent";

export default function Subscription() {
  // const [isSubscriptionModalOpen, setIsScopeModalOpen] = useState(false);

  // const handleChange = (value: boolean): void => {
  //   setIsScopeModalOpen(value)
  // }

  return (
    <div>
      <TableActionHeader title={"My Subscription"}></TableActionHeader>
      {/* <SubscriptionComponent /> */}

      <MySubscriptionComponent />

      {/* {isSubscriptionModalOpen ? <SubscriptionComponent stateHandler={handleChange} /> : <MySubscriptionComponent stateHandler={handleChange} />} */}
    </div>
  );
}
