import React, { FC, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form, Input } from "antd";
import styles from "./index.module.scss";
import { singleUserInputInputWithLabelAndSvgProps } from "./types";
import { phoneNumberFormatterr } from "helper/phoneNumberFormatter";


const InputWithLabelAndSvg: FC<singleUserInputInputWithLabelAndSvgProps> = (
  props
) => {
  const {
    singleUserInput,
    disabled,
    setPhoneNumber,
    phoneError,
    setPhoneError,
    setIsPasswordValidate,
  } = props;

  const [num, setNum] = useState("");
  const [email, setEmail] = useState("");



  const phoneChangeHandler = (value: string, country: any) => {
    const formattedValue = phoneNumberFormatterr(value, country?.dialCode);
    setNum(formattedValue);
    if (formattedValue.replace(/\D/g, "").length === 0) {
      setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };

  const handlePasswordChange = (password: string) => {
    setIsPasswordValidate(false);
    const isMinLength = password.length >= 8;
    const isSpecialAndNumber = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(password);
    setIsPasswordValidate(isMinLength && isSpecialAndNumber);
  };


  useEffect(() => {
   if (num !== ''){
     setPhoneNumber(num);
   }else{
    setPhoneError()
   }
  }, [num]);

  useEffect(() => {
    const emailLocal = localStorage.getItem("email");
    if (emailLocal !== undefined && emailLocal !== null) {
      setEmail(emailLocal);
    }
  }, [email]);

  return (
    <>
      <div className={styles["input-icon"]}>
        {singleUserInput.id !== "phone" ? (
          <>
            <div className={styles["input-icon__title"]}>
              {singleUserInput.svg && (
                <div className={styles["input-icon__title--svg"]}>
                  {singleUserInput.svg}
                </div>
              )}
              <label className={styles["input-icon__title--label"]}>
                {singleUserInput.title}{" "}
                {singleUserInput?.required && singleUserInput.type !== "checkbox" && (
                  <span className="required-color">*</span>
                )}
              </label>
            </div>
            <div className={styles["input-icon__form"]} style={{ marginBottom: '10px' }}>
              <Form.Item name={singleUserInput.name} rules={singleUserInput.rules} >
                {singleUserInput.type === "text" && singleUserInput.id !== "phone" && singleUserInput.name !== "email" && (
                  <Input
                    placeholder={singleUserInput.placeHolder}
                    size="large"
                    className={styles["input-icon__form--input"]}
                    type={singleUserInput.type}
                    disabled={disabled ? disabled : false}
                  />
                )}

                {singleUserInput.type === "password" && (
                  <Input.Password
                    placeholder={singleUserInput.placeHolder}
                    size="large"
                    className={styles["input-icon__form--input"]}
                    onChange={(e) => handlePasswordChange(e.target.value)}
                  />
                )}

                {singleUserInput.name === "email" && (
                  <Form.Item>
                    <Input
                      placeholder={singleUserInput.placeHolder}
                      size="large"
                      className={styles["input-icon__form--input"]}
                      type={singleUserInput.type}
                      disabled={true}
                      value={email}
                    />
                  </Form.Item>
                )}
              
              </Form.Item>
            </div>
          </>
        ) : (
          ``
        )}
        {singleUserInput.id === "phone" && (
          <>
            <div className={styles["input-icon__title"]}>
              {singleUserInput.svg && (
                <div className={styles["input-icon__title--svg"]}>
                  {singleUserInput.svg}
                </div>
              )}
              <label className={styles["input-icon__title--label"]}>
                {singleUserInput.title}{" "}
                {singleUserInput?.required && (
                  <span className="required-color">*</span>
                )}
              </label>
            </div>
            <Form.Item >
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                }}
                country={"us"}
                value={num}
                onChange={phoneChangeHandler}
                inputClass={`${styles["input-icon__form--input"]} ${styles.customPhoneInput}`}
              />
              <span className={styles["phoneError"]}>{phoneError}</span>
            </Form.Item>
          </>
        )}
      </div>
    </>
  );
};

export default InputWithLabelAndSvg;
