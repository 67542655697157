import { FC } from "react"
import { LogsSearchAndFilterProps } from "./types"
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, Space, Input, Select } from "antd";
import "./index.scss";

const LogsSearchAndFilter: FC<LogsSearchAndFilterProps> = (props) => {

    const {searchValue, performSearchHandler, performFilterHandler} = props;
    return (
        <div>
            <div className={"search-filter"}>
                <div className={"search-filter__wrapper"}>
                    <Row justify={"space-between"}>
                        <Col>
                        </Col>
                        <Col>
                            <Space>
                                <Input
                                    className={"search-filter__search"}
                                    placeholder="Search by Integration"
                                    suffix={<SearchOutlined />}
                                    onChange={performSearchHandler}
                                    value={searchValue}
                                    size="large"
                                />
                                <Select
                                    defaultValue="All"
                                    style={{ width: 200 }}
                                    onChange={performFilterHandler}
                                    options={[
                                        { label: "All", value: " " },
                                        { label: "OK", value: "200" },
                                        { label: "NOT FOUND", value: "404" },
                                        { label: "UNAUTHORIZED", value: "401" },
                                        { label: "BAD REQUEST", value: "400" },
                                        { label: "BAD GATEWAY ERROR", value: "502" },
                                    ]}
                                    size="large"
                                />
                            </Space>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default LogsSearchAndFilter
