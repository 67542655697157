import {
  Avatar,
  // Button,
  Image,
  Layout,
  Menu,
  Popover,
  Switch,
} from "antd";
import { FC, useState } from "react";
import styles from "./index.module.scss";
import "./index.scss";
import { SidebarProps } from "./types";
// import EmailSupportModal from "components/GetSupport";
// import { TechnicalSupportEmailForm } from "constants/Data";
import { UpOutlined } from "@ant-design/icons";
import ProfilePopover from "../ProfilePopover";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// Global sidebar
const Sidebar: FC<SidebarProps> = (props) => {
  // Initialize variables and state
  const { Sider } = Layout;
  const {
    handleSidebar,
    items,
    //  isGetSupportButton,
    selectedSidebar,
  } = props;

  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [phoneError, setPhoneError] = useState("");
  // // const [formData, setFormData] = useState([{}]);
  // const [isEmailSupportModalOpen, setisEmailSupportModalOpen] = useState(false);
  // const [isLoading] = useState(false);
  const [open, setOpen] = useState(false);

  //  To check the name of the path
  const { pathname } = useLocation();

  // Handle cancel action for Email Support Modal
  // const EmailSupportModalHandleCancel = () => {
  //   setisEmailSupportModalOpen(false);
  // };

  // Handle Email Support Modal
  // const EmailSupportModalHandle = () => {
  //   setisEmailSupportModalOpen(false);
  // };

  // Show the Email Support Modal
  // const showModal = () => {
  //   setisEmailSupportModalOpen(true);
  // };

  const { data: userData } = useSelector((state: any) => state?.userProfile);

  const getInitials = (fullname: string) => {
    const name = fullname;
    let initials = "";
    if (name) {
      name.split(" ")?.forEach((e: any) => {
        initials += e[0].toUpperCase();
      });
    }
    return initials;
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const onSideBarChange = (value: any) => {
    handleSidebar(value);
  };
  const handleChange = () => {
    console.log("switch ");
  };

  // JSX
  return (
    <>
      <div>
        {pathname.startsWith("/settings") ? (
          <>
            <div
              style={{
                display: "flex",
                padding: "15px 0px 15px 10px",
                alignItems: "center",
                background: "rgb(250, 250, 250)",
              }}
            >
              <Image
                src={`https://staging.app.synctools.io/assets/Sidebar/setting.svg`}
                preview={false}
                alt="setting"
                crossOrigin={
                  process.env.REACT_APP_ENV === "local"
                    ? undefined
                    : "anonymous"
                }
              />
              <div
                style={{
                  fontSize: "24px",
                  fontWeight: "500",
                  paddingLeft: "10px",
                  color: "#1d75a7",
                }}
              >
                Settings
              </div>
            </div>
          </>
        ) : (
          <div style={{ width: "100%", background: "rgb(250, 250, 250)" }}>
            <Image
              src={`https://staging.app.synctools.io/assets/sync-tools-v2.svg`}
              preview={false}
              alt="synctools"
              width={"100%"}
              style={{ padding: "20px 29px 20px 7px" }}
              crossOrigin={
                process.env.REACT_APP_ENV === "local" ? undefined : "anonymous"
              }
            />
          </div>
        )}

        <Sider
          style={{
            background: "rgb(250, 250, 250)",
            maxHeight: "100%",
            height: pathname.startsWith("/settings")
              ? "calc(100vh - 120px)"
              : "calc(100vh - 176px)",
            overflow: "auto",
          }}
          className={styles.sidebar}
        >
          <div className={styles.sidebar__wrapper}>
            <Menu
              mode="inline"
              defaultSelectedKeys={[selectedSidebar]}
              selectedKeys={[selectedSidebar]}
              items={items}
              onClick={onSideBarChange}
              className="menu-item "
              style={{
                background: "rgb(250, 250, 250)",
                padding: 0,
              }}
            />
            {/* {isGetSupportButton && (
              <div className={styles.sidebar__support}>
                <Button
                  type="primary"
                  ghost={true}
                  className={styles["sidebar__support--button"]}
                  onClick={() => {
                    setFormData(TechnicalSupportEmailForm);
                    showModal();
                  }}
                >
                  Get Support
                </Button>
              </div>
            )} */}
          </div>
          {/* <EmailSupportModal
            handleCancel={EmailSupportModalHandleCancel}
            handleOk={EmailSupportModalHandle}
            isModalOpen={isEmailSupportModalOpen}
            formData={formData}
            isLoading={isLoading}
            title={"At your service"}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            setPhoneError={setPhoneError}
            phoneError={phoneError}
          /> */}
        </Sider>
        <div
          style={{
            backgroundColor: "rgb(250, 250, 250)",
          }}
        >
          {!pathname.startsWith("/settings") && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <span style={{ fontSize: "1.5rem", marginRight: 10 }}>
                {" "}
                Sandbox{" "}
              </span>
              <Switch defaultChecked onChange={handleChange} />
            </div>
          )}
          <Popover
            open={open}
            onOpenChange={handleOpenChange}
            showArrow={false}
            content={
              <ProfilePopover
                avatarName={getInitials(userData?.fullName)}
                role={userData?.companies[0].role?.roleName}
                fullName={userData?.fullName}
                hide={hide}
                email={userData?.email}
                accessToken={userData?.accessToken}
              />
            }
            trigger="click"
            placement={"right"}
          >
            <div
              style={{
                background: "rgb(239, 239, 239)",
                display: "grid",
                gridTemplateColumns: "20% 60% 20%",
                padding: "10px 0px 10px 10px",
                cursor: "pointer",
              }}
            >
              <Avatar
                size={40}
                style={{
                  background: "#D2E3ED",
                  color: "#1D75A7",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {getInitials(userData?.fullName)}
              </Avatar>
              <div style={{ paddingLeft: "20px", alignSelf: "center" }}>
                <p
                  style={{
                    fontSize: "14px",
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  {userData?.fullName}
                </p>
              </div>
              <div
                style={{ alignSelf: "center" }}
                className={styles["menu-list"]}
              >
                <UpOutlined />
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
