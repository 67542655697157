import { Form, Input } from "antd";
import styles from "./index.module.scss";
import { singleUserInputInputWithLabelAndSvgMaskedProps } from "./types";
import { FC, useState } from "react";
import { maskValue } from "utils/Common";

// For create the dynamic input component
const InputWithLabelAndSvgMasked: FC<
  singleUserInputInputWithLabelAndSvgMaskedProps
> = (props) => {
  // Inits
  const { singleUserInput, value, setFormValues, isSubmitClick } = props;

  const [displayValue, setDisplayValue] = useState(maskValue(value));
  const handleInputChange = (e: any) => {
    const { value, name } = e.target;
    setDisplayValue(value);
    setFormValues((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBlur = (e: any) => {
    const { value } = e.target;
    if (value.length <= 3) {
      setDisplayValue(value);
    } else {
      setDisplayValue(value.substring(0, 3) + "*".repeat(value.length - 3));
    }
  };
 
  const handleFocous = () => {
    setDisplayValue(value);
  };
  // JSX
  return (
    <div className={styles["input-icon"]}>
      <>
        <div className={styles["input-icon__title"]}>
          {singleUserInput.svg && (
            <div className={styles["input-icon__title--svg"]}>
              {singleUserInput.svg}
            </div>
          )}
          <label className={styles["input-icon__title--label"]}>
            {singleUserInput.title}{" "}
            {singleUserInput?.required && (
              <span className="required-color">*</span>
            )}
          </label>
        </div>
        <div className={styles["input-icon__form"]}>
          <Form.Item name={singleUserInput.name} rules={singleUserInput.rules}>
            {singleUserInput.type === "text" &&
              singleUserInput.id !== "phone" && (
                <Input
                  placeholder={singleUserInput.placeHolder}
                  size="large"
                  name={singleUserInput.name}
                  className={styles["input-icon__form--input"]}
                  type={singleUserInput.type}
                  onChange={handleInputChange}
                  value={displayValue}
                  onBlur={handleBlur}
                  onFocus={handleFocous}
                />
              )}
            {isSubmitClick && displayValue === "" ? <p style={{color:'red'}}>Please enter value</p> : ""}
          </Form.Item>
          {/* {singleUserInput.id === "password" && (
              <div>
                <PasswordValidation
                  minLength={passwordValidations.minLength}
                  hasSpecialAndNumber={passwordValidations.hasSpecialAndNumber}
                />
              </div>
            )} */}
        </div>
      </>
    </div>
  );
};

export default InputWithLabelAndSvgMasked;
