import { createSlice } from '@reduxjs/toolkit';
import { getCompanyDetailsAction } from 'redux/action/companyDetails';

// Define the initial state for the slice
const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

// Create the CompanySlice using createSlice
const CompanySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    // Handler for setting company data
    getCompanies: (state, action) => {
      // Extract the companies data from the action payload
      const companies = action.payload?.data?.companies;

      if (companies?.length > 0) {
        // Store the company ID in localStorage (assuming you want the first company's ID)
        localStorage.setItem("companyId", companies[0]?.company?.id);
      }

      // Update the state with the companies data
      state.data = companies;
    },
  },
  extraReducers: (builder) => {
    // Get All Users
    builder.addCase(getCompanyDetailsAction.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getCompanyDetailsAction.fulfilled, (state, action) => {
      state.data = action?.payload.data;
      state.isLoading = false;
      state.error = null;
      // toastText("Accounts fetched successfully", "success");
    });
    builder.addCase(getCompanyDetailsAction.rejected, (state, action: any) => {
      state.error = action.payload;
      state.isLoading = false;
      // toastText(action?.payload?.message, "error");
    });
  },
});

// Export the getCompanies action
export const { getCompanies } = CompanySlice.actions;
export default CompanySlice;
