import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteApiWithData, getApi, postApi } from "redux/apis";

// Get All Roles
export const getRoleActionTable = createAsyncThunk(
  "roleTable/getRolesTable",
  async (queryObj: any, { rejectWithValue }: any) => {
    try {
      const response = await getApi(
        `/role/organization-roles${
          queryObj?.url ? `?${queryObj?.url}` : ""
        } `
      );

      return response.data.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const paginateRoleTable = createAsyncThunk(
  "roleTable/paginateTable",
  async (url: any, { rejectWithValue, getState }: any) => {
    const alreadyAvailableRoles = getState().roles.data;
    try {
      const response = await getApi(
        `/role/organization-roles${
          url ? `?${url}` : ""
        }`
      );
      return [...alreadyAvailableRoles, ...response.data.data.roles];
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Add New Role
export const addRoleActionTable = createAsyncThunk(
  "roleTable/addRolesTable",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await postApi(`/role/create`, data);
      return response.data;
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Edit Role
export const editRoleActionTable = createAsyncThunk(
  "roleTable/editRoleTable",
  async (data: any, { rejectWithValue }) => {
    try {
      const { isChangeStatus, ...rest } = data;
      console.log("🚀 ~ isChangeStatus:", isChangeStatus)
      const response = await postApi(`/role/update-role`, rest);

      return { response: response.data.data, editData: data};
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

//Clone Role 
export const cloneRoleActionTable = createAsyncThunk(
  "roleTable/cloneRoleTable",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await postApi(`/role/clone`, data);

      return response.data
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);

// Delete Role
export const deleteRoleActionTable = createAsyncThunk(
  "roleTable/deleteRoleTable",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await deleteApiWithData(`/role`, data);
      return { response: response.data, id: data?.roleId };
    } catch (error: any) {
      if (!error.response) {
        throw error;
      }
      return rejectWithValue(error?.response?.data);
    }
  }
);
