import { Tabs } from 'antd';
import { LogsTabProps } from './types';
import { FC } from 'react';
import DynamicLogsTable from '../Table';


const LogPageTabs: FC<LogsTabProps> = (props) => {

    const { isLoading, logsDataSource, setLoadingHandler, totalRecords, onTabChange, tabIndex } = props
    const items = [
        {
            key: '1',
            label: 'API Logs',
            children: <DynamicLogsTable tabIndex={tabIndex} logsDataSource={logsDataSource} isLoading={isLoading} setLoadingHandler={setLoadingHandler} totalRecords={totalRecords} />,
        },
        {
            key: '2',
            label: 'Webhooks',
            children: <DynamicLogsTable tabIndex={tabIndex} logsDataSource={logsDataSource} isLoading={false} setLoadingHandler={setLoadingHandler} totalRecords={totalRecords} />,
        },
    ];



    return (
        <div>
            <Tabs defaultActiveKey="1" items={items} onChange={onTabChange} />
        </div>
    )
}

export default LogPageTabs
