import { Sidebar } from "components/Global";
import { FORMDATA } from "constants/Data";
import styles from "./index.module.scss";
import { FC } from "react";
import { SettingsLayoutProps } from "./types";
import { useSelector } from "react-redux";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

// Settings page layout component
const SettingsLayout: FC<SettingsLayoutProps> = (props) => {
  // Initialize variables and props
  const { settingsMenuItems } = FORMDATA;
  const { children, onSideBarChange, selectedSidebar } = props;
  const navigate = useNavigate()

  // Retrieve user profile and permissions
  const selectedCompany = useSelector(
    (state: any) => state?.userProfile?.data?.companies
  );

  // Extract permissions for the selected company
  const permissionsForSelectedCompany = selectedCompany?.map((data: any) => {
    if (data.companyId === localStorage.getItem("companyId")) {
      return data.role.permissions;
    }
  });

  // Initialize an array to store result permissions
  const resultArray: any = ["Profile"];

  // Process permissions
  if (permissionsForSelectedCompany) {
    permissionsForSelectedCompany[0]?.map((item: any) => {
      if (item.view) {
        resultArray.push(item.permissionName);
      }
    });
  }

  // Filter the menu items based on permissions
  const filteredMenuItems = settingsMenuItems.filter((item) =>
    resultArray.includes(item.roleName)
  );

  // JSX
  return (
    <div className={styles["settings-layout"]}>
      <div className={styles["settings-layout__wrapper"]}>
        <Sidebar
          items={filteredMenuItems}
          isGetSupportButton={true}
          handleSidebar={onSideBarChange}
          selectedSidebar={selectedSidebar as string}
        />
        <div className={styles["settings-layout__body-wrapper"]}>
          <div className={styles["settings-layout__closebtn"]}><AiOutlineClose size={20} style={{ cursor: "pointer" }} onClick={() => navigate('/') }/></div>
          <div className={styles["settings-layout__body"]}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default SettingsLayout;
