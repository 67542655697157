import { Button, Form, Input, Modal } from "antd";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postApi, putApi } from "redux/apis";
import { CloseSvg } from "utils/svgs";
import './index.scss';
import { CreateAPIKeyProps } from "./types";
import { AppDispatch } from "redux/store";
import { getApiKey } from "redux/action/apikeyAction";
import { toastText } from "utils/utils";

const CreateAPIKeyModal: FC<CreateAPIKeyProps> = (props) => {
    const { isModalOpen, editFormValue, handleClose } = props;
    const [form] = Form.useForm();
    const [isloading, setIsLoading] = useState(false);
    const userId = useSelector((data: any) => data?.userProfile?.data?.id);
    const dispatch = useDispatch<AppDispatch>();

    const onSubmit = async (values: any) => {

        setIsLoading(true);
        if (Object.keys(editFormValue).length === 0) {
            const apiKeyData = {
                userId,
                apikeyName: values?.apikeyName
            };
            await postApi('/apiKey/create', apiKeyData)
                .then(async (res: any) => {
                    if (res?.status === 200) {
                        dispatch(getApiKey());
                        toastText("Api key created successfully!", "success");
                    }
                    setIsLoading(false);
                })
                .catch((error: any) => {
                    toastText(error?.response?.data?.error?.message, "error");
                    setIsLoading(false);
                });

        } else {
            let newValues = editFormValue;
            newValues = {
                ...newValues,
                apikeyName: values?.apikeyName
            }
            await putApi('/apiKey/update', newValues).then(async (res: any) => {
                if (res?.status === 200) {
                    dispatch(getApiKey());
                    toastText("Api key created successfully!", "success");
                }
                setIsLoading(false);
            }).catch((error: any) => {
                toastText(error?.response?.data?.error?.message, "error");
                setIsLoading(false);
            });
        }
        handleClose();
    };

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(editFormValue); // Set form values when editFormValue changes
    }, [editFormValue, form])

    return (
        <Modal
            title='Create API Key'
            open={isModalOpen}
            onCancel={handleClose}
            centered={true}
            width={600}
            closeIcon={<CloseSvg />}
            footer={null}
        >
            <Form form={form} onFinish={onSubmit} name="apiKeyForm" autoComplete="off" className={'form_margin'}>
                <div className={"input-icon__title"}>
                    <label className={"input-icon__title--label"}>
                        Name <span className="required-color">*</span>
                    </label>
                </div>
                <div className={"input-icon__form"}>
                    <Form.Item name='apikeyName' rules={[{ required: true, message: 'Please enter API key name' }]}>
                        <Input placeholder='Enter key name' size="large" className={"input-icon__form--input"} type='text' />
                    </Form.Item>
                </div>
                <div key={"wrapper"} className={"integration-model__button"}>
                    <Button htmlType="submit" className={'integration-model__button--save integration-model__button--btn'} loading={isloading}>
                        Save
                    </Button>
                    <button type="button" onClick={handleClose} className={"integration-model__button--cancel integration-model__button--btn"}>
                        Cancel
                    </button>
                </div>
            </Form>
        </Modal>
    );
};

export default CreateAPIKeyModal;



